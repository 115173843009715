<template>
  <div class="major-product-index">
    <el-form class="zwx-form edit-form" key="msForm" ref="majorProductIndexRef" :model="msForm" label-position="right" :rules="majorProductRules" @submit.native.prevent>
      <div class="edit-row" style="margin-bottom: 12px">
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addProduct()">添加</el-button>
        <el-checkbox v-model="msForm.ifClassifiedUnit" style="margin-left: 14px">军工等涉密企业</el-checkbox>
        <div class="ms-el-message ms-el-message--warning" style="display: inline-block;margin-left: 10px;">
          <i class="el-icon-warning"></i>
          非生产型企业，如医院、饭店等，主要产品项名称及计量单位可填“无”，年产量填“0”。
        </div>
      </div>
      <el-table class="zwx-table" style="width: 100%" row-key="rid" key="majorProductListTable" :data="msForm.majorProductList" stripe border>
        <el-table-column prop="productName" label="产品名称" width="350" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'majorProductList.' + scope.$index + '.productName'" :rules="majorProductRules.productName">
              <el-input class="zwx-input cc-input" size="small" style="cursor: pointer;min-width:68px!important" placeholder="请输入" clearable v-model="scope.row.productName" maxlength="100" />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="dosage" label="年产量" width="180" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'majorProductList.' + scope.$index + '.dosage'" :rules="majorProductRules.dosage">
              <el-input class="zwx-input cc-input" size="small" style="cursor: pointer;min-width:68px!important" placeholder="请输入" @input="$validate.nonnegativeDouble($data['msForm']['majorProductList'][scope.$index], 'dosage', 8, 2)" clearable v-model="scope.row.dosage" maxlength="11" />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="unit" label="计量单位" width="180" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'majorProductList.' + scope.$index + '.unit'" :rules="majorProductRules.unit">
              <!-- <el-select style="height: 28px" class="zwx-select" filterable allow-create popper-class="zwx-select-popper" v-model="scope.row.unit" placeholder="请选择" clearable>
                <el-option v-for="item in simpleCodeList" :key="item.rid" :label="item.codeName" :value="item.codeName"></el-option>
              </el-select> -->
              <el-autocomplete class="zwx-input" v-model.trim="scope.row.unit" value-key="codeName" :fetch-suggestions="unitAutocompleteQuery" :maxlength="10" clearable></el-autocomplete>
              <!-- <el-input class="zwx-input cc-input" size="small" style="cursor: pointer;min-width:68px!important" placeholder="请输入" clearable v-model="scope.row.unit" maxlength="10" /> -->
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" header-align="left" align="left" fixed="right" min-width="200">
          <template slot-scope="scope">
            <el-popconfirm title="确认删除吗？" confirm-button-type="text" @confirm="delProduct(scope)" v-if="$zwxBase.verifyIsNotBlank(scope.row.productName) || $zwxBase.verifyIsNotBlank(scope.row.dosage) || $zwxBase.verifyIsNotBlank(scope.row.unit)">
              <el-button slot="reference" type="danger" class="zwx-button zwx-button-text-26 ">删除</el-button>
            </el-popconfirm>
            <el-button class="zwx-button zwx-button-text-26" type="danger" @click="delProduct(scope)" v-if="$zwxBase.verifyIsBlank(scope.row.productName) && $zwxBase.verifyIsBlank(scope.row.dosage) && $zwxBase.verifyIsBlank(scope.row.unit)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'MajorProductIndex',
  data() {
    return {
      api: this.$store.state.api,
      employerUuid: '',
      msForm: {
        rid: '',
        mainId: '', //申报表id
        ifClassifiedUnit: '',
        majorProductList: [],
      },
      majorProductRules: {
        productName: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateProductName, trigger: ['blur'] },
        ],
        dosage: [{ required: true, message: '请输入', trigger: ['change', 'blur'] }],
        unit: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] },
          // { validator: this.validateUnit, trigger: ['blur', 'change'] },
        ],
      },
      simpleCodeList: [], //单位
    }
  },
  created() {},
  mounted() {
    this.employerUuid = this.$route.params.employerUuid
    this.msForm.mainId = this.$route.params.rid
    this.$SimpleCodeTools.getSimpleCodeListByCodeTypeCodes('2002', data => {
      let codeList = data['2002']
      for (let index = 0; index < codeList.length; index++) {
        const element = codeList[index]
        element.codeName = element.codeName.substring(0, element.codeName.lastIndexOf('/'))
      }
      this.simpleCodeList = codeList
      // let type = this.$route.params.type
      // if (2 == type) {
      this.getMajorProductByMainID()
      // }
    })
  },
  methods: {
    /**
     * 计量单位
     */
    unitAutocompleteQuery: function(queryString, cb) {
      var unitSimpleCodeList = this.simpleCodeList
      var results = queryString ? unitSimpleCodeList.filter(this.unitAutocompleteFilter(queryString)) : unitSimpleCodeList
      cb(results)
    },
    /**
     * 计量单位过滤器
     */
    unitAutocompleteFilter: function(queryString) {
      return function(obj) {
        return obj.codeName.indexOf(queryString) === 0
      }
    },
    validateUnit(rule, value, callback) {
      if (value.length > 20) {
        callback(new Error('长度<=20'))
      }
      callback()
    },
    validateProductName(rule, value, callback) {
      if (this.msForm.majorProductList.length > 1) {
        let arr = this.msForm.majorProductList.filter(item => item.productName == value)
        if (arr.length > 1) {
          callback(new Error('产品名称不能重复'))
        }
      }
      callback()
    },
    // 获取计量单位
    getSimpleCodeID() {
      let data = {
        code: 2002,
      }
      this.$system.get(this.api + '/oh/declaration/getSimpleCodeID-1', data, true, true, this.getSimpleCodeIDSuccess)
    },
    // 获取计量单位
    getSimpleCodeIDSuccess(data) {
      let codeList = data.simpleCodeList
      for (let index = 0; index < codeList.length; index++) {
        const element = codeList[index]
        element.codeName = element.codeName.substring(0, element.codeName.lastIndexOf('/'))
      }
      this.simpleCodeList = codeList
    },
    /**
     * 添加产品
     */
    addProduct() {
      let data = {
        productName: '',
        dosage: '',
        unit: '',
      }
      this.msForm.majorProductList.push(data)
    },
    /**
     * 删除产品
     */
    delProduct(row) {
      this.msForm.majorProductList.splice(row.$index, 1)
    },
    /**
     * 编辑获取主要产品
     */
    getMajorProductByMainID() {
      this.$parent.loading = true
      let fkByMainId = {
        rid: this.msForm.mainId,
      }
      let data = {
        fkByMainId: fkByMainId,
      }
      this.$system.postJson(
        this.api + '/oh/declaration/getMajorProductByMainID-1',
        data,
        true,
        true,
        data => {
          this.$parent.loading = false
          if (data.type === '00') {
            this.msForm.ifClassifiedUnit = data.ifClassifiedUnit
            this.msForm.majorProductList = data.majorProductList
            this.$parent.jsonData = JSON.stringify(this.msForm)
            this.$parent.saveDisabled = false
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        data => {
          this.$parent.loading = false
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 新增加载主要产品
     */
    loadMajorProduct() {
      this.$parent.loading = true
      let data = {
        employerUuid: this.employerUuid,
      }
      this.$system.get(
        this.api + '/oh/declaration/loadMajorProduct-1',
        data,
        true,
        true,
        data => {
          this.$parent.loading = false
          if (data.type === '00') {
            this.msForm.majorProductList = data.majorProductList
            this.$parent.jsonData = JSON.stringify(this.msForm)
            this.$parent.saveDisabled = false
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        data => {
          this.$parent.loading = false
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 保存更新基本信息
     */
    saveOrUpdateMajorProduct(opType) {
      if (0 == opType || 2 == opType) {
        //上一步和暂存不校验
        this.saveMajorProduct(opType)
      } else {
        // 校验
        this.$refs.majorProductIndexRef.validate(valid => {
          if (valid) {
            if (!this.msForm.ifClassifiedUnit && this.msForm.majorProductList < 1) {
              this.$notify.info({ title: '提示', message: '非军工等涉密企业请填写产品信息' })
            } else {
              this.$parent.saveDisabled = true
              this.saveMajorProduct(opType)
            }
          }
        })
      }
    },
    saveMajorProduct(opType) {
      this.$parent.loading = true
      let fkByMainId = {
        rid: this.msForm.mainId,
      }
      let data = {
        majorProductExpList: this.msForm.majorProductList,
        fkByMainId: fkByMainId,
        ifClassifiedUnit: this.msForm.ifClassifiedUnit ? true : false,
      }
      data.opType = opType
      this.$system.postJson(
        this.api + '/oh/declaration/saveOrUpdateMajorProduct-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.$route.params.type = 2
            //点击下一步
            if (opType === 1) {
              this.$parent.step++
              this.$parent.informantStep = this.$parent.step
              this.$parent.saveDisabled = false
            } else {
              this.$parent.jsonData = JSON.stringify(this.msForm)
            }
            if (2 == opType) {
              // 暂存保存数据后，按钮解禁
              this.$parent.saveDisabled = false
            }
            if (0 == opType) {
              this.$parent.step--
            }
          } else if (data.type === '99') {
            this.$parent.saveDisabled = false
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$parent.saveDisabled = false
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$parent.loading = false
        },
        this.error
      )
    },
  },
}
</script>

<style lang="less" scoped>
.major-product-index {
  .ms-el-message {
    display: inline-block;
    min-width: 236px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    font-size: 12px;
    padding: 5px 10px 5px 9px;
    border-radius: 2px;
  }

  .ms-el-message--warning {
    background-color: #fff5e6;
    border-color: #ffa738;
    color: #ff9400;
  }
}
</style>
