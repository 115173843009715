<template>
  <div class="hazard-detection-index">
    <el-form class="zwx-form edit-form" key="msForm" ref="hazardDetectionIndexRef" :model="msForm" label-position="right" @submit.native.prevent :rules="rules">
      <div style="max-width: 1232px; padding: 10px 0 5px 20px !important;">
        <el-form-item label-width="196px" label="本年度是否开展危害因素检测：" prop="projectNature">
          <el-radio-group class="zwx-radio-group" v-model="msForm.existsDetection" @change="existsDetectionChange">
            <el-radio class="zwx-radio" :label="true">是</el-radio>
            <el-radio class="zwx-radio" :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div v-if="isShow">
        <!-- 检测机构 -->
        <base-headline title="检测机构" class="headline no-bottom">
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addList">添加</el-button>
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-search" @click="chooseData">选择</el-button>
        </base-headline>
        <div style="">
          <!-- 列表展示 -->
          <el-table class="zwx-table" style="width: 100%;" row-key="rid" :data="msForm.tdHazardDetectionRecordExpList" border stripe>
            <el-table-column prop="unitName" label="检测机构名称" width="350" header-align="center" align="left">
              <template slot-scope="scope">
                <el-form-item v-if="!scope.row.detectionReportUid" :prop="'tdHazardDetectionRecordExpList.' + scope.$index + '.unitName'" :rules="rules.unitName">
                  <detection-organ-select class="organ-select" style="width: 315px!important;" :ref="'detectionOrganRef_' + scope.$index" requestUrl="/oh/repository/findTechnicalServiceAgencyList-1" codeType="2013,2014" @change="detectionOrganSelectChange($event, scope.$index)" v-model="scope.row.unitName" />
                </el-form-item>
                <span v-else class="td-text">{{ scope.row.unitName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="检测报告编号" width="220" header-align="center" align="left">
              <template slot-scope="scope">
                <el-form-item v-if="!scope.row.detectionReportUid" :prop="'tdHazardDetectionRecordExpList.' + scope.$index + '.reportNo'" :rules="rules.reportNo">
                  <el-input class="zwx-input" v-model="scope.row.reportNo" placeholder="请输入" clearable maxlength="50" />
                </el-form-item>
                <a @click.prevent="previewFile(scope.row.detectionReportUid)" v-else class="td-text">
                  {{ scope.row.reportNo }}
                </a>
              </template>
            </el-table-column>
            <el-table-column label="报告完成日期" width="220" header-align="center" align="left">
              <template slot-scope="scope">
                <el-form-item v-if="!scope.row.detectionReportUid" :prop="'tdHazardDetectionRecordExpList.' + scope.$index + '.reportDate'" :rules="rules.reportDate">
                  <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" v-model="scope.row.reportDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noOverToday()" placeholder="开始日期" />
                </el-form-item>
                <span v-else class="td-text">{{ $system.formatDate(scope.row.reportDate, 'yyyy-MM-DD') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="社会信用代码" width="230" header-align="center" align="left">
              <template slot-scope="scope">
                <el-form-item v-if="!scope.row.detectionReportUid" :prop="'tdHazardDetectionRecordExpList.' + scope.$index + '.creditCode'" :rules="rules.creditCode">
                  <el-input class="zwx-input" v-model="scope.row.creditCode" placeholder="请输入" clearable maxlength="50" />
                </el-form-item>
                <span v-else class="td-text">{{ scope.row.creditCode }}</span>
              </template>
            </el-table-column>
            <el-table-column label="联系电话" width="200" header-align="center" align="left">
              <template slot-scope="scope">
                <el-form-item v-if="!scope.row.detectionReportUid" :prop="'tdHazardDetectionRecordExpList.' + scope.$index + '.contactInfo'" :rules="rules.contactInfo">
                  <el-input class="zwx-input" v-model="scope.row.contactInfo" placeholder="请输入" clearable maxlength="20" />
                </el-form-item>
                <span v-else class="td-text">{{ scope.row.contactInfo }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" header-align="left" align="left">
              <template slot-scope="scope">
                <el-button class="zwx-button zwx-button-text-26" type="text" v-if="scope.row.reportAnnexExpList1 && scope.row.reportAnnexExpList1.length > 0" @click="openFilePreview(scope.row.reportAnnexExpList1, 0)">查看报告</el-button>
                <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletion(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 检测情况 -->
        <base-headline title="检测情况" class="no-top" />
        <div class="harz-list">
          <!--粉尘-->
          <div v-if="msForm.dustHazardList.length > 0" class="harz">
            <div class="flex-row" style="margin: 12px 16px;">
              <span>粉尘因素</span>
              <div class="flex-row stages-css" @click="itemSelect(1)">
                <span>
                  <img v-show="msForm.existsDust" src="@/assets/images/hazard_declaration/ic_select_1.svg" alt="" />
                  <img v-show="!msForm.existsDust" src="@/assets/images/hazard_declaration/ic_no_select.svg" alt="" />
                </span>
                <span style="margin-left:8px">检测</span>
              </div>
              <div style="height:36px">
                <el-form-item label="总检测点数：" label-width="96px" prop="dustPoints" :rules="msForm.existsDust ? rules.dustPoints : rules.noRequired">
                  <el-input class="zwx-input" v-model="msForm.dustPoints" placeholder="请输入" clearable maxlength="50" :disabled="!msForm.existsDust" @input="$validate.nonnegativeInteger($data['msForm'], 'dustPoints')" />
                </el-form-item>
                <el-form-item label="总超标点数：" label-width="96px" prop="dustOverproofPoints" :rules="msForm.existsDust ? rules.dustOverproofPoints : rules.noRequired">
                  <el-input class="zwx-input" v-model="msForm.dustOverproofPoints" placeholder="请输入" clearable maxlength="50" :disabled="!msForm.existsDust" @input="$validate.nonnegativeInteger($data['msForm'], 'dustOverproofPoints')" />
                </el-form-item>
              </div>
            </div>
            <div class="div-table">
              <div class="div-table-row">
                <div class="div-table-head-name-col flew-center" style="width: 250px">检测危害因素</div>
                <div class="div-table-head-name-col flew-center">检测点数</div>
                <div class="div-table-head-name-col flew-center">超标点数</div>
                <div class="div-table-head-border"></div>
                <div class="div-table-head-name-col flew-center" style="width: 250px">检测危害因素</div>
                <div class="div-table-head-name-col flew-center">检测点数</div>
                <div class="div-table-head-name-col flew-center">超标点数</div>
                <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1308px);"></div>
              </div>
              <template v-for="(childs, i) in msForm.dustHazardList">
                <div class="div-table-row" :key="i">
                  <template v-for="(child, j) in childs">
                    <div class="div-table-td-name-col flew-center" style="justify-content: flex-start" :key="'hazardsName' + j">
                      {{ child.hazardsName }}
                    </div>
                    <div class="div-table-td-num-col flew-center" :key="'points' + j">
                      <el-form-item :id="'dustHazardList' + i + j" :prop="'dustHazardList.' + i + '.' + j + '.points'" :rules="msForm.existsDust ? rules.points : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                        <el-input class="zwx-input" v-model.trim="child.points" style="width: 100% !important" @input="$validate.nonnegativeInteger(child, 'points')" maxlength="8" clearable :disabled="!msForm.existsDust" />
                      </el-form-item>
                    </div>
                    <div class="div-table-td-num-col flew-center" :key="'overproofPoints' + j">
                      <el-form-item :id="'dustHazardList' + i + j" :prop="'dustHazardList.' + i + '.' + j + '.overproofPoints'" :rules="msForm.existsDust ? rules.overproofPoints : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                        <el-input class="zwx-input" v-model.trim="child.overproofPoints" style="width: 100% !important" @input="$validate.nonnegativeInteger(child, 'overproofPoints')" maxlength="8" clearable :disabled="!msForm.existsDust" />
                      </el-form-item>
                    </div>
                    <div :class="1 != j ? 'div-table-td-border' : ''" :style="i == msForm.dustHazardList.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''"></div>
                    <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 250px;"></div>
                    <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-num-col flew-center" style="width: 200px;"></div>
                    <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-num-col flew-center" style="width: 200px;"></div>
                  </template>
                  <div class="div-table-td-name-col" style="width: calc(100% - 1308px);"></div>
                </div>
              </template>
            </div>
          </div>
          <!--化学-->
          <div v-if="msForm.chemicalHazardList.length > 0" class="harz">
            <div class="flex-row" style="margin: 12px 16px;">
              <span>化学因素</span>
              <div class="flex-row stages-css" @click="itemSelect(2)">
                <span>
                  <img v-show="msForm.existsChemical" src="../../../../assets/images/hazard_declaration/ic_select_1.svg" alt="" />
                  <img v-show="!msForm.existsChemical" src="../../../../assets/images/hazard_declaration/ic_no_select.svg" alt="" />
                </span>
                <span style="margin-left:8px">检测</span>
              </div>
              <div style="height:36px">
                <el-form-item label="总检测点数：" label-width="96px" prop="chemicalPoints" :rules="msForm.existsChemical ? rules.chemicalPoints : rules.noRequired">
                  <el-input class="zwx-input" v-model="msForm.chemicalPoints" placeholder="请输入" @input="$validate.nonnegativeInteger(msForm, 'chemicalPoints')" clearable maxlength="50" :disabled="!msForm.existsChemical" />
                </el-form-item>
                <el-form-item label="总超标点数：" label-width="96px" prop="chemicalOverproofPoints" :rules="msForm.existsChemical ? rules.chemicalOverproofPoints : rules.noRequired">
                  <el-input class="zwx-input" v-model="msForm.chemicalOverproofPoints" placeholder="请输入" @input="$validate.nonnegativeInteger(msForm, 'chemicalOverproofPoints')" clearable maxlength="50" :disabled="!msForm.existsChemical" />
                </el-form-item>
              </div>
            </div>
            <div class="div-table">
              <div class="div-table-row">
                <div class="div-table-head-name-col flew-center" style="width: 250px">检测危害因素</div>
                <div class="div-table-head-name-col flew-center">检测点数</div>
                <div class="div-table-head-name-col flew-center">超标点数</div>
                <div class="div-table-head-border"></div>
                <div class="div-table-head-name-col flew-center" style="width: 250px">检测危害因素</div>
                <div class="div-table-head-name-col flew-center">检测点数</div>
                <div class="div-table-head-name-col flew-center">超标点数</div>
                <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1308px);"></div>
              </div>
              <template v-for="(childs, i) in msForm.chemicalHazardList">
                <div class="div-table-row" :key="i">
                  <template v-for="(child, j) in childs">
                    <div class="div-table-td-name-col flew-center" style="justify-content: flex-start" :key="j">
                      {{ child.hazardsName }}
                    </div>
                    <div class="div-table-td-num-col flew-center" :key="j">
                      <el-form-item :id="'chemicalHazardList' + i + j" :prop="'chemicalHazardList.' + i + '.' + j + '.points'" :rules="msForm.existsChemical ? rules.points : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                        <el-input class="zwx-input" v-model.trim="child.points" style="width: 100% !important" @input="$validate.nonnegativeInteger(child, 'points')" maxlength="8" clearable :disabled="!msForm.existsChemical" />
                      </el-form-item>
                    </div>
                    <div class="div-table-td-num-col flew-center" :key="j">
                      <el-form-item :id="'chemicalHazardList' + i + j" :prop="'chemicalHazardList.' + i + '.' + j + '.overproofPoints'" :rules="msForm.existsChemical ? rules.overproofPoints : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                        <el-input class="zwx-input" v-model.trim="child.overproofPoints" style="width: 100% !important" @input="$validate.nonnegativeInteger(child, 'overproofPoints')" maxlength="8" clearable :disabled="!msForm.existsChemical" />
                      </el-form-item>
                    </div>
                    <div :class="1 != j ? 'div-table-td-border' : ''" :style="i == msForm.chemicalHazardList.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''"></div>
                    <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 250px;"></div>
                    <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-num-col flew-center" style="width: 200px;"></div>
                    <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-num-col flew-center" style="width: 200px;"></div>
                  </template>
                  <div class="div-table-td-name-col" style="width: calc(100% - 1308px);"></div>
                </div>
              </template>
            </div>
          </div>
          <!--物理-->
          <div v-if="msForm.physicalHazardList.length > 0" class="harz">
            <div class="flex-row" style="margin: 12px 16px;">
              <span>物理因素</span>
              <div class="flex-row stages-css" @click="itemSelect(3)">
                <span>
                  <img v-show="msForm.existsPhysical" src="../../../../assets/images/hazard_declaration/ic_select_1.svg" alt="" />
                  <img v-show="!msForm.existsPhysical" src="../../../../assets/images/hazard_declaration/ic_no_select.svg" alt="" />
                </span>
                <span style="margin-left:8px">检测</span>
              </div>
              <div style="height:36px">
                <el-form-item label="总检测点数：" label-width="96px" prop="physicalPoints" :rules="msForm.existsPhysical ? rules.physicalPoints : rules.noRequired">
                  <el-input class="zwx-input" v-model="msForm.physicalPoints" placeholder="请输入" @input="$validate.nonnegativeInteger(msForm, 'physicalPoints')" clearable maxlength="50" :disabled="!msForm.existsPhysical" />
                </el-form-item>
                <el-form-item label="总超标点数：" label-width="96px" prop="physicalOverproofPoints" :rules="msForm.existsPhysical ? rules.physicalOverproofPoints : rules.noRequired">
                  <el-input class="zwx-input" v-model="msForm.physicalOverproofPoints" placeholder="请输入" @input="$validate.nonnegativeInteger(msForm, 'physicalOverproofPoints')" clearable maxlength="50" :disabled="!msForm.existsPhysical" />
                </el-form-item>
              </div>
            </div>
            <div class="div-table">
              <div class="div-table-row">
                <div class="div-table-head-name-col flew-center" style="width: 250px">检测危害因素</div>
                <div class="div-table-head-name-col flew-center">检测点数</div>
                <div class="div-table-head-name-col flew-center">超标点数</div>
                <div class="div-table-head-border"></div>
                <div class="div-table-head-name-col flew-center" style="width: 250px">检测危害因素</div>
                <div class="div-table-head-name-col flew-center">检测点数</div>
                <div class="div-table-head-name-col flew-center">超标点数</div>
                <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1308px);"></div>
              </div>
              <template v-for="(childs, i) in msForm.physicalHazardList">
                <div class="div-table-row" :key="i">
                  <template v-for="(child, j) in childs">
                    <div class="div-table-td-name-col flew-center" style="justify-content: flex-start">
                      {{ child.hazardsName }}
                    </div>
                    <div class="div-table-td-num-col flew-center">
                      <el-form-item :id="'physicalHazardList' + i + j" :prop="'physicalHazardList.' + i + '.' + j + '.points'" :rules="msForm.existsPhysical ? rules.points : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                        <el-input class="zwx-input" v-model.trim="child.points" style="width: 100% !important" @input="$validate.nonnegativeInteger(child, 'points')" maxlength="8" clearable :disabled="!msForm.existsPhysical" />
                      </el-form-item>
                    </div>
                    <div class="div-table-td-num-col flew-center">
                      <el-form-item :id="'physicalHazardList' + i + j" :prop="'physicalHazardList.' + i + '.' + j + '.overproofPoints'" :rules="msForm.existsPhysical ? rules.overproofPoints : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                        <el-input class="zwx-input" v-model.trim="child.overproofPoints" style="width: 100% !important" @input="$validate.nonnegativeInteger(child, 'overproofPoints')" maxlength="8" clearable :disabled="!msForm.existsPhysical" />
                      </el-form-item>
                    </div>
                    <div :class="1 != j ? 'div-table-td-border' : ''" :style="i == msForm.physicalHazardList.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''"></div>
                    <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 250px;"></div>
                    <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-num-col flew-center" style="width: 200px;"></div>
                    <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-num-col flew-center" style="width: 200px;"></div>
                  </template>
                  <div class="div-table-td-name-col" style="width: calc(100% - 1308px);"></div>
                </div>
              </template>
            </div>
          </div>
          <!--放射性-->
          <div v-if="msForm.radioactivityHazardList.length > 0" class="harz">
            <div class="flex-row" style="margin: 12px 16px;">
              <span>放射性因素</span>
              <div class="flex-row stages-css" @click="itemSelect(4)">
                <span>
                  <img v-show="msForm.existsRadioactivity" src="../../../../assets/images/hazard_declaration/ic_select_1.svg" alt="" />
                  <img v-show="!msForm.existsRadioactivity" src="../../../../assets/images/hazard_declaration/ic_no_select.svg" alt="" />
                </span>
                <span style="margin-left:8px">检测</span>
              </div>
              <div style="height:36px">
                <el-form-item label="总检测点数：" label-width="96px" prop="radioactivityPoints" :rules="msForm.existsRadioactivity ? rules.radioactivityPoints : rules.noRequired">
                  <el-input class="zwx-input" v-model="msForm.radioactivityPoints" placeholder="请输入" @input="$validate.nonnegativeInteger(msForm, 'radioactivityPoints')" clearable maxlength="50" :disabled="!msForm.existsRadioactivity" />
                </el-form-item>
                <el-form-item label="总超标点数：" label-width="96px" prop="radioactivityOverproofPoints" :rules="msForm.existsRadioactivity ? rules.radioactivityOverproofPoints : rules.noRequired">
                  <el-input class="zwx-input" v-model="msForm.radioactivityOverproofPoints" placeholder="请输入" @input="$validate.nonnegativeInteger(msForm, 'radioactivityOverproofPoints')" clearable maxlength="50" :disabled="!msForm.existsRadioactivity" />
                </el-form-item>
              </div>
            </div>
            <div class="div-table">
              <div class="div-table-row">
                <div class="div-table-head-name-col flew-center" style="width: 250px">检测危害因素</div>
                <div class="div-table-head-name-col flew-center">检测点数</div>
                <div class="div-table-head-name-col flew-center">超标点数</div>
                <div class="div-table-head-border"></div>
                <div class="div-table-head-name-col flew-center" style="width: 250px">检测危害因素</div>
                <div class="div-table-head-name-col flew-center">检测点数</div>
                <div class="div-table-head-name-col flew-center">超标点数</div>
                <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1308px);"></div>
              </div>
              <template v-for="(childs, i) in msForm.radioactivityHazardList">
                <div class="div-table-row" :key="i">
                  <template v-for="(child, j) in childs">
                    <div class="div-table-td-name-col flew-center" style="justify-content: flex-start">
                      {{ child.hazardsName }}
                    </div>
                    <div class="div-table-td-num-col flew-center">
                      <el-form-item :id="'radioactivityHazardList' + i + j" :prop="'radioactivityHazardList.' + i + '.' + j + '.points'" :rules="msForm.existsRadioactivity ? rules.points : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                        <el-input class="zwx-input" v-model.trim="child.points" style="width: 100% !important" @input="$validate.nonnegativeInteger(child, 'points')" maxlength="8" clearable :disabled="!msForm.existsRadioactivity" />
                      </el-form-item>
                    </div>
                    <div class="div-table-td-num-col flew-center">
                      <el-form-item :id="'radioactivityHazardList' + i + j" :prop="'radioactivityHazardList.' + i + '.' + j + '.overproofPoints'" :rules="msForm.existsRadioactivity ? rules.overproofPoints : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                        <el-input class="zwx-input" v-model.trim="child.overproofPoints" style="width: 100% !important" @input="$validate.nonnegativeInteger(child, 'overproofPoints')" maxlength="8" clearable :disabled="!msForm.existsRadioactivity" />
                      </el-form-item>
                    </div>
                    <div :class="1 != j ? 'div-table-td-border' : ''" :style="i == msForm.radioactivityHazardList.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''"></div>
                    <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 250px;"></div>
                    <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-num-col flew-center" style="width: 200px;"></div>
                    <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-num-col flew-center" style="width: 200px;"></div>
                  </template>
                  <div class="div-table-td-name-col" style="width: calc(100% - 1308px);"></div>
                </div>
              </template>
            </div>
          </div>
          <!--生物和其他因素-->
          <div v-if="msForm.bioticOtherHazardList.length > 0" class="harz">
            <div class="flex-row" style="margin: 12px 16px;">
              <span>生物和其他因素</span>
              <div class="flex-row stages-css" @click="itemSelect(5)">
                <span>
                  <img v-show="msForm.existsBioticOther" src="../../../../assets/images/hazard_declaration/ic_select_1.svg" alt="" />
                  <img v-show="!msForm.existsBioticOther" src="../../../../assets/images/hazard_declaration/ic_no_select.svg" alt="" />
                </span>
                <span style="margin-left:8px">检测</span>
              </div>
              <div style="height:36px">
                <el-form-item label="总检测点数：" label-width="96px" prop="bioticOtherPoints" :rules="msForm.existsBioticOther ? rules.bioticOtherPoints : rules.noRequired">
                  <el-input class="zwx-input" v-model="msForm.bioticOtherPoints" placeholder="请输入" @input="$validate.nonnegativeInteger(msForm, 'bioticOtherPoints')" clearable maxlength="50" :disabled="!msForm.existsBioticOther" />
                </el-form-item>
                <el-form-item label="总超标点数：" label-width="96px" prop="bioticOtherOverproofPoints" :rules="msForm.existsBioticOther ? rules.bioticOtherOverproofPoints : rules.noRequired">
                  <el-input class="zwx-input" v-model="msForm.bioticOtherOverproofPoints" placeholder="请输入" @input="$validate.nonnegativeInteger(msForm, 'bioticOtherOverproofPoints')" clearable maxlength="50" :disabled="!msForm.existsBioticOther" />
                </el-form-item>
              </div>
            </div>
            <div class="div-table">
              <div class="div-table-row">
                <div class="div-table-head-name-col flew-center" style="width: 250px">检测危害因素</div>
                <div class="div-table-head-name-col flew-center">检测点数</div>
                <div class="div-table-head-name-col flew-center">超标点数</div>
                <div class="div-table-head-border"></div>
                <div class="div-table-head-name-col flew-center" style="width: 250px">检测危害因素</div>
                <div class="div-table-head-name-col flew-center">检测点数</div>
                <div class="div-table-head-name-col flew-center">超标点数</div>
                <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1308px);"></div>
              </div>
              <template v-for="(childs, i) in msForm.bioticOtherHazardList">
                <div class="div-table-row" :key="i">
                  <template v-for="(child, j) in childs">
                    <div class="div-table-td-name-col flew-center" style="justify-content: flex-start">
                      {{ child.hazardsName }}
                    </div>
                    <div class="div-table-td-num-col flew-center">
                      <el-form-item :id="'bioticOtherHazardList' + i + j" :prop="'bioticOtherHazardList.' + i + '.' + j + '.points'" :rules="msForm.existsBioticOther ? rules.points : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                        <el-input class="zwx-input" v-model.trim="child.points" style="width: 100% !important" @input="$validate.nonnegativeInteger(child, 'points')" maxlength="8" clearable :disabled="!msForm.existsBioticOther" />
                      </el-form-item>
                    </div>
                    <div class="div-table-td-num-col flew-center">
                      <el-form-item :id="'bioticOtherHazardList' + i + j" :prop="'bioticOtherHazardList.' + i + '.' + j + '.overproofPoints'" :rules="msForm.existsBioticOther ? rules.overproofPoints : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                        <el-input class="zwx-input" v-model.trim="child.overproofPoints" style="width: 100% !important" @input="$validate.nonnegativeInteger(child, 'overproofPoints')" maxlength="8" clearable :disabled="!msForm.existsBioticOther" />
                      </el-form-item>
                    </div>
                    <div :class="1 != j ? 'div-table-td-border' : ''" :style="i == msForm.bioticOtherHazardList.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''"></div>
                    <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 250px;"></div>
                    <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-num-col flew-center" style="width: 200px;"></div>
                    <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-num-col flew-center" style="width: 200px;"></div>
                  </template>
                  <div class="div-table-td-name-col" style="width: calc(100% - 1308px);"></div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <!-- 选择检测记录 -->
    <base-dialog ref="chooseDialogRef" class="choose-dialog" :pagination="true" @currentPage="pageSearch" :currentPage="chooseDialogForm.currentPage" :pageSize="chooseDialogForm.pageSize" :total="chooseDialogForm.total" @determine="chooseDialogSubmit" title="选择检测记录" @cancel="chooseDialogCancel" @close="chooseDialogClose">
      <el-form ref="chooseDialogForm" class="zwx-form" :model="chooseDialogForm">
        <el-form-item label-width="70px" label="检测机构：">
          <el-input class="zwx-input" v-model.trim="chooseDialogForm.organNameLike" placeholder="请输入" clearable maxlength="50" />
        </el-form-item>
        <el-form-item label-width="70px" label="报告编号：">
          <el-input class="zwx-input" v-model.trim="chooseDialogForm.reportCode" placeholder="请输入" clearable maxlength="50" />
        </el-form-item>
        <el-form-item label-width="98px" label="报告完成日期：">
          <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" v-model="chooseDialogForm.reportDateStart" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noBigDate($data['chooseDialogForm'], 'reportDateEnd', true)" placeholder="开始日期" />
          -
          <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" v-model="chooseDialogForm.reportDateEnd" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noSmallDate($data['chooseDialogForm'], 'reportDateStart', true)" placeholder="结束日期" />
        </el-form-item>
        <el-button style="margin-bottom:10px" class="zwx-button zwx-button-icontext-28" icon="el-icon-search" @click="queryList()">检索</el-button>
      </el-form>
      <!-- 列表展示部分 -->
      <!-- @select="rowSelect" @select-all="allSelect" -->
      <el-table class="zwx-table" ref="chooseTableRef" row-key="staffUuid" :data="chooseDialogForm.tableList" border stripe tooltip-effect="light" @selection-change="selectionChange">
        <el-table-column key="selection" type="selection" reserve-selection width="55" header-align="center" align="center"></el-table-column>
        <el-table-column prop="organName" label="检测机构" min-width="220" header-align="center" align="left"></el-table-column>
        <el-table-column prop="organCreditCode" label="社会信用代码" width="200" header-align="center" align="center"></el-table-column>
        <el-table-column prop="reportCode" label="报告编号" width="200" header-align="center" align="center"></el-table-column>
        <el-table-column prop="detectionType" label="检测类型" width="120" header-align="center" align="center" :formatter="detectionTypeFormatter"></el-table-column>
        <el-table-column prop="reportDate" label="报告完成日期" width="120" header-align="center" align="center" :formatter="dateFormatter"></el-table-column>
        <!-- <el-table-column prop="contactInfo" label="联系电话" min-width="120" header-align="left" align="left"></el-table-column> -->
      </el-table>
    </base-dialog>
  </div>
</template>

<script>
export default {
  name: 'HazardDetectionIndex',
  data() {
    return {
      api: this.$store.state.api,
      view: true,
      employerId: '',
      isShow: false,
      propPointsList1: [],
      propOverproofPointsList1: [],
      propPointsList2: [],
      propOverproofPointsList2: [],
      propPointsList3: [],
      propOverproofPointsList3: [],
      propPointsList4: [],
      propOverproofPointsList4: [],
      propPointsList5: [],
      propOverproofPointsList5: [],
      msForm: {
        rid: '',
        mainId: '',
        existsDetection: true,
        tdHazardDetectionRecordExpList: [], // 场所检测评价记录
        dustHazardList: [], // 粉尘危害因素接触集合
        chemicalHazardList: [], // 化学危害因素接触集合
        physicalHazardList: [], // 物理危害因素接触集合
        radioactivityHazardList: [], // 放射危害因素接触集合
        bioticOtherHazardList: [], // 生物危害和其他因素接触集合
        existsDust: true, // 粉尘_检测
        dustPoints: '', // 粉尘_检测点数
        dustOverproofPoints: '', //	粉尘_超标点数
        existsChemical: true, // 化学物质_检测
        chemicalPoints: '', // 化学物质_检测点数
        chemicalOverproofPoints: '', // 化学物质_超标点数
        existsPhysical: true, // 物理因素_检测
        physicalPoints: '', // 物理因素_检测点数
        physicalOverproofPoints: '', // 物理因素_超标点数
        existsRadioactivity: true, // 放射性因素_检测
        radioactivityPoints: '', // 放射性因素_检测点数
        radioactivityOverproofPoints: '', // 放射性因素_超标点数
        existsBioticOther: true, // 生物和其他因素_检测
        bioticOtherPoints: '', // 生物和其他因素_检测点数
        bioticOtherOverproofPoints: '', // 生物和其他因素_超标点数
      },
      organizationList: [], // 检测机构列表
      technicalServiceAgencyList: [],
      rules: {
        unitName: [{ required: true, message: '请选择检测机构', trigger: ['change', 'blur'] }],
        reportNo: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateReportNo, trigger: ['change', 'blur'] },
        ],
        reportDate: [{ required: true, message: '请选择报告完成日期', trigger: ['change', 'blur'] }],
        creditCode: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateCreditCode, trigger: ['blur', 'change'] },
        ],
        contactInfo: [{ required: false, validator: this.$validate.mbPeLdline, message: '请输入正确的联系电话', trigger: ['change', 'blur'] }],
        dustPoints: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalPoints, trigger: ['change', 'blur'] },
        ],
        dustOverproofPoints: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalOverproofPoints, trigger: ['change', 'blur'] },
        ],
        chemicalPoints: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalPoints, trigger: ['change', 'blur'] },
        ],
        chemicalOverproofPoints: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalOverproofPoints, trigger: ['change', 'blur'] },
        ],
        physicalPoints: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalPoints, trigger: ['change', 'blur'] },
        ],
        physicalOverproofPoints: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalOverproofPoints, trigger: ['change', 'blur'] },
        ],
        radioactivityPoints: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalPoints, trigger: ['change', 'blur'] },
        ],
        radioactivityOverproofPoints: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalOverproofPoints, trigger: ['change', 'blur'] },
        ],
        bioticOtherPoints: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalPoints, trigger: ['change', 'blur'] },
        ],
        bioticOtherOverproofPoints: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalOverproofPoints, trigger: ['change', 'blur'] },
        ],
        points: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validatePoints, trigger: ['change', 'blur'] },
        ],
        overproofPoints: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateOverproofPoints, trigger: ['change', 'blur'] },
        ],
        noRequired: [{ required: false, message: '', trigger: ['change', 'blur'] }],
      },
      chooseDialogForm: {
        organNameLike: null,
        reportCode: null,
        reportDateStart: null,
        reportDateEnd: null,
        currentPage: 1,
        pageSize: 10,
        total: 0,
        tableList: [],
      },
      dataList: [],
      finallyList: [],
    }
  },
  created() {},
  mounted() {
    this.employerId = this.$route.params.employerId
    this.msForm.mainId = this.$route.params.rid
    this.getHazardDetectionByMainID(true)
    this.queryList()
  },
  activated() {},
  computed: {},
  watch: {
    'msForm.existsDust': {
      handler() {
        if (!this.msForm.existsDust) {
          this.msForm.dustPoints = ''
          this.msForm.dustOverproofPoints = ''
          this.msForm.dustHazardList.forEach(child => {
            child.forEach(item => {
              this.$set(item, 'overproofPoints', '')
              this.$set(item, 'points', '')
            })
          })
        }
      },
      deep: true,
    },
    'msForm.existsChemical': {
      handler() {
        if (!this.msForm.existsChemical) {
          this.msForm.chemicalPoints = ''
          this.msForm.chemicalOverproofPoints = ''
          this.msForm.chemicalHazardList.forEach(child => {
            child.forEach(item => {
              this.$set(item, 'overproofPoints', '')
              this.$set(item, 'points', '')
            })
          })
        }
      },
      deep: true,
    },
    'msForm.existsPhysical': {
      handler() {
        if (!this.msForm.existsPhysical) {
          this.msForm.physicalPoints = ''

          this.msForm.physicalOverproofPoints = ''
          this.msForm.physicalHazardList.forEach(child => {
            child.forEach(item => {
              this.$set(item, 'overproofPoints', '')
              this.$set(item, 'points', '')
            })
          })
        }
      },
      deep: true,
    },
    'msForm.existsRadioactivity': {
      handler() {
        if (!this.msForm.existsRadioactivity) {
          this.msForm.radioactivityPoints = ''
          this.msForm.radioactivityOverproofPoints = ''
          this.msForm.radioactivityHazardList.forEach(child => {
            child.forEach(item => {
              this.$set(item, 'overproofPoints', '')
              this.$set(item, 'points', '')
            })
          })
        }
      },
      deep: true,
    },
    'msForm.existsBioticOther': {
      handler() {
        if (!this.msForm.existsBioticOther) {
          this.msForm.bioticOtherPoints = ''
          this.msForm.bioticOtherOverproofPoints = ''
          this.msForm.bioticOtherHazardList.forEach(child => {
            child.forEach(item => {
              this.$set(item, 'overproofPoints', '')
              this.$set(item, 'points', '')
            })
          })
        }
      },
      deep: true,
    },
    // msForm: {
    //   handler() {
    //     this.$parent.jsonData = JSON.stringify(this.msForm)
    //   },
    //   deep: true,
    // },
  },
  methods: {
    // 预览检测附件
    previewFile(detectionReportUid) {
      this.$emit('loading', true)
      let data = {
        mainUid: detectionReportUid,
      }
      this.$system.postJson(
        this.api + '/oh/workplace/detection/getReportAnnexType1ListByMainUid-1',
        data,
        true,
        true,
        data => {
          this.$emit('', false)
          if (data.type === '00') {
            this.openFilePreview(data.reportAnnexExpList, 0)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    openFilePreview(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
    // 弹框选择数据
    selectionChange(selection) {
      this.finallyList = selection
    },
    // 点击选择
    chooseData() {
      this.$refs.chooseDialogRef.show(true)
      if (!this.chooseDialogForm.tableList || !this.msForm.tdHazardDetectionRecordExpList) return
      this.$nextTick(() => {
        this.chooseDialogForm.tableList.forEach(item => {
          this.msForm.tdHazardDetectionRecordExpList.forEach(itemm => {
            if (item.reportCode == itemm.reportNo) {
              this.$refs.chooseTableRef && this.$refs.chooseTableRef.toggleRowSelection(item, true)
            }
          })
        })
      })
    },
    //弹框内检索
    queryList() {
      let data = {
        reportDateStart: this.chooseDialogForm.reportDateStart,
        reportDateEnd: this.chooseDialogForm.reportDateEnd,
        organNameLike: this.chooseDialogForm.organNameLike,
        reportCode: this.chooseDialogForm.reportCode,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/oh/workplace/detection/getDetectionReportListNoPageSize-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.dataList = data.tdDetectionReportList
            let count = this.chooseDialogForm.currentPage * this.chooseDialogForm.pageSize
            this.chooseDialogForm.tableList = this.dataList.slice(count - 10, count)
            this.chooseDialogForm.total = this.dataList.length
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    pageSearch(currentPage) {
      this.chooseDialogForm.currentPage = currentPage
      let count = this.chooseDialogForm.currentPage * this.chooseDialogForm.pageSize
      this.chooseDialogForm.tableList = this.dataList.slice(count - 10, count)
      this.chooseDialogForm.tableList.forEach(item => {
        // this.finallyList.forEach(itemm => {
        //   if (item.staffUuid == itemm.staffUuid) {
        //     this.$refs.chooseTableRef.toggleRowSelection(item, true)
        //   }
        // })
      })
    },
    chooseDialogCancel() {
      this.finallyList = []
      this.$refs.chooseTableRef.clearSelection()
      this.$nextTick(() => {
        this.$refs.chooseDialogRef.show(false)
      })
      this.dialogClickClose = true
    },
    chooseDialogClose() {
      // 清空
      this.chooseDialogForm.currentPage = 1
      this.chooseDialogForm.pageSize = 10
      this.chooseDialogForm.total = 0
      this.finallyList = []
      this.chooseDialogForm.tableList = []
      this.queryList()
    },
    chooseDialogSubmit() {
      if (this.finallyList.length == 0) {
        this.$system.notify('提示', '请选择检测记录', 'info')
      } else {
        this.dialogSave()
      }
    },
    // 选择弹框点击确认
    dialogSave() {
      this.finallyList.forEach(item => {
        item.unitName = item.organName
        item.reportNo = item.reportCode
        item.creditCode = item.organCreditCode
        item.detectionReportUid = item.uuid
        // item.contactInfo = item.contactInfo
      })
      let list = this.msForm.tdHazardDetectionRecordExpList.concat(this.finallyList)
      this.msForm.tdHazardDetectionRecordExpList = this.cutarray(list)
      this.$refs.chooseDialogRef.show(false)
    },
    //数组去重
    cutarray(arr) {
      let obj = {} //obj用来记录该项重复出现了几次
      let brr = [] //brr是去重后的数组
      arr.forEach(item => {
        if (obj[item.reportNo] == undefined) {
          obj[item.reportNo] = 1
          brr.push(item)
        } else {
          obj[item.reportNo] += 1
        }
      })
      return brr
    },
    //检测类型
    detectionTypeFormatter(row, column, cellValue, index) {
      // 1：定期检测 2：现状评价 3：监督检测 4：复测 5：其他检测
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        let str = ''
        switch (cellValue) {
          case 1:
            str = '定期检测'
            break
          case 2:
            str = '现状评价'
            break
          case 3:
            str = '监督检测'
            break
          case 4:
            str = '复测'
            break
          case 5:
            str = '其他检测'
            break
          default:
            break
        }
        return str
      }
    },
    /**
     * 日期格式化
     */
    dateFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        return this.$system.formatDate(cellValue, 'YYYY-MM-DD')
      }
    },
    // 体检机构下拉框选择
    changeSelect(value, row) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        let list = this.organizationList.filter(e => value === e.organName)
        this.$set(row, 'creditCode', list[0].organCreditCode)
        this.$set(row, 'unitName', list[0].organName)
      } else {
        row.unitName = ''
        row.creditCode = ''
      }
    },
    /**
     * 获取下拉检测机构
     */
    getServiceContractList() {
      let data = {
        // 用人单位id
        employerId: this.employerId,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/oh/declaration/getServiceContractList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.organizationList = data.tdDetectionServiceContractList
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    existsDetectionChange(val) {
      // this.isShow = val
      if (this.isShow) {
        this.$confirm('修改后将清空下方填写内容，是否确定?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' })
          .then(() => {
            this.isShow = false
            this.msForm.existsDust = false
            this.msForm.existsChemical = false
            this.msForm.existsPhysical = false
            this.msForm.existsRadioactivity = false
            this.msForm.existsBioticOther = false
          })
          .catch(() => {
            this.isShow = true
            this.msForm.existsDetection = true
          })
      } else {
        this.getHazardDetectionByMainID(false)
      }
    },
    // 检测报告编号不可重复
    validateReportNo(rule, value, callback) {
      let index = rule.field.split('.')[1]
      for (let i = 0; i < this.msForm.tdHazardDetectionRecordExpList.length; i++) {
        if (index != i && value == this.msForm.tdHazardDetectionRecordExpList[i].reportNo) {
          callback(new Error('检测报告编号不可重复'))
        }
      }
      callback()
    },
    /**
     * 总检测点数校验
     * 1.大类已勾选，总监测点数大于0
     * 2.总检测点数≥总超标点数
     * 3.总检测点数≥各小项点数之和
     */
    validateTotalPoints(rule, value, callback) {
      // 正整数的正则表达式
      let pattern = /^[1-9][0-9]*$/
      if (!pattern.test(value)) {
        callback('大于0')
      }
      let ifempty = false
      let listName = rule.field
      if (listName === 'dustPoints') {
        if (this.$zwxBase.verifyIsNotBlank(this.msForm.dustOverproofPoints) && Number(value) < Number(this.msForm.dustOverproofPoints)) {
          callback(new Error('总检测点数应大于等于总超标点数（' + Number(this.msForm.dustOverproofPoints) + '）'))
        }

        let list = this.$zwxBase.arrayDeal(this.msForm.dustHazardList)
        list.map(item => {
          if (this.$zwxBase.verifyIsNotBlank(item.points)) {
            ifempty = true
          }
        })
        let total = 0
        for (let i = 0; i < list.length; i++) {
          total += list[i].points ? parseInt(list[i].points) : 0
        }
        if (total > parseInt(this.msForm.dustPoints) && ifempty) {
          callback(new Error('总检测点数应大于等于各检测点数之和（' + total + '）'))
        }
      }
      if (listName === 'chemicalPoints') {
        if (this.msForm.chemicalOverproofPoints && value && Number(value) < Number(this.msForm.chemicalOverproofPoints)) {
          callback(new Error('总检测点数应大于等于总超标点数（' + Number(this.msForm.chemicalOverproofPoints) + '）'))
        }
        let total = 0
        let list = this.$zwxBase.arrayDeal(this.msForm.chemicalHazardList)
        list.map(item => {
          if (this.$zwxBase.verifyIsNotBlank(item.points)) {
            ifempty = true
          }
        })
        for (let i = 0; i < list.length; i++) {
          total += list[i].points ? parseInt(list[i].points) : 0
        }
        if (total > parseInt(this.msForm.chemicalPoints) && ifempty) {
          callback(new Error('总检测点数应大于等于各检测点数之和（' + total + '）'))
        }
      }
      if (listName === 'physicalPoints') {
        if (this.msForm.physicalOverproofPoints && value && Number(value) < Number(this.msForm.physicalOverproofPoints)) {
          callback(new Error('总检测点数应大于等于总超标点数（' + Number(this.msForm.physicalOverproofPoints) + '）'))
        }
        let total = 0
        let list = this.$zwxBase.arrayDeal(this.msForm.physicalHazardList)
        list.map(item => {
          if (this.$zwxBase.verifyIsNotBlank(item.points)) {
            ifempty = true
          }
        })
        for (let i = 0; i < list.length; i++) {
          total += list[i].points ? parseInt(list[i].points) : 0
        }
        if (total > parseInt(this.msForm.physicalPoints) && ifempty) {
          callback(new Error('总检测点数应大于等于各检测点数之和（' + total + '）'))
        }
      }
      if (listName === 'radioactivityPoints') {
        if (this.msForm.radioactivityOverproofPoints && value && Number(value) < Number(this.msForm.radioactivityOverproofPoints)) {
          callback(new Error('总检测点数应大于等于总超标点数（' + Number(this.msForm.radioactivityOverproofPoints) + '）'))
        }
        let total = 0
        let list = this.$zwxBase.arrayDeal(this.msForm.radioactivityHazardList)
        list.map(item => {
          if (this.$zwxBase.verifyIsNotBlank(item.points)) {
            ifempty = true
          }
        })
        for (let i = 0; i < list.length; i++) {
          total += list[i].points ? parseInt(list[i].points) : 0
        }
        if (total > parseInt(this.msForm.radioactivityPoints) && ifempty) {
          callback(new Error('总检测点数应大于等于各检测点数之和（' + total + '）'))
        }
      }
      if (listName === 'bioticOtherPoints') {
        if (this.msForm.bioticOtherOverproofPoints && value && Number(value) < Number(this.msForm.bioticOtherOverproofPoints)) {
          callback(new Error('总检测点数应大于等于总超标点数（' + Number(this.msForm.bioticOtherOverproofPoints) + '）'))
        }
        let total = 0
        let list = this.$zwxBase.arrayDeal(this.msForm.bioticOtherHazardList)
        list.map(item => {
          if (this.$zwxBase.verifyIsNotBlank(item.points)) {
            ifempty = true
          }
        })
        for (let i = 0; i < list.length; i++) {
          total += list[i].points ? parseInt(list[i].points) : 0
        }
        if (total > parseInt(this.msForm.bioticOtherPoints) && ifempty) {
          callback(new Error('总检测点数应大于等于各检测点数之和（' + total + '）'))
        }
      }
      callback()
    },
    /**
     * 总超标点数校验
     * 1.总超标点数<=总检测点数
     * 2.总超标点数 ≥  各小项超标点数之和
     */
    validateTotalOverproofPoints(rule, value, callback) {
      let listName = rule.field
      let ifempty = false
      if (listName === 'dustOverproofPoints') {
        if (this.msForm.dustPoints && Number(value) > Number(this.msForm.dustPoints)) {
          callback(new Error('总超标点数应小于等于总检测点数（' + Number(this.msForm.dustPoints) + '）'))
        }
        let list = this.$zwxBase.arrayDeal(this.msForm.dustHazardList)
        list.map(item => {
          if (this.$zwxBase.verifyIsNotBlank(item.overproofPoints)) {
            ifempty = true
          }
        })
        let total = 0
        list.forEach(item => {
          total += item.overproofPoints ? parseInt(item.overproofPoints) : 0
        })
        if (total > parseInt(this.msForm.dustOverproofPoints) && ifempty) {
          callback(new Error('总超标点数应大于等于各超标点数之和（' + total + '）'))
        }
      }
      if (listName === 'chemicalOverproofPoints') {
        if (this.msForm.chemicalPoints && value && Number(value) > Number(this.msForm.chemicalPoints)) {
          callback(new Error('总超标点数应小于等于总检测点数（' + Number(this.msForm.chemicalPoints) + '）'))
        }
        let total = 0
        let list = this.$zwxBase.arrayDeal(this.msForm.chemicalHazardList)
        list.map(item => {
          if (this.$zwxBase.verifyIsNotBlank(item.overproofPoints)) {
            ifempty = true
          }
        })
        list.forEach(item => {
          total += item.overproofPoints ? parseInt(item.overproofPoints) : 0
        })
        if (total > parseInt(this.msForm.chemicalOverproofPoints) && ifempty) {
          callback(new Error('总超标点数应大于等于各超标点数之和（' + total + '）'))
        }
      }
      if (listName === 'physicalOverproofPoints') {
        if (this.msForm.physicalPoints && value && Number(value) > Number(this.msForm.physicalPoints)) {
          callback(new Error('总超标点数应小于等于总检测点数（' + Number(this.msForm.physicalPoints) + '）'))
        }
        let total = 0
        let list = this.$zwxBase.arrayDeal(this.msForm.physicalHazardList)
        list.map(item => {
          if (this.$zwxBase.verifyIsNotBlank(item.overproofPoints)) {
            ifempty = true
          }
        })
        list.forEach(item => {
          total += item.overproofPoints ? parseInt(item.overproofPoints) : 0
        })
        if (total > parseInt(this.msForm.physicalOverproofPoints) && ifempty) {
          callback(new Error('总超标点数应大于等于各超标点数之和（' + total + '）'))
        }
      }
      if (listName === 'radioactivityOverproofPoints') {
        if (this.msForm.radioactivityPoints && value && Number(value) > Number(this.msForm.radioactivityPoints)) {
          callback(new Error('总超标点数应小于等于总检测点数（' + Number(this.msForm.radioactivityPoints) + '）'))
        }
        let total = 0
        let list = this.$zwxBase.arrayDeal(this.msForm.radioactivityHazardList)
        list.map(item => {
          if (this.$zwxBase.verifyIsNotBlank(item.overproofPoints)) {
            ifempty = true
          }
        })
        list.forEach(item => {
          total += item.overproofPoints ? parseInt(item.overproofPoints) : 0
        })
        if (total > parseInt(this.msForm.radioactivityOverproofPoints) && ifempty) {
          callback(new Error('总超标点数应大于等于各超标点数之和（' + total + '）'))
        }
      }
      if (listName === 'bioticOtherOverproofPoints') {
        if (this.msForm.bioticOtherPoints && value && Number(value) > Number(this.msForm.bioticOtherPoints)) {
          callback(new Error('总超标点数应小于等于总检测点数（' + Number(this.msForm.bioticOtherPoints) + '）'))
        }
        let total = 0
        let list = this.$zwxBase.arrayDeal(this.msForm.bioticOtherHazardList)
        list.map(item => {
          if (this.$zwxBase.verifyIsNotBlank(item.overproofPoints)) {
            ifempty = true
          }
        })
        list.forEach(item => {
          total += item.overproofPoints ? parseInt(item.overproofPoints) : 0
        })
        if (total > parseInt(this.msForm.bioticOtherOverproofPoints) && ifempty) {
          callback(new Error('总超标点数应大于等于各超标点数之和（' + total + '）'))
        }
      }
      callback()
    },
    /**
     * 明细检测点数校验
     * 1.各小项检测点数<=总检测点数
     * 2.各小项检测点数≥ 各小项超标点数
     */
    validatePoints(rule, value, callback) {
      let listName = rule.field.split('.')[0]
      let i = rule.field.split('.')[1]
      let j = rule.field.split('.')[2]
      // 已输入的总检测点数
      let pointCount = 0
      // 已输入的总超标点数
      this.msForm[listName].map(item => {
        item.map(val => {
          pointCount += parseInt(val.points || 0)
        })
      })
      if (listName === 'dustHazardList') {
        let factorObj = this.msForm.dustHazardList[i][j]
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(this.msForm.dustPoints) && factorObj.points > parseInt(this.msForm.dustPoints)) {
          callback(new Error('该检测点数应小于等于总检测点数（' + parseInt(this.msForm.dustPoints) + '）'))
        }
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(this.msForm.dustPoints) && pointCount > parseInt(this.msForm.dustPoints)) {
          callback(new Error('各检测点数之和应小于等于总检测点数（' + parseInt(this.msForm.dustPoints) + '）'))
        }
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && parseInt(factorObj.points) < parseInt(factorObj.overproofPoints)) {
          callback(new Error('检测点数应大于等于超标点数（' + parseInt(factorObj.overproofPoints) + '）'))
        }
      }
      if (listName === 'chemicalHazardList') {
        let factorObj = this.msForm.chemicalHazardList[i][j]
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(this.msForm.chemicalPoints) && factorObj.points > parseInt(this.msForm.chemicalPoints)) {
          callback(new Error('该检测点数应小于等于总检测点数（' + parseInt(this.msForm.chemicalPoints) + '）'))
        }
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(this.msForm.chemicalPoints) && pointCount > parseInt(this.msForm.chemicalPoints)) {
          callback(new Error('各检测点数之和应小于等于总检测点数（' + parseInt(this.msForm.chemicalPoints) + '）'))
        }
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && parseInt(factorObj.points) < parseInt(factorObj.overproofPoints)) {
          callback(new Error('检测点数应大于等于超标点数（' + parseInt(factorObj.overproofPoints) + '）'))
        }
      }
      if (listName === 'physicalHazardList') {
        let factorObj = this.msForm.physicalHazardList[i][j]
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(this.msForm.physicalPoints) && factorObj.points > parseInt(this.msForm.physicalPoints)) {
          callback(new Error('该检测点数应小于等于总检测点数（' + parseInt(this.msForm.physicalPoints) + '）'))
        }
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(this.msForm.physicalPoints) && pointCount > parseInt(this.msForm.physicalPoints)) {
          callback(new Error('各检测点数之和应小于等于总检测点数（' + parseInt(this.msForm.physicalPoints) + '）'))
        }
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && parseInt(factorObj.points) < parseInt(factorObj.overproofPoints)) {
          callback(new Error('检测点数应大于等于超标点数）' + parseInt(factorObj.overproofPoints) + '）'))
        }
      }
      if (listName === 'radioactivityHazardList') {
        let factorObj = this.msForm.radioactivityHazardList[i][j]
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(this.msForm.radioactivityPoints) && factorObj.points > parseInt(this.msForm.radioactivityPoints)) {
          callback(new Error('该检测点数应小于等于总检测点数（' + parseInt(this.msForm.radioactivityPoints) + '）'))
        }
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(this.msForm.radioactivityPoints) && pointCount > parseInt(this.msForm.radioactivityPoints)) {
          callback(new Error('各检测点数之和应小于等于总检测点数（' + parseInt(this.msForm.radioactivityPoints) + '）'))
        }
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && parseInt(factorObj.points) < parseInt(factorObj.overproofPoints)) {
          callback(new Error('检测点数应大于等于超标点数（' + parseInt(factorObj.overproofPoints) + '）'))
        }
      }
      if (listName === 'bioticOtherHazardList') {
        let factorObj = this.msForm.bioticOtherHazardList[i][j]
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(this.msForm.bioticOtherPoints) && factorObj.points > parseInt(this.msForm.bioticOtherPoints)) {
          callback(new Error('该检测点数应小于等于总检测点数（' + parseInt(this.msForm.bioticOtherPoints) + '）'))
        }
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(this.msForm.bioticOtherPoints) && pointCount > parseInt(this.msForm.bioticOtherPoints)) {
          callback(new Error('各检测点数之和应小于等于总检测点数（' + parseInt(this.msForm.bioticOtherPoints) + '）'))
        }
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && parseInt(factorObj.points) < parseInt(factorObj.overproofPoints)) {
          callback(new Error('检测点数应大于等于超标点数（' + parseInt(factorObj.overproofPoints) + '）'))
        }
      }
      callback()
    },
    /**
     * 明细超标点数校验
     * 1.各小项超标点数<=总超标点数
     * 2.各小项超标点数<= 各小项检测点数
     */
    validateOverproofPoints(rule, value, callback) {
      let listName = rule.field.split('.')[0]
      let i = rule.field.split('.')[1]
      let j = rule.field.split('.')[2]
      // 已输入的总超标点数
      let overproofPointsCount = 0
      this.msForm[listName].map(item => {
        item.map(val => {
          overproofPointsCount += parseInt(val.overproofPoints || 0)
        })
      })
      if (listName === 'dustHazardList') {
        let factorObj = this.msForm.dustHazardList[i][j]
        if (this.msForm.existsDust === true && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && parseInt(factorObj.overproofPoints) > parseInt(this.msForm.dustOverproofPoints)) {
          callback(new Error('该超标点数应小于等于总超标点数（' + parseInt(this.msForm.dustOverproofPoints) + '）'))
        }
        if (this.msForm.existsDust === true && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && this.$zwxBase.verifyIsNotBlank(this.msForm.dustOverproofPoints) && overproofPointsCount > parseInt(this.msForm.dustOverproofPoints)) {
          callback(new Error('各超标点数之和应小于等于总超标点数（' + parseInt(this.msForm.dustOverproofPoints) + '）'))
        }
        if (this.msForm.existsDust === true && this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && parseInt(factorObj.points) < parseInt(factorObj.overproofPoints)) {
          callback(new Error('超标点数应小于等于检测点数（' + parseInt(factorObj.points) + '）'))
        }
      }
      if (listName === 'chemicalHazardList') {
        let factorObj = this.msForm.chemicalHazardList[i][j]
        if (this.msForm.existsDust === true && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && parseInt(factorObj.overproofPoints) > parseInt(this.msForm.chemicalOverproofPoints)) {
          callback(new Error('该超标点数应小于等于总超标点数（' + parseInt(this.msForm.chemicalOverproofPoints) + '）'))
        }
        if (this.msForm.existsChemical === true && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && this.$zwxBase.verifyIsNotBlank(this.msForm.chemicalOverproofPoints) && overproofPointsCount > parseInt(this.msForm.chemicalOverproofPoints)) {
          callback(new Error('各超标点数之和应小于等于总超标点数（' + parseInt(this.msForm.chemicalOverproofPoints) + '）'))
        }
        if (this.msForm.existsChemical === true && this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && parseInt(factorObj.points) < parseInt(factorObj.overproofPoints)) {
          callback(new Error('超标点数应小于等于检测点数（' + parseInt(factorObj.points) + '）'))
        }
      }
      if (listName === 'physicalHazardList') {
        let factorObj = this.msForm.physicalHazardList[i][j]
        if (this.msForm.existsDust === true && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && parseInt(factorObj.overproofPoints) > parseInt(this.msForm.physicalOverproofPoints)) {
          callback(new Error('该超标点数应小于等于总超标点数（' + parseInt(this.msForm.physicalOverproofPoints) + '）'))
        }
        if (this.msForm.existsPhysical === true && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && this.$zwxBase.verifyIsNotBlank(this.msForm.physicalOverproofPoints) && overproofPointsCount > parseInt(this.msForm.physicalOverproofPoints)) {
          callback(new Error('各超标点数之和应小于等于总超标点数（' + parseInt(this.msForm.physicalOverproofPoints) + '）'))
        }
        if (this.msForm.existsPhysical === true && this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && parseInt(factorObj.points) < parseInt(factorObj.overproofPoints)) {
          callback(new Error('超标点数应小于等于检测点数（' + parseInt(factorObj.points) + '）'))
        }
      }
      if (listName === 'radioactivityHazardList') {
        let factorObj = this.msForm.radioactivityHazardList[i][j]
        if (this.msForm.existsDust === true && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && parseInt(factorObj.overproofPoints) > parseInt(this.msForm.radioactivityOverproofPoints)) {
          callback(new Error('该超标点数应小于等于总超标点数（' + parseInt(this.msForm.radioactivityOverproofPoints) + '）'))
        }
        if (this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && this.$zwxBase.verifyIsNotBlank(this.msForm.radioactivityOverproofPoints) && overproofPointsCount > parseInt(this.msForm.radioactivityOverproofPoints)) {
          callback(new Error('各超标点数之和应小于等于总超标点数（' + parseInt(this.msForm.radioactivityOverproofPoints) + '）'))
        }
        if (this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && parseInt(factorObj.points) < parseInt(factorObj.overproofPoints)) {
          callback(new Error('超标点数应小于等于检测点数（' + parseInt(factorObj.points) + '）'))
        }
      }
      if (listName === 'bioticOtherHazardList') {
        let factorObj = this.msForm.bioticOtherHazardList[i][j]
        if (this.msForm.existsDust === true && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && parseInt(factorObj.overproofPoints) > parseInt(this.msForm.bioticOtherOverproofPoints)) {
          callback(new Error('该超标点数应小于等于总超标点数（' + parseInt(this.msForm.bioticOtherOverproofPoints) + '）'))
        }
        if (this.msForm.existsBioticOther === true && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && this.$zwxBase.verifyIsNotBlank(this.msForm.bioticOtherOverproofPoints) && overproofPointsCount > parseInt(this.msForm.bioticOtherOverproofPoints)) {
          callback(new Error('各超标点数之和应小于等于总超标点数（' + parseInt(this.msForm.bioticOtherOverproofPoints) + '）'))
        }
        if (this.msForm.existsBioticOther === true && this.$zwxBase.verifyIsNotBlank(factorObj.points) && this.$zwxBase.verifyIsNotBlank(factorObj.overproofPoints) && parseInt(factorObj.points) < parseInt(factorObj.overproofPoints)) {
          callback(new Error('超标点数应小于等于检测点数（' + parseInt(factorObj.points) + '）'))
        }
      }
      callback()
    },
    // 查询职业病危害因素检测情况
    getHazardDetectionByMainID(flag) {
      this.$parent.loading = true
      let fkByMainId = {
        rid: this.msForm.mainId,
      }
      let data = {
        employerId: this.employerId,
        fkByMainId: fkByMainId,
      }
      if (!flag) {
        data.ifClickYes = true
      }
      this.$system.postJson(
        this.api + '/oh/declaration/getHazardDetectionByMainID-1',
        data,
        true,
        true,
        data => {
          this.$parent.loading = false
          if (data.type === '00') {
            this.msForm.rid = data.hazardDistribution.rid // 场所检测评价记录
            if (flag) {
              this.msForm.existsDetection = data.hazardDistribution.existsDetection
              this.isShow = data.hazardDistribution.existsDetection
            } else {
              this.msForm.existsDetection = true
              this.isShow = true
            }
            this.msForm.existsDust = data.hazardDistribution.existsDust
            this.msForm.dustPoints = data.hazardDistribution.dustPoints
            this.msForm.dustOverproofPoints = data.hazardDistribution.dustOverproofPoints
            this.msForm.existsChemical = data.hazardDistribution.existsChemical
            this.msForm.chemicalPoints = data.hazardDistribution.chemicalPoints
            this.msForm.chemicalOverproofPoints = data.hazardDistribution.chemicalOverproofPoints
            this.msForm.existsPhysical = data.hazardDistribution.existsPhysical
            this.msForm.physicalPoints = data.hazardDistribution.physicalPoints
            this.msForm.physicalOverproofPoints = data.hazardDistribution.physicalOverproofPoints
            this.msForm.existsRadioactivity = data.hazardDistribution.existsRadioactivity
            this.msForm.radioactivityPoints = data.hazardDistribution.radioactivityPoints
            this.msForm.radioactivityOverproofPoints = data.hazardDistribution.radioactivityOverproofPoints
            this.msForm.existsBioticOther = data.hazardDistribution.existsBioticOther
            this.msForm.bioticOtherPoints = data.hazardDistribution.bioticOtherPoints
            this.msForm.bioticOtherOverproofPoints = data.hazardDistribution.bioticOtherOverproofPoints
            this.msForm.tdHazardDetectionRecordExpList = data.tdHazardDetectionRecordExpList || [] // 场所检测评价记录
            if (this.$zwxBase.verifyIsNotBlank(data.dustHazardList)) {
              this.msForm.dustHazardList = this.$zwxBase.multidimensionalArrayDeal(data.dustHazardList, 2)
            }
            if (this.$zwxBase.verifyIsNotBlank(data.chemicalHazardList)) {
              this.msForm.chemicalHazardList = this.$zwxBase.multidimensionalArrayDeal(data.chemicalHazardList, 2)
            }
            if (this.$zwxBase.verifyIsNotBlank(data.physicalHazardList)) {
              this.msForm.physicalHazardList = this.$zwxBase.multidimensionalArrayDeal(data.physicalHazardList, 2)
            }
            if (this.$zwxBase.verifyIsNotBlank(data.radioactivityHazardList)) {
              this.msForm.radioactivityHazardList = this.$zwxBase.multidimensionalArrayDeal(data.radioactivityHazardList, 2)
            }
            if (this.$zwxBase.verifyIsNotBlank(data.bioticOtherHazardList)) {
              this.msForm.bioticOtherHazardList = this.$zwxBase.multidimensionalArrayDeal(data.bioticOtherHazardList, 2)
            }
            if (flag) {
              this.$nextTick(() => {
                this.$parent.jsonData = JSON.stringify(this.msForm)
              })
            }
            this.$parent.saveDisabled = false
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    // 是否选中
    itemSelect(val) {
      if (1 == val) {
        this.msForm.existsDust = !this.msForm.existsDust
      } else if (2 == val) {
        this.msForm.existsChemical = !this.msForm.existsChemical
      } else if (3 == val) {
        this.msForm.existsPhysical = !this.msForm.existsPhysical
      } else if (4 == val) {
        this.msForm.existsRadioactivity = !this.msForm.existsRadioactivity
      } else if (5 == val) {
        this.msForm.existsBioticOther = !this.msForm.existsBioticOther
      }
      //this.$forceUpdate()
    },
    // 检测机构列表添加
    addList() {
      this.msForm.tdHazardDetectionRecordExpList.push({
        unitName: '',
        reportNo: '',
        creditCode: '',
        contactInfo: '',
      })
    },
    detectionOrganSelectChange(value, index) {
      this.$set(this.msForm.tdHazardDetectionRecordExpList[index], 'unitName', value.unitName)
      this.$set(this.msForm.tdHazardDetectionRecordExpList[index], 'creditCode', value.creditCode)
      this.$set(this.msForm.tdHazardDetectionRecordExpList[index], 'contactInfo', value.linkMobile)
    },
    // 检测机构列表删除
    deletion(index) {
      if (this.msForm.tdHazardDetectionRecordExpList.length < 2) {
        this.$system.notify('提示', '至少填写一条检测机构记录', 'info')
      } else if (this.msForm.tdHazardDetectionRecordExpList[index].unitName != '' || this.msForm.tdHazardDetectionRecordExpList[index].reportNo != '' || this.msForm.tdHazardDetectionRecordExpList[index].creditCode != '' || this.msForm.tdHazardDetectionRecordExpList[index].contactInfo != '') {
        this.$system.msgbox('', '提示', '是否确定删除？', '确定', '取消', () => {
          this.msForm.tdHazardDetectionRecordExpList.splice(index, 1)
        })
      } else {
        this.msForm.tdHazardDetectionRecordExpList.splice(index, 1)
      }
    },
    /**
     * 保存/更新
     */
    saveOrUpdateHazardDetection(opType) {
      if (0 == opType || 2 == opType) {
        //不校验
        this.saveHazardDetection(opType)
      } else {
        // 校验
        if (!this.isShow) {
          this.saveHazardDetection(opType)
        } else {
          var tdHazardDetectionRecordExpListTF = true
          if (this.msForm.tdHazardDetectionRecordExpList.length < 1) {
            this.$system.notify('提示', '至少填写一条检测机构记录', 'info')
            tdHazardDetectionRecordExpListTF = false
          }
          var chooseOne = true
          if (this.msForm.existsDetection && this.msForm.existsDust == false && this.msForm.existsChemical == false && this.msForm.existsPhysical == false && this.msForm.existsRadioactivity == false && this.msForm.existsBioticOther == false) {
            chooseOne = false
            this.$system.notify('提示', '至少填写一条危害因素检测情况', 'info')
          }
          this.$refs.hazardDetectionIndexRef.validate((valid, aaa) => {
            if (valid && tdHazardDetectionRecordExpListTF && chooseOne) {
              if (!this.$parent.saveDisabled) {
                this.$parent.saveDisabled = true
                this.saveHazardDetection(opType)
              }
            }
          })
        }
      }
    },
    saveHazardDetection(opType) {
      if (!this.isShow) {
        this.msForm.tdHazardDetectionRecordExpList = []
      }
      let hazardDetectionDetailList = []
      let flag1 = false
      let flagover1 = false
      if (this.msForm.dustHazardList.length > 0) {
        let list = this.$zwxBase.arrayDeal(this.msForm.dustHazardList)
        list.forEach(item => {
          if (parseInt(item.points) > 0) {
            flag1 = true
          }
          if (parseInt(item.overproofPoints) > 0) {
            flagover1 = true
          }
          hazardDetectionDetailList.push(item)
        })
      }
      if (1 == opType && this.msForm.existsDust && !flag1 && this.msForm.dustHazardList.length > 0) {
        this.$parent.saveDisabled = false
        this.$notify.info({ title: '提示', message: '粉尘因素检测点数不能全部为0' })
        return false
      }
      // 如果超标总人数填写，超标小项不能全部为0
      if (1 == opType && this.msForm.existsDust && this.msForm.dustOverproofPoints && parseInt(this.msForm.dustOverproofPoints) > 0 && !flagover1 && this.msForm.dustHazardList.length > 0) {
        this.$parent.saveDisabled = false
        this.$notify.info({ title: '提示', message: '粉尘因素超标点数不能全部为0' })
        return false
      }
      let flag2 = false
      let flagover2 = false
      if (this.msForm.chemicalHazardList.length > 0) {
        let list2 = this.$zwxBase.arrayDeal(this.msForm.chemicalHazardList)
        list2.forEach(item => {
          if (parseInt(item.points) > 0) {
            flag2 = true
          }
          if (parseInt(item.overproofPoints) > 0) {
            flagover2 = true
          }
          hazardDetectionDetailList.push(item)
        })
      }
      if (1 == opType && this.msForm.existsChemical && !flag2 && this.msForm.chemicalHazardList.length > 0) {
        this.$parent.saveDisabled = false
        this.$notify.info({ title: '提示', message: '化学因素检测点数不能全部为0' })
        return false
      }
      // 如果超标总人数填写，超标小项不能全部为0
      if (1 == opType && this.msForm.existsChemical && this.msForm.chemicalOverproofPoints && parseInt(this.msForm.chemicalOverproofPoints) > 0 && !flagover2 && this.msForm.chemicalHazardList.length > 0) {
        this.$parent.saveDisabled = false
        this.$notify.info({ title: '提示', message: '化学因素超标点数不能全部为0' })
        return false
      }
      let flag3 = false
      let flagover3 = false
      if (this.msForm.physicalHazardList.length > 0) {
        let list3 = this.$zwxBase.arrayDeal(this.msForm.physicalHazardList)
        list3.forEach(item => {
          if (parseInt(item.points) > 0) {
            flag3 = true
          }
          if (parseInt(item.overproofPoints) > 0) {
            flagover3 = true
          }
          hazardDetectionDetailList.push(item)
        })
      }
      if (1 == opType && this.msForm.existsPhysical && !flag3 && this.msForm.physicalHazardList.length > 0) {
        this.$parent.saveDisabled = false
        this.$notify.info({ title: '提示', message: '物理因素检测点数不能全部为0' })
        return false
      }
      // 如果超标总人数填写，超标小项不能全部为0
      if (1 == opType && this.msForm.existsPhysical && this.msForm.physicalOverproofPoints && parseInt(this.msForm.physicalOverproofPoints) > 0 && !flagover3 && this.msForm.physicalHazardList.length > 0) {
        this.$parent.saveDisabled = false
        this.$notify.info({ title: '提示', message: '物理因素超标点数不能全部为0' })
        return false
      }
      let flag4 = false
      let flagover4 = false
      if (this.msForm.radioactivityHazardList.length > 0) {
        let list4 = this.$zwxBase.arrayDeal(this.msForm.radioactivityHazardList)
        list4.forEach(item => {
          if (parseInt(item.points) > 0) {
            flag4 = true
          }
          if (parseInt(item.overproofPoints) > 0) {
            flagover4 = true
          }
          hazardDetectionDetailList.push(item)
        })
      }
      if (1 == opType && this.msForm.existsRadioactivity && !flag4 && this.msForm.radioactivityHazardList.length > 0) {
        this.$parent.saveDisabled = false
        this.$notify.info({ title: '提示', message: '放射因素检测点数不能全部为0' })
        return false
      }
      // 如果超标总人数填写，超标小项不能全部为0
      if (1 == opType && this.msForm.existsRadioactivity && this.msForm.radioactivityOverproofPoints && parseInt(this.msForm.radioactivityOverproofPoints) > 0 && !flagover4 && this.msForm.radioactivityHazardList.length > 0) {
        this.$parent.saveDisabled = false
        this.$notify.info({ title: '提示', message: '放射因素超标点数不能全部为0' })
        return false
      }
      let flag5 = false
      let flagover5 = false
      if (this.msForm.bioticOtherHazardList.length > 0) {
        let list5 = this.$zwxBase.arrayDeal(this.msForm.bioticOtherHazardList)
        list5.forEach(item => {
          if (parseInt(item.points) > 0) {
            flag5 = true
          }
          if (parseInt(item.overproofPoints) > 0) {
            flagover5 = true
          }
          hazardDetectionDetailList.push(item)
        })
      }
      if (1 == opType && this.msForm.existsBioticOther && !flag5 && this.msForm.bioticOtherHazardList.length > 0) {
        this.$parent.saveDisabled = false
        this.$notify.info({ title: '提示', message: '生物和其他因素检测点数不能全部为0' })
        return false
      }
      // 如果超标总人数填写，超标小项不能全部为0
      if (1 == opType && this.msForm.existsBioticOther && this.msForm.bioticOtherOverproofPoints && parseInt(this.msForm.bioticOtherOverproofPoints) > 0 && !flagover5 && this.msForm.bioticOtherHazardList.length > 0) {
        this.$parent.saveDisabled = false
        this.$notify.info({ title: '提示', message: '生物和其他因素超标点数不能全部为0' })
        return false
      }

      this.$parent.loading = true
      let fkByMainId = {
        rid: this.msForm.mainId,
      }
      let data = {
        tdHazardDetectionRecordExpList: this.msForm.tdHazardDetectionRecordExpList,
        hazardDetectionDetailList: hazardDetectionDetailList,
        rid: this.msForm.rid,
        fkByMainId: fkByMainId,
        existsDetection: this.msForm.existsDetection,
        existsDust: this.msForm.existsDust,
        dustPoints: this.msForm.dustPoints,
        dustOverproofPoints: this.msForm.dustOverproofPoints,
        existsChemical: this.msForm.existsChemical,
        chemicalPoints: this.msForm.chemicalPoints,
        chemicalOverproofPoints: this.msForm.chemicalOverproofPoints,
        existsPhysical: this.msForm.existsPhysical,
        physicalPoints: this.msForm.physicalPoints,
        physicalOverproofPoints: this.msForm.physicalOverproofPoints,
        existsRadioactivity: this.msForm.existsRadioactivity,
        radioactivityPoints: this.msForm.radioactivityPoints,
        radioactivityOverproofPoints: this.msForm.radioactivityOverproofPoints,
        existsBioticOther: this.msForm.existsBioticOther,
        bioticOtherPoints: this.msForm.bioticOtherPoints,
        bioticOtherOverproofPoints: this.msForm.bioticOtherOverproofPoints,
      }
      data.opType = opType
      this.$system.postJson(
        this.api + '/oh/declaration/saveOrUpdateHazardDetection-1',
        data,
        true,
        true,
        data => {
          this.$parent.loading = false
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.msForm.rid = data.rid
            //点击下一步
            if (opType === 1) {
              this.$parent.step++
              this.$parent.informantStep = this.$parent.step
            } else {
              this.$parent.jsonData = JSON.stringify(this.msForm)
            }
            if (2 == opType) {
              // 暂存保存数据后，按钮解禁
              this.$parent.saveDisabled = false
            }
            if (0 == opType) {
              this.$parent.step--
            }
          } else if (data.type === '99') {
            this.$parent.saveDisabled = false
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$parent.saveDisabled = false
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        this.error
      )
    },
    /**
     * 社会信用代码校验
     */
    validateCreditCode(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        this.$validate.creditCode(rule, value, callback)
      }
      callback()
    },
  },
}
</script>

<style lang="less" scoped>
.harz {
  margin-top: 20px !important;
  border-top: 1px solid #d5d9e0;
}
.harz-list {
  .harz:first-child {
    margin-top: 0px !important;
    border-top: none !important;
  }
}

.td-text {
  padding-left: 5px;
}
a[class='td-text'] {
  color: #0045f9 !important;
  cursor: pointer;
}
.zwx-input /deep/.el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
}
.table-css {
  /deep/.zwx-table .zwx-input .el-input__inner {
    width: 120px !important;
  }
}

.flex-row {
  display: flex;
  align-items: center;
}

.flex-row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.flew-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.zwx-table .el-input__icon {
  line-height: 32px !important;
}

.hazard-detection-index {
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  .stages-css {
    color: rgba(31, 45, 61, 1);
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    background: rgba(244, 255, 244, 1);
    padding: 8px 10px;
    border: 1px solid rgba(199, 231, 195, 1);
    border-radius: 4px;
    margin-left: 12px;
    margin-right: 20px;
    cursor: pointer;
  }
  .div-table {
  }

  .div-table-row {
    display: flex;
    width: 100%;
  }

  .div-table-head-name-col {
    width: 200px;
    height: 38px;
    border-right: 1px solid #e1e5f4;
    border-bottom: 1px solid #e1e5f4;
    border-top: 1px solid #e1e5f4;
    background-color: #f6f8fb;
  }
  .div-table-head-border {
    width: 10px;
    border-top: 1px solid #e1e5f4;
    border-right: 1px solid #e1e5f4;
    background-color: #f6f8fb;
  }
  .div-table-td-border {
    width: 9px;
    height: 45px;
    border-right: 1px solid #e1e5f4;
    background-color: #f6f8fb;
  }
  .div-table-td-name-col {
    width: 250px;
    height: 45px;
    border-right: 1px solid #e1e5f4;
    border-bottom: 1px solid #e1e5f4;
    padding: 8.5px 14px;
  }
  .div-table-td-num-col {
    width: 200px;
    height: 45px;
    border-right: 1px solid #e1e5f4;
    border-bottom: 1px solid #e1e5f4;
    padding: 5px 14px;
  }
}

/deep/ .choose-dialog .el-dialog {
  width: 1046px !important;
  min-width: 1046px !important;
}
/deep/.choose-dialog .el-dialog__footer {
  border-top: unset !important;
}

/deep/.choose-dialog .el-radio.zwx-radio-course > .el-radio__input + .el-radio__label {
  display: none;
}
</style>
