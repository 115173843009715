var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "harzard-declaration-edit-page" }, [
    _c(
      "div",
      { staticClass: "edit-step" },
      [
        _vm._l(_vm.tabs, function(item, index) {
          return [
            _c(
              "div",
              {
                staticClass: "edit-step-item is-complete",
                on: {
                  click: function($event) {
                    return _vm.toStep(index + 1)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "edit-step-item-icon",
                    class:
                      _vm.step === index + 1
                        ? "is-complete-icon"
                        : _vm.step >= index + 1
                        ? "is-complete-icon-check"
                        : ""
                  },
                  [_vm._v(_vm._s(index + 1))]
                ),
                _c(
                  "span",
                  {
                    style: _vm.step >= index + 1 ? "cursor:pointer" : "",
                    on: {
                      click: function($event) {
                        return _vm.toStep(index + 1)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item))]
                ),
                index < 5
                  ? _c("div", {
                      staticClass: "edit-step-item-separator",
                      class: _vm.step > index + 1 ? "is-complete-separator" : ""
                    })
                  : _vm._e()
              ]
            )
          ]
        })
      ],
      2
    ),
    _c("div", { staticStyle: { height: "52px" } }),
    _c(
      "div",
      { staticClass: "basic-info-main-body" },
      [
        _c(
          "div",
          { attrs: { id: "node1" } },
          [
            _c("base-headline", {
              staticClass: "no-top",
              attrs: { title: "基本信息" }
            })
          ],
          1
        ),
        [_c("base-info-index", { ref: "baseInfoIndexRef" })],
        _c(
          "div",
          { attrs: { id: "node2" } },
          [_c("base-headline", { attrs: { title: "主要产品" } })],
          1
        ),
        [_c("major-product-index", { ref: "majorProductIndexRef" })],
        _c(
          "div",
          { staticStyle: { "margin-top": "12px" }, attrs: { id: "node3" } },
          [_c("base-headline", { attrs: { title: "危害因素接触情况" } })],
          1
        ),
        [
          _c("hazard-distribution-index", { ref: "hazardDistributionIndexRef" })
        ],
        _c(
          "div",
          { staticStyle: { "margin-top": "12px" }, attrs: { id: "node4" } },
          [_c("base-headline", { attrs: { title: "危害因素检测情况" } })],
          1
        ),
        [_c("hazard-detection-index", { ref: "hazardDetectionIndexRef" })],
        _c(
          "div",
          { attrs: { id: "node5" } },
          [_c("base-headline", { attrs: { title: "职业健康检查开展情况" } })],
          1
        ),
        [
          _c("health-supervision-detail-index", {
            ref: "healthSupervisionDetailIndexRef"
          })
        ],
        _c(
          "div",
          { attrs: { id: "node6" } },
          [_c("base-headline", { attrs: { title: "附件上传" } })],
          1
        ),
        [_c("health-attachment-index", { ref: "healthAttachmentIndexRef" })]
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "basic-info-main-foot" },
      [
        _c(
          "fixed-footer",
          [
            _vm.step > 1
              ? _c(
                  "el-button",
                  {
                    staticClass: "zwx-button zwx-button-32",
                    attrs: { disabled: _vm.saveDisabled },
                    on: {
                      click: function($event) {
                        return _vm.save(0, "back")
                      }
                    }
                  },
                  [_vm._v(" 上一步 ")]
                )
              : _vm._e(),
            _vm.step < 6
              ? _c(
                  "el-button",
                  {
                    staticClass: "zwx-button zwx-button-32",
                    attrs: { type: "primary", disabled: _vm.saveDisabled },
                    on: {
                      click: function($event) {
                        return _vm.save(1, "")
                      }
                    }
                  },
                  [_vm._v(" 下一步 ")]
                )
              : _vm._e(),
            _vm.step === 6
              ? _c(
                  "el-button",
                  {
                    staticClass: "zwx-button zwx-button-32",
                    attrs: { type: "primary", disabled: _vm.saveDisabled },
                    on: {
                      click: function($event) {
                        return _vm.submit(1, "提交")
                      }
                    }
                  },
                  [_vm._v(" 提交 ")]
                )
              : _vm._e(),
            _c(
              "el-button",
              {
                staticClass: "zwx-button zwx-button-32",
                attrs: { disabled: _vm.saveDisabled },
                on: {
                  click: function($event) {
                    return _vm.save(2, "")
                  }
                }
              },
              [_vm._v(" 暂存 ")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }