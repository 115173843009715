<template>
  <div class="base-info-index">
    <el-form class="zwx-form edit-form" key="msForm" ref="baseInfoIndexRef" :model="msForm" label-position="right" :rules="rules" @submit.native.prevent>
      <div class="edit-row declare-type">
        <el-form-item label-width="90px" label="申报类型：" prop="declareType">
          <el-radio-group class="zwx-radio-group" v-model="msForm.declareType" @change="declareTypeChange">
            <el-radio class="zwx-radio" :label="0" v-if="ifShow">初次申报</el-radio>
            <el-radio class="zwx-radio" :label="1" v-if="!ifShow">变更申报</el-radio>
            <el-radio class="zwx-radio" :label="2" v-if="!ifShow && ifCanDeclareType2">年度申报</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label-width="90px" label="变更原因：" v-if="!ifShow" prop="reasonCode">
          <cascader-single v-model="msForm.reasonCode" :simpleCodeList="reasonCodeList" class="codeTypeCodeCss" ref="cascader2" codeTypeCode="2003" :disabled="reasonCodeDisabled"></cascader-single>
        </el-form-item>
        <el-form-item label-width="90px" label="申报年份：" prop="declareYear">
          <el-select class="zwx-select" popper-class="zwx-select-popper" v-model="msForm.declareYear" :clearable="true" :disabled="yearDisable">
            <el-option v-for="item in declareYearList" :label="item" :value="item" :key="item"></el-option>
          </el-select>
        </el-form-item>
        <div v-if="'20030099' == msForm.reasonCode">
          <el-form-item label-width="90px" label="其他原因：" prop="otherReason">
            <el-input class="zwx-textarea " placeholder="请输入其他原因说明" :rows="2" type="textarea" style="width: 810px !important;" v-model.trim="msForm.otherReason" clearable maxlength="2000" :rules="{ required: true, message: '请输入其他原因说明', trigger: 'blur' }"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="edit-row" style="margin-top: 16px">
        <el-form-item label-width="110px" label="单位名称：" prop="unitName">
          <el-input style="width:300px !important" class="zwx-input" v-model="msForm.unitName" placeholder="请输入" clearable :disabled="$zwxBase.verifyIsNotBlank(msForm.unitName) && disabledFlag" />
        </el-form-item>
        <el-form-item label-width="170px" label="社会信用代码：" prop="creditCode">
          <el-input style="width:300px !important" class="zwx-input" v-model="msForm.creditCode" placeholder="请输入" clearable disabled />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="110px" label="单位注册地区：" prop="registerZone">
          <zwx-select-area class="zwx-basic-information-management-edit-zone" ref="registerZone" @change="nativePlaceChange1" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="true" :disabled="$zwxBase.verifyIsNotBlank(msForm.registerZone) && disabledFlag" />
        </el-form-item>
        <el-form-item label-width="170px" label="单位注册地址：" prop="registerAddr">
          <el-input style="width:300px !important" class="zwx-input" v-model="msForm.registerAddr" placeholder="请输入" clearable maxlength="400" :disabled="$zwxBase.verifyIsNotBlank(msForm.registerAddr) && disabledFlag" />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="110px" label="作业场所地区：" prop="bizZone">
          <zwx-select-area class="zwx-basic-information-management-edit-zone" ref="bizZone" @change="nativePlaceChange" :zoneCode12From="zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="true" :disabled="$zwxBase.verifyIsNotBlank(msForm.bizZone) && disabledFlag" />
        </el-form-item>
        <el-form-item label-width="170px" label="作业场所地址：" prop="workplaceAddr">
          <el-input style="width:300px !important" class="zwx-input" v-model="msForm.workplaceAddr" placeholder="请输入" clearable maxlength="400" :disabled="$zwxBase.verifyIsNotBlank(msForm.workplaceAddr) && disabledFlag" />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="110px" label="企业规模：" prop="enterpriseScale">
          <el-radio-group style="min-width:300px !important;" class="zwx-radio-group" v-model="msForm.enterpriseScale" :disabled="$zwxBase.verifyIsNotBlank(msForm.enterpriseScale) && disabledFlag">
            <el-radio class="zwx-radio" :label="1">大</el-radio>
            <el-radio class="zwx-radio" :label="2">中</el-radio>
            <el-radio class="zwx-radio" :label="3">小</el-radio>
            <el-radio class="zwx-radio" :label="4">微</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label-width="170px" label="经济类型：" prop="economicCode">
          <cascader-single class="zwx-basic-information-management-edit-zone" v-model="msForm.economicCode" ref="cascader" codeTypeCode="1015" expandTrigger="hover" :simpleCodeList="economicSimpleCodeList" @change="economicCodeChange" :disabled="$zwxBase.verifyIsNotBlank(msForm.economicCode) && disabledFlag"></cascader-single>
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="110px" label="行业分类：" prop="industryName">
          <div @click="msDeclareInfoCategoryDialog">
            <el-input class="zwx-input zwx-occ-category-input" readonly="readonly" v-model.trim="msForm.industryName" style="width: 300px !important;" placeholder="" :read-only="true" :disabled="$zwxBase.verifyIsNotBlank(msForm.industryName) && disabledFlag">
              <template slot="append">
                <span><i class="el-icon-search"></i></span>
              </template>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label-width="170px" label="填报人：" prop="informant">
          <el-input style="width:120px !important" class="zwx-input" v-model="msForm.informant" placeholder="姓名" clearable />
        </el-form-item>
        <el-form-item label="" prop="informantContact">
          <el-input style="width:160px !important;" class="zwx-input" v-model="msForm.informantContact" placeholder="联系方式" clearable />
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="110px" label="法定代表人：" prop="legalPerson">
          <el-input style="width:120px !important" class="zwx-input" v-model="msForm.legalPerson" placeholder="姓名" clearable :disabled="$zwxBase.verifyIsNotBlank(msForm.legalPerson) && disabledFlag" />
        </el-form-item>
        <el-form-item label="" prop="legalPersonPhone">
          <el-input style="width:160px !important;" class="zwx-input" v-model="msForm.legalPersonPhone" placeholder="联系方式" clearable :disabled="ifDisabled && disabledFlag" />
        </el-form-item>
        <el-form-item label-width="170px" label="职业卫生管理联系人：" prop="linkManager">
          <el-input style="width:120px !important" class="zwx-input" v-model="msForm.linkManager" placeholder="姓名" clearable />
        </el-form-item>
        <el-form-item label="" prop="linkManagerContact">
          <el-input style="width:160px !important;" class="zwx-input" v-model="msForm.linkManagerContact" placeholder="联系方式" clearable />
        </el-form-item>
      </div>
      <div class="edit-row info-box">
        <el-form-item label-width="160px" label="本单位在册职工总数：" prop="totalStaffNum">
          <el-input class="zwx-input" style="width: 80px !important;" v-model.trim="msForm.totalStaffNum" placeholder="请输入" clearable @input="$validate.positiveInteger($data['msForm'], 'totalStaffNum')"></el-input>
        </el-form-item>
        <el-form-item label-width="120px" label="外委人员总数：" prop="totalSubcontractingStaff">
          <el-input class="zwx-input" style="width: 80px !important;" v-model.trim="msForm.totalSubcontractingStaff" placeholder="请输入" clearable @input="$validate.nonnegativeInteger($data['msForm'], 'totalSubcontractingStaff')"></el-input>
        </el-form-item>
        <el-form-item label-width="140px" label="在册接害人数：" prop="workerNum">
          <!-- {{workNum}} -->
          <el-input class="zwx-input" style="width: 80px !important;" v-model.trim="msForm.workerNum" placeholder="请输入" clearable @input="$validate.nonnegativeInteger($data['msForm'], 'workerNum')"></el-input>
        </el-form-item>
        <el-form-item label-width="150px" label="外委接害人数：" prop="outsourcingNum">
          <el-input class="zwx-input" style="width: 80px !important;" v-model.trim="msForm.outsourcingNum" placeholder="请输入" clearable @input="$validate.nonnegativeInteger($data['msForm'], 'outsourcingNum')"></el-input>
        </el-form-item>
      </div>
      <div class="edit-row">
        <el-form-item label-width="140px" label="职业病累计人数：" prop="cumulativePatients">
          <el-input class="zwx-input" style="width: 80px !important;" v-model.trim="msForm.cumulativePatients" placeholder="请输入" clearable @input="$validate.nonnegativeInteger($data['msForm'], 'cumulativePatients')"></el-input>
        </el-form-item>

        <el-form-item label-width="150px" label="接害总人数(含外委)：">
          <span>{{ totalVictimsNum }}</span>
          <span style="padding-left:10px;color:orange">
            提示：该人数为在册接害人数与外委接害人数之和，自动统计无需填写
            <!-- <el-tooltip class="item" effect="light" popper-class="contact-tooltip" content="该人数为在册接害人数与外委接害人数之和，自动统计无需填写" placement="top-start"> -->
            <!-- <i class="el-icon-info"></i> -->
            <!-- </el-tooltip> -->
          </span>
        </el-form-item>
      </div>
      <div class="edit-row info-box">
        <el-form-item label-width="130px" label="主要负责人培训：" prop="principalTraining">
          <el-radio-group style="min-width:130px !important;" class="zwx-radio-group" v-model="msForm.principalTraining">
            <el-radio class="zwx-radio" :label="true">已培训</el-radio>
            <el-radio class="zwx-radio" :label="false">未培训</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label-width="170px" label="职业卫生管理人员培训：" prop="managerTraining">
          <el-radio-group style="min-width:130px !important;" class="zwx-radio-group" v-model="msForm.managerTraining">
            <el-radio class="zwx-radio" :label="true">已培训</el-radio>
            <el-radio class="zwx-radio" :label="false">未培训</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label-width="260px" label="接触职业病危害因素年度培训总人数：" prop="totalTrainee">
          <el-input class="zwx-input" style="width: 80px !important;" v-model.trim="msForm.totalTrainee" placeholder="请输入" clearable @input="$validate.nonnegativeInteger($data['msForm'], 'totalTrainee')"></el-input>
        </el-form-item>
      </div>
      <div class="edit-row" style="display: flex;">
        <div style="width: 270px;" v-if="msForm.principalTraining">
          <el-form-item
            label-width="160px"
            :label="msForm.principalTraining ? '主要负责人培训证明：' : ''"
            prop="principalTrainingPath"
            :rules="[
              {
                required: msForm.declareType == '2' && msForm.principalTraining,
                validator: (rule, value, callback) => {
                  validateTrainingPath(rule, value, callback)
                },
                trigger: ['change', 'blur'],
              },
            ]"
          >
            <file-upload v-show="msForm.principalTrainingPathList.length == 0" ref="principalTrainingPathList" @showFileList="showPrincipalTrainingPathList" accept=".png,.PNG,.jpg,.JPG,.JPEG,.jpeg,.pdf,.PDF" :limit="1" @success="principalTrainingPathSuccess" @error="fileUploadError" :size="50 * 1024 * 1024" sizeInfo="50M">
              <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2" v-if="msForm.principalTraining">
                上传
              </el-button>
            </file-upload>
            <div style="display:inline-block;" v-if="$zwxBase.verifyIsNotBlank(msForm.principalTrainingPathList) && msForm.principalTraining">
              <span style="margin-right:15px;color:#0045F9;cursor: pointer;" @click="openTrainingFilePreview(msForm.principalTrainingPathList, '主要负责人培训证明')">查看</span>
              <span style="color:red; cursor: pointer;" @click="delAnnexFile(false)">删除</span>
            </div>
          </el-form-item>
        </div>
        <!-- <div>
          <el-form-item
            label-width="210px"
            :label="msForm.managerTraining ? '职业卫生管理人员培训证明：' : ''"
            prop="managerTrainingPath"
            :rules="[
              {
                required: msForm.declareType == '2' && msForm.managerTraining,
                validator: (rule, value, callback) => {
                  validateTrainingPath(rule, value, callback)
                },
                trigger: ['change', 'blur'],
              },
            ]"
          >
            <file-upload v-show="msForm.managerTrainingPathList.length == 0" ref="managerTrainingPathList" @showFileList="showManagerTrainingPathList" accept=".png,.PNG,.jpg,.JPG,.JPEG,.jpeg,.pdf,.PDF" :limit="1" @success="managerTrainingPathSuccess" @error="fileUploadError" :size="50 * 1024 * 1024" sizeInfo="50M">
              <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2" v-if="msForm.managerTraining">
                上传
              </el-button>
            </file-upload>
            <div v-if="$zwxBase.verifyIsNotBlank(msForm.managerTrainingPathList) && msForm.managerTraining" style="display:inline-block;">
              <span style="margin-right:15px;color:#0045F9;cursor: pointer;" @click="openTrainingFilePreview(msForm.managerTrainingPathList, '职业卫生管理人员培训证明')">查看</span>
              <span style="color:red; cursor: pointer;" @click="delAnnexFile(true)">删除</span>
            </div>
          </el-form-item>
        </div> -->
        <div v-if="msForm.managerTraining">
          <attachmen-upload folder="/employer/basic" v-model="fileList" label="职业卫生管理人员培训证明：" labelwidth="200px" :limit="5" fileProp="managerTrainingPath" />
        </div>
      </div>
    </el-form>
    <!--行业分类弹窗-->
    <industry-select ref="msDeclareComponentCategory" :ifValidateIfLeaf="true" :interfaceName="'/oh/repository'" @change="msDeclareComponentCategoryChange" />

    <base-dialog class="year-declaration-dialog" ref="yearDeclarationDialog" title="知情同意书" :ifShowClose="false" :ifDetermine="false" :ifCancel="false">
      <div class="edit-row">
        职业病危害项目申报“年度更新”，仅适用于年度检验检测、职业健康检查工作已依法开展并取得正式报告后方可申报。
      </div>
      <div slot="footer">
        <div style="text-align: center">
          <el-button class="zwx-button zwx-button-32" :disabled="timeForm.informedConsentDisabled" @click="closeYearDeclarationDialog()">{{ timeForm.informedConsentButtonTitle }}</el-button>
        </div>
      </div>
    </base-dialog>
  </div>
</template>

<script>
export default {
  name: 'BaseInfoIndex',
  data() {
    return {
      api: this.$store.state.api,
      view: true,
      routeType: this.$route.params.type, //1：新增；2：编辑
      //地区
      zoneCode12From: undefined,
      employerUuid: '', //单位id
      ifShow: false, // 是否显示初次申报
      ifCanDeclareType2: false, // 是否已经年度申报
      reasonCodeDisabled: false, //变更原因是否禁用
      msForm: {
        rid: '',
        fkByEmployerId: {
          rid: '',
        },
        employerUuid: '',
        declareType: '',
        reasonCode: '',
        otherReason: '',
        declareYear: '',
        unitName: '',
        creditCode: '',
        registerZone: '',
        registerAddr: '',
        bizZone: '',
        workplaceAddr: '',
        enterpriseScale: '',
        economicCode: '',
        industryCode: '',
        industryName: '',
        industryFullCode: '',
        riskClassification: '',
        informant: '',
        informantContact: '',
        legalPerson: '',
        legalPersonPhone: '',
        linkManager: '',
        linkManagerContact: '',
        totalStaffNum: '',
        totalSubcontractingStaff: '',
        totalVictimsNum: '',
        outsourcingNum: '',
        cumulativePatients: '',
        workerNum: '',
        principalTraining: '',
        managerTraining: '',
        totalTrainee: '',
        totalTraineeRate: '',
        informantStep: 1,
        informantDate: '',
        declareStatus: '', //申报状态0:暂存   1:待申报 2:完成申报
        registerZoneType: '',
        zoneType: '',
        principalTrainingPath: '',
        managerTrainingPath: '',
        principalTrainingPathList: [],
        managerTrainingPathList: [],
      },
      economicSimpleCodeList: [],
      reasonCodeList: [],
      rules: {
        declareType: [{ required: true, message: '请选择', trigger: ['change'] }],
        reasonCode: [{ required: false, message: '请选择', trigger: ['change', 'blur'] }],
        otherReason: [{ required: true, message: '请输入', trigger: ['change', 'blur'] }],
        declareYear: [{ required: true, message: '请选择', trigger: ['change'] }],
        unitName: [{ required: true, message: '请输入', trigger: ['change', 'blur'] }],
        registerZone: [
          { required: true, message: '请选择', trigger: ['change'] },
          { validator: this.validateRegisterZone, trigger: ['blur', 'change'] },
        ],
        registerAddr: [{ required: true, message: '请输入', trigger: ['change', 'blur'] }],
        bizZone: [
          { required: true, message: '请选择', trigger: ['change'] },
          { validator: this.validateBizZone, trigger: ['blur', 'change'] },
        ],
        workplaceAddr: [{ required: true, message: '请输入', trigger: ['change', 'blur'] }],
        enterpriseScale: [{ required: true, message: '请选择', trigger: ['change'] }],
        economicCode: [{ required: true, message: '请选择', trigger: ['change', 'blur'] }],
        industryName: [{ required: true, message: '请选择', trigger: ['change', 'blur'] }],
        informant: [{ required: true, message: '请输入', trigger: ['change', 'blur'] }],
        informantContact: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validatorPhone, trigger: 'blur' },
        ],
        legalPerson: [{ required: true, message: '请输入', trigger: ['change', 'blur'] }],
        legalPersonPhone: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validatorPhoneAndMobile, trigger: 'blur' },
        ],
        linkManager: [{ required: true, message: '请输入', trigger: ['change', 'blur'] }],
        linkManagerContact: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validatorPhone, trigger: 'blur' },
        ],
        totalStaffNum: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validatorTotalStaffNum, trigger: 'blur' },
        ],
        totalSubcontractingStaff: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validatorTotalSubcontractingStaff, trigger: 'blur' },
        ],
        totalVictimsNum: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validatorTotalVictimsNum, trigger: 'blur' },
        ],
        outsourcingNum: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validatorOutsourcingNum, trigger: 'blur' },
        ],
        cumulativePatients: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validatorCumulativePatients, trigger: 'blur' },
        ],
        workerNum: [{ required: true, message: '请输入', trigger: ['change', 'blur'] }],
        principalTraining: [{ required: true, message: '请选择', trigger: ['change'] }],
        managerTraining: [{ required: true, message: '请选择', trigger: ['change'] }],
        totalTrainee: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validatorTotalTrainee, trigger: 'blur' },
        ],
      },
      newDeclareYear: '', //最新申报年份
      declareYearList: [], //年份
      yearDisable: false, //年份是否可选
      disabledFlag: false,
      originalData: {}, //原始数据
      timeForm: {
        informedConsentDisabled: false,
        informedConsentButtonTitle: '确 定',
        count: '',
        timer: null,
      },
      ifDisabled: false,
      bizLength: 0,
      fileList: [],
    }
  },
  created() {
    this.zoneCode12From = '000000000000'
  },
  mounted() {
    this.employerUuid = this.$route.params.employerUuid
    this.msForm.rid = this.$route.params.rid
    this.$SimpleCodeTools.getSimpleCodeListByCodeTypeCodes('1015,2003', data => {
      this.economicSimpleCodeList = data['1015']
      this.reasonCodeList = data['2003']
      if (this.msForm.rid) {
        // this.getHazardDeclarationBasicByID()
      } else {
        this.msForm.declareYear = new Date().getFullYear().toString()
        this.declareYearList.push(new Date().getFullYear().toString())
        // this.loadHazardDeclarationBasic()
      }
    })
  },
  activated() {},
  computed: {
    totalVictimsNum() {
      let num = (Number.parseInt(this.msForm.workerNum) || 0) + (Number.parseInt(this.msForm.outsourcingNum) || 0)
      this.msForm.totalVictimsNum = num
      return num
    },
  },
  methods: {
    /**
     * 地区校验
     */
    validateBizZone(rule, value, callback) {
      let { length } = this.$refs.bizZone.areaShowList
      if (length > 0) {
        this.bizLength = length
        callback()
        return
      }
      if (this.msForm.zoneType < this.bizLength - 1) {
        callback(new Error('请精确到最下级'))
      }
      callback()
    },
    /**
     * 地区校验
     */
    validateRegisterZone(rule, value, callback) {
      if (this.msForm.registerZoneType < 3) {
        callback(new Error('请精确到区级、街道级'))
      }
      callback()
    },
    /**
     * 电话校验
     */
    validatorPhoneAndMobile(rule, value, callback) {
      //固定电话
      let pattern = /^(([0\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/
      if (!/^[1][3-9]\d{9}$/.test(value) && !pattern.test(value)) {
        callback(new Error('联系方式格式错误'))
      } else {
        callback()
      }
    },
    /**
     * 电话校验
     */
    validatorPhone(rule, value, callback) {
      if (!/^[1][3-9]\d{9}$/.test(value)) {
        callback(new Error('联系方式格式错误'))
      } else {
        callback()
      }
    },
    /**
     * 总人数校验
     */
    validatorTotalStaffNum(rule, value, callback) {
      callback()
    },
    /**
     * 外委人数校验
     */
    validatorTotalSubcontractingStaff(rule, value, callback) {
      callback()
    },
    /**
     * 接害总人数校验
     */
    validatorTotalVictimsNum(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value) && this.$zwxBase.verifyIsNotBlank(this.msForm.totalStaffNum) && this.$zwxBase.verifyIsNotBlank(this.msForm.totalSubcontractingStaff) && parseInt(value) > parseInt(this.msForm.totalStaffNum) + parseInt(this.msForm.totalSubcontractingStaff)) {
        callback(new Error('小于等于在册职工总数与外委人员总数和'))
      }
      // if (this.$zwxBase.verifyIsNotBlank(value) && this.$zwxBase.verifyIsNotBlank(this.msForm.totalTrainee)) {
      //   if(parseInt(value) >= parseInt(this.msForm.totalTrainee)){
      //     let a = parseFloat(this.msForm.totalTrainee)/parseFloat(value)*100
      //     let ab = parseFloat(a.toFixed(2));
      //     this.msForm.totalTraineeRate = ab + '%'
      //   }
      // }
      callback()
    },

    validatorOutsourcingNum(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (this.$zwxBase.verifyIsNotBlank(this.msForm.totalVictimsNum) && parseInt(value) > parseInt(this.msForm.totalVictimsNum)) {
          callback(new Error('小于等于接害总人数(含外委)'))
        }
        if (this.$zwxBase.verifyIsNotBlank(this.msForm.totalSubcontractingStaff) && parseInt(value) > parseInt(this.msForm.totalSubcontractingStaff)) {
          callback(new Error('小于等于外委人员总数'))
        }
      }
      callback()
    },

    validatorCumulativePatients(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value) && this.$zwxBase.verifyIsNotBlank(this.msForm.totalStaffNum) && this.$zwxBase.verifyIsNotBlank(this.msForm.totalSubcontractingStaff) && parseInt(value) > parseInt(this.msForm.totalStaffNum) + parseInt(this.msForm.totalSubcontractingStaff)) {
        callback(new Error('小于等于在册职工总数与外委人员总数和'))
      }
      callback()
    },

    /**
     * 接触职业病危害因素年度培训总人数校验
     */
    validatorTotalTrainee(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value) && this.$zwxBase.verifyIsNotBlank(this.msForm.totalStaffNum) && this.$zwxBase.verifyIsNotBlank(this.msForm.totalSubcontractingStaff) && parseInt(value) > parseInt(this.msForm.totalStaffNum) + parseInt(this.msForm.totalSubcontractingStaff)) {
        callback(new Error('小于等于在册职工总数与外委人员总数和'))
      }
      if (this.$zwxBase.verifyIsNotBlank(value) && this.$zwxBase.verifyIsNotBlank(this.msForm.totalVictimsNum)) {
        if (parseInt(value) > parseInt(this.msForm.totalVictimsNum)) {
          callback(new Error('小于等于接害总人数(含外委)'))
        } else {
          // let a = parseFloat(value)/parseFloat(this.msForm.totalVictimsNum)*100
          // let ab = parseFloat(a.toFixed(2));
          // this.msForm.totalTraineeRate = ab + '%'
        }
      }
      callback()
    },
    /**
     * 申报类型切换
     */
    declareTypeChange(val) {
      this.disabledFlag = false
      this.msForm.declareYear = new Date().getFullYear().toString()
      let years = []
      if (0 == val || 2 == val) {
        this.rules.reasonCode[0].required = false
        this.reasonCodeDisabled = true
        this.yearDisable = false
        this.msForm.reasonCode = ''
        if (this.$zwxBase.verifyIsBlank(this.newDeclareYear) || (this.$zwxBase.verifyIsNotBlank(this.newDeclareYear) && this.newDeclareYear <= (new Date().getFullYear() - 1).toString())) {
          years.push((new Date().getFullYear() - 1).toString())
        }
        years.push(new Date().getFullYear().toString())
        // 非首次申报，年度申报基本信息不可以修改
        if (2 == val) {
          if (this.$zwxBase.verifyIsNotBlank(this.newDeclareYear)) {
            this.disabledFlag = true
            let originalData = typeof this.originalData === 'object' ? this.msForm : JSON.parse(this.originalData)
            // let originalData = JSON.parse(this.originalData)
            // 基本单位信息还原
            this.msForm.unitName = originalData.unitName
            this.msForm.creditCode = originalData.creditCode
            this.msForm.registerZonwe = originalData.registerZone
            this.msForm.registerAddr = originalData.registerAddr
            this.msForm.bizZone = originalData.bizZone
            this.msForm.workplaceAddr = originalData.workplaceAddr
            this.msForm.enterpriseScale = originalData.enterpriseScale
            this.msForm.economicCode = originalData.economicCode
            this.msForm.industryCode = originalData.industryCode
            this.msForm.industryName = originalData.industryName
            // 职业病危害风险分类
            this.msForm.riskClassification = originalData.riskClassification
            this.msForm.legalPerson = originalData.legalPerson
            this.msForm.legalPersonPhone = this.$zwxSm.sm4JsDecrypt(originalData.legalPersonPhone)
            this.$refs.bizZone.initSearch(this.msForm.bizZone)
            this.$refs.registerZone.initSearch(this.msForm.registerZone)
          }
          //弹出知情同意书
          const that = this
          that.$refs.yearDeclarationDialog.show(true)
          that.timeForm.informedConsentDisabled = true
          var TIME_COUNT = 5
          that.timeForm.count = 5
          that.timeForm.timer = setInterval(function() {
            if (that.timeForm.count > 1 && that.timeForm.count <= TIME_COUNT) {
              that.timeForm.count--
              that.timeForm.informedConsentButtonTitle = '确 定（' + that.timeForm.count + 's）'
            } else {
              // 清除定时器
              clearInterval(that.timeForm.timer)
              that.timeForm.timer = null
              that.timeForm.informedConsentDisabled = false
              that.timeForm.informedConsentButtonTitle = '确 定'
            }
          }, 1000)
        }
      } else if (1 == val) {
        this.rules.reasonCode[0].required = true
        this.reasonCodeDisabled = false
        this.yearDisable = true
        years.push(new Date().getFullYear().toString())
      }
      this.declareYearList = years
    },
    closeYearDeclarationDialog() {
      this.$refs.yearDeclarationDialog.show(false)
    },
    /**
     * 行业分类弹框
     */
    msDeclareInfoCategoryDialog() {
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.industryName) && this.disabledFlag) {
        return
      }
      this.$refs.msDeclareComponentCategory.show(true)
    },
    msDeclareComponentCategoryChange() {
      this.msForm.riskClassification = this.$refs.msDeclareComponentCategory.msCategoryGet().riskClassification
      this.msForm.industryCode = this.$refs.msDeclareComponentCategory.msCategoryGet().codeNo
      this.msForm.industryFullCode = this.$refs.msDeclareComponentCategory.msCategoryGet().levelCodeNo
      this.$set(this.msForm, 'industryName', this.$refs.msDeclareComponentCategory.msCategoryGet().industryName)
    },
    economicCodeChange(value) {
      let economicArr = this.economicSimpleCodeList.filter(item => item.codeNo == value)
      if (this.$zwxBase.verifyIsNotBlank(economicArr)) {
        this.msForm.economicFullCode = economicArr[0].codeLevelNo
        this.msForm.economicName = economicArr[0].codeName
      }
    },
    /**
     * 获取基本信息
     */
    loadHazardDeclarationBasic() {
      // this.$parent.loading = true
      let data = {
        employerUuid: this.employerUuid,
      }
      this.$system.get(this.api + '/oh/declaration/loadHazardDeclarationBasic-1', data, true, true, this.loadHazardDeclarationBasicSuccess, data => {
        this.$parent.loading = false
        this.$system.notify('错误', data.mess, 'error')
      })
    },
    loadHazardDeclarationBasicSuccess(data) {
      if (data.type === '00') {
        // 基本单位信息
        this.msForm.unitName = data.tdOccupationalHazardDeclarationExp.unitName
        this.msForm.employerUuid = data.tdOccupationalHazardDeclarationExp.employerUuid
        this.msForm.creditCode = data.tdOccupationalHazardDeclarationExp.creditCode
        this.msForm.registerZone = data.tdOccupationalHazardDeclarationExp.registerZone
        this.msForm.registerAddr = data.tdOccupationalHazardDeclarationExp.registerAddr
        this.msForm.bizZone = data.tdOccupationalHazardDeclarationExp.bizZone
        this.msForm.workplaceAddr = data.tdOccupationalHazardDeclarationExp.workplaceAddr
        this.msForm.enterpriseScale = data.tdOccupationalHazardDeclarationExp.enterpriseScale
        this.msForm.economicCode = data.tdOccupationalHazardDeclarationExp.economicCode
        this.msForm.economicName = data.tdOccupationalHazardDeclarationExp.economicName
        this.msForm.economicFullCode = data.tdOccupationalHazardDeclarationExp.economicFullCode
        this.msForm.industryCode = data.tdOccupationalHazardDeclarationExp.industryCode
        this.msForm.industryName = data.tdOccupationalHazardDeclarationExp.industryName
        this.msForm.industryFullCode = data.tdOccupationalHazardDeclarationExp.industryFullCode
        // 职业病危害风险分类
        this.msForm.riskClassification = data.tdOccupationalHazardDeclarationExp.riskClassification
        this.msForm.legalPerson = data.tdOccupationalHazardDeclarationExp.legalPerson
        if (data.tdOccupationalHazardDeclarationExp.legalPersonPhone) {
          this.ifDisabled = true
        }
        this.msForm.legalPersonPhone = this.$zwxSm.sm4JsDecrypt(data.tdOccupationalHazardDeclarationExp.legalPersonPhone)
        this.$refs.bizZone.initSearch(this.msForm.bizZone)
        this.$refs.registerZone.initSearch(this.msForm.registerZone)
        this.msForm.totalStaffNum = data.tdOccupationalHazardDeclarationExp.totalStaffNum
        this.msForm.totalSubcontractingStaff = data.tdOccupationalHazardDeclarationExp.totalSubcontractingStaff
        this.msForm.totalVictimsNum = data.tdOccupationalHazardDeclarationExp.totalVictimsNum
        this.msForm.outsourcingNum = data.tdOccupationalHazardDeclarationExp.outsourcingNum
        this.msForm.cumulativePatients = data.tdOccupationalHazardDeclarationExp.cumulativePatients
        this.msForm.workerNum = data.tdOccupationalHazardDeclarationExp.workerNum
        this.msForm.principalTraining = data.tdOccupationalHazardDeclarationExp.principalTraining
        this.msForm.managerTraining = data.tdOccupationalHazardDeclarationExp.managerTraining
        this.msForm.totalTrainee = data.tdOccupationalHazardDeclarationExp.totalTrainee
        this.msForm.informant = data.tdOccupationalHazardDeclarationExp.informant
        this.msForm.informantContact = this.$zwxSm.sm4JsDecrypt(data.tdOccupationalHazardDeclarationExp.informantContact)
        this.msForm.linkManager = data.tdOccupationalHazardDeclarationExp.linkManager
        this.msForm.linkManagerContact = this.$zwxSm.sm4JsDecrypt(data.tdOccupationalHazardDeclarationExp.linkManagerContact)
        if (this.$zwxBase.verifyIsNotBlank(data.tdOccupationalHazardDeclarationExp.principalTrainingPath)) {
          this.msForm.principalTrainingPath = data.tdOccupationalHazardDeclarationExp.principalTrainingPath
          let principalTrainingPath = new Object()
          principalTrainingPath.filePath = data.tdOccupationalHazardDeclarationExp.principalTrainingPath
          this.msForm.principalTrainingPathList.push(principalTrainingPath)
        }
        if (this.$zwxBase.verifyIsNotBlank(data.tdOccupationalHazardDeclarationExp.managerTrainingPath)) {
          this.msForm.managerTrainingPath = data.tdOccupationalHazardDeclarationExp.managerTrainingPath
          let managerTrainingPath = new Object()
          managerTrainingPath.filePath = data.tdOccupationalHazardDeclarationExp.managerTrainingPath
          this.msForm.managerTrainingPathList.push(managerTrainingPath)
        }
        this.newDeclareYear = data.tdOccupationalHazardDeclarationExp.newDeclareYear
        this.rules.reasonCode[0].required = false
        this.ifCanDeclareType2 = data.tdOccupationalHazardDeclarationExp.ifCanDeclareType2
        if (this.$zwxBase.verifyIsBlank(this.newDeclareYear)) {
          this.$parent.firstAdd = true
          this.msForm.declareType = 0
          this.reasonCodeDisabled = true
          this.ifShow = true
          this.declareYearList.push((new Date().getFullYear() - 1).toString())
        } else {
          this.ifShow = false
          this.msForm.declareType = 2
          this.yearDisable = true
          this.$parent.firstAdd = false
          this.declareTypeChange(2)
          this.rules.reasonCode[0].required = false
        }
        this.$parent.jsonData = JSON.stringify(this.msForm)
        this.$parent.saveDisabled = false
        this.originalData = JSON.stringify(this.msForm)
        this.$nextTick(() => {
          this.$refs.baseInfoIndexRef.clearValidate()
        })
      } else if (data.type === '99') {
        this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
      } else {
        this.$notify.error({ title: '错误', message: data.mess })
      }
    },
    /**
     * 编辑获取基本信息
     */
    getHazardDeclarationBasicByID() {
      this.$parent.loading = true
      let data = {
        rid: this.msForm.rid,
        employerUuid: this.employerUuid,
      }
      this.$system.get(
        this.api + '/oh/declaration/getHazardDeclarationBasicByID-1',
        data,
        true,
        true,
        data => {
          this.$parent.loading = false
          if (data.type === '00') {
            this.msForm.declareType = data.tdOccupationalHazardDeclarationExp.declareType
            this.msForm.reasonCode = data.tdOccupationalHazardDeclarationExp.reasonCode
            this.msForm.otherReason = data.tdOccupationalHazardDeclarationExp.otherReason
            this.msForm.declareYear = data.tdOccupationalHazardDeclarationExp.declareYear
            // 基本单位信息
            this.msForm.fkByEmployerId = data.tdOccupationalHazardDeclarationExp.fkByEmployerId
            this.msForm.employerUuid = data.tdOccupationalHazardDeclarationExp.employerUuid
            this.msForm.unitName = data.tdOccupationalHazardDeclarationExp.unitName
            this.msForm.creditCode = data.tdOccupationalHazardDeclarationExp.creditCode
            this.msForm.registerZone = data.tdOccupationalHazardDeclarationExp.registerZone
            this.msForm.registerAddr = data.tdOccupationalHazardDeclarationExp.registerAddr
            this.msForm.bizZone = data.tdOccupationalHazardDeclarationExp.bizZone
            this.msForm.workplaceAddr = data.tdOccupationalHazardDeclarationExp.workplaceAddr
            this.msForm.enterpriseScale = data.tdOccupationalHazardDeclarationExp.enterpriseScale
            this.msForm.economicCode = data.tdOccupationalHazardDeclarationExp.economicCode
            this.msForm.economicName = data.tdOccupationalHazardDeclarationExp.economicName
            this.msForm.economicFullCode = data.tdOccupationalHazardDeclarationExp.economicFullCode
            this.msForm.industryCode = data.tdOccupationalHazardDeclarationExp.industryCode
            this.msForm.industryName = data.tdOccupationalHazardDeclarationExp.industryName
            this.msForm.industryFullCode = data.tdOccupationalHazardDeclarationExp.industryFullCode
            // 职业病危害风险分类
            this.msForm.riskClassification = data.tdOccupationalHazardDeclarationExp.riskClassification
            this.msForm.informant = data.tdOccupationalHazardDeclarationExp.informant
            this.msForm.informantContact = this.$zwxSm.sm4JsDecrypt(data.tdOccupationalHazardDeclarationExp.informantContact)
            this.msForm.legalPerson = data.tdOccupationalHazardDeclarationExp.legalPerson
            this.msForm.legalPersonPhone = this.$zwxSm.sm4JsDecrypt(data.tdOccupationalHazardDeclarationExp.legalPersonPhone)
            this.msForm.linkManager = data.tdOccupationalHazardDeclarationExp.linkManager
            this.msForm.linkManagerContact = this.$zwxSm.sm4JsDecrypt(data.tdOccupationalHazardDeclarationExp.linkManagerContact)
            this.$refs.bizZone && this.$refs.bizZone.initSearch(this.msForm.bizZone)
            this.$refs.registerZone && this.$refs.registerZone.initSearch(this.msForm.registerZone)
            this.msForm.totalStaffNum = data.tdOccupationalHazardDeclarationExp.totalStaffNum
            this.msForm.totalSubcontractingStaff = data.tdOccupationalHazardDeclarationExp.totalSubcontractingStaff
            this.msForm.totalVictimsNum = data.tdOccupationalHazardDeclarationExp.totalVictimsNum
            this.msForm.outsourcingNum = data.tdOccupationalHazardDeclarationExp.outsourcingNum
            this.msForm.cumulativePatients = data.tdOccupationalHazardDeclarationExp.cumulativePatients
            this.msForm.workerNum = data.tdOccupationalHazardDeclarationExp.workerNum
            this.msForm.principalTraining = data.tdOccupationalHazardDeclarationExp.principalTraining
            this.msForm.managerTraining = data.tdOccupationalHazardDeclarationExp.managerTraining
            this.msForm.totalTrainee = data.tdOccupationalHazardDeclarationExp.totalTrainee
            this.msForm.totalTraineeRate = data.tdOccupationalHazardDeclarationExp.totalTraineeRate
            this.ifCanDeclareType2 = data.tdOccupationalHazardDeclarationExp.ifCanDeclareType2
            if (this.$zwxBase.verifyIsNotBlank(data.tdOccupationalHazardDeclarationExp.principalTrainingPath)) {
              this.msForm.principalTrainingPath = data.tdOccupationalHazardDeclarationExp.principalTrainingPath
              let principalTrainingPath = new Object()
              principalTrainingPath.filePath = data.tdOccupationalHazardDeclarationExp.principalTrainingPath
              this.msForm.principalTrainingPathList.push(principalTrainingPath)
            }
            if (this.$zwxBase.verifyIsNotBlank(data.tdOccupationalHazardDeclarationExp.managerTrainingPath)) {
              this.fileList = data.tdOccupationalHazardDeclarationExp.managerTrainingPath.split(',').map(item => {
                return {
                  fileName: this.getFilenameFromPath(item),
                  filePath: item,
                }
              })
            }
            this.newDeclareYear = data.tdOccupationalHazardDeclarationExp.newDeclareYear
            if (this.$zwxBase.verifyIsBlank(this.newDeclareYear)) {
              this.ifShow = true
              this.$parent.firstAdd = true
            } else {
              this.ifShow = false
              this.$parent.firstAdd = false
            }
            this.disabledFlag = false
            let years = []
            if (0 == this.msForm.declareType || 2 == this.msForm.declareType) {
              this.rules.reasonCode[0].required = false
              this.reasonCodeDisabled = true
              this.yearDisable = false
              this.msForm.reasonCode = ''
              if (this.$zwxBase.verifyIsBlank(this.newDeclareYear) || (this.$zwxBase.verifyIsNotBlank(this.newDeclareYear) && this.newDeclareYear <= (new Date().getFullYear() - 1).toString())) {
                years.push((new Date().getFullYear() - 1).toString())
              }
              years.push(new Date().getFullYear().toString())
              if (2 == this.msForm.declareType && this.$zwxBase.verifyIsNotBlank(this.newDeclareYear)) {
                this.disabledFlag = true
              }
            } else if (1 == this.msForm.declareType) {
              this.rules.reasonCode[0].required = true
              this.reasonCodeDisabled = false
              this.yearDisable = true
              years.push(new Date().getFullYear().toString())
            }
            this.declareYearList = years
            this.$parent.jsonData = JSON.stringify(this.msForm)
            this.$parent.saveDisabled = false
            this.originalData = JSON.stringify(this.msForm)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$parent.loading = false
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    getFilenameFromPath(path) {
      return path
        .split('/')
        .pop()
        .split('\\')
        .pop()
    },
    /**
     * 保存更新基本信息
     */
    saveOrUpdateHazardDeclarationBasic(opType) {
      if (0 == opType || 2 == opType) {
        //上一步和暂存不校验
        this.saveBaseInfo(opType)
      } else {
        // 校验
        this.$refs.baseInfoIndexRef.validate(valid => {
          if (valid) {
            this.$parent.saveDisabled = true
            this.saveBaseInfo(opType)
          }
        })
      }
    },
    saveBaseInfo(opType) {
      this.$parent.loading = true
      let data = {
        ...this.msForm,
      }
      if (!this.msForm.principalTraining) {
        delete data.principalTrainingPath
      }
      if (this.msForm.managerTraining) {
        data.managerTrainingPath = this.fileList.map(item => item.filePath).join(',')
      } else {
        delete data.managerTrainingPath
      }
      data.informantDate = new Date()
      data.opType = opType
      data.bizZone = this.$refs.bizZone.getChooseArea() == null ? null : this.$refs.bizZone.getChooseArea().zoneCode12
      data.registerZone = this.$refs.registerZone.getChooseArea() == null ? null : this.$refs.registerZone.getChooseArea().zoneCode12
      this.$system.postJson(
        this.api + '/oh/declaration/saveOrUpdateHazardDeclarationBasic-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.msForm.rid = data.rid
            this.$route.params.rid = data.rid
            //点击下一步
            if (opType === 1) {
              this.$parent.step++
              this.$parent.informantStep = this.$parent.step
              this.$parent.saveDisabled = false
            } else {
              this.$parent.jsonData = JSON.stringify(this.msForm)
            }
            if (2 == opType) {
              // 暂存保存数据后，按钮解禁
              this.$parent.saveDisabled = false
            }
            if (0 == opType) {
              this.$parent.step--
            }
          } else if (data.type === '99') {
            this.$parent.saveDisabled = false
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$parent.saveDisabled = false
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$parent.loading = false
        },
        this.error
      )
    },
    //经营地区
    nativePlaceChange() {
      this.msForm.zoneType = this.$refs.bizZone.getChooseArea().zoneType
      this.msForm.bizZone = this.$refs.bizZone.getChooseArea() == null ? null : this.$refs.bizZone.getChooseArea().zoneCode12
      this.$parent.jsonData = JSON.stringify(this.msForm)
    },
    //作业地区
    nativePlaceChange1() {
      this.msForm.registerZoneType = this.$refs.registerZone.getChooseArea().zoneType
      this.msForm.registerZone = this.$refs.registerZone.getChooseArea() == null ? null : this.$refs.registerZone.getChooseArea().zoneCode12
      this.$parent.jsonData = JSON.stringify(this.msForm)
    },
    /**
     * 文件预览
     */
    openTrainingFilePreview(fileList, itemName) {
      fileList.forEach(item => {
        item.fileName = itemName
      })
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: 0,
          },
        },
        '*'
      )
    },
    showPrincipalTrainingPathList(showList) {
      this.showAnnexList(false, showList)
    },
    showManagerTrainingPathList(showList) {
      this.showAnnexList(true, showList)
    },
    showAnnexList(type, showList) {
      let list = type ? this.msForm.managerTrainingPathList : this.msForm.principalTrainingPathList
      this.msForm[type ? 'managerTrainingPathList' : 'principalTrainingPathList'] = []
      list = this.msForm[type ? 'managerTrainingPathList' : 'principalTrainingPathList']
      showList.forEach(item => {
        let flag = list.some(e => e.fileName == item)
        if (flag) {
          return
        }
        list.push({
          fileName: item,
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUid(type)
      }
      this.$refs.baseInfoIndexRef.clearValidate(type ? 'managerTrainingPath' : 'principalTrainingPath')
    },
    generateFilesUid(type) {
      let data = {
        folder: '/hazard_declaration',
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/systematic/tools/getAnnexUploadUid-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$refs[type ? 'managerTrainingPathList' : 'principalTrainingPathList'].submit(data.annexUploadUid)
            this.$emit('loading', false)
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    principalTrainingPathSuccess(filePathList) {
      this.annexFileSuccess(false, filePathList)
    },
    managerTrainingPathSuccess(filePathList) {
      this.annexFileSuccess(true, filePathList)
    },
    annexFileSuccess(type, filePathList) {
      let list = this.msForm[type ? 'managerTrainingPathList' : 'principalTrainingPathList']
      if (this.$zwxBase.verifyIsNotBlank(list) && this.$zwxBase.verifyIsNotBlank(filePathList)) {
        for (let i in list) {
          for (let j in filePathList) {
            if (list[i].fileName === filePathList[j].fileName) {
              list[i].filePath = filePathList[j].filePath
            }
          }
        }
        if (!type) {
          this.msForm.principalTrainingPath = list[0].filePath
        } else {
          this.msForm.managerTrainingPath = list[0].filePath
        }
      }
      this.$forceUpdate()
    },
    delAnnexFile(type) {
      let list = this.msForm[type ? 'managerTrainingPathList' : 'principalTrainingPathList']
      list.splice(0, 1)
      if (!type) {
        this.msForm.principalTrainingPath = ''
      } else {
        this.msForm.managerTrainingPath = ''
      }
      this.$forceUpdate()
    },
    fileUploadError() {
      this.$system.notify('失败', '文件上传失败', 'error')
    },
    validateTrainingPath(rule, value, callback) {
      if (rule.required) {
        if (this.$zwxBase.verifyIsBlank(value)) {
          return callback(new Error('请上传证明'))
        }
        return callback()
      }
      return callback()
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-select.zwx-select.economicCode > .el-input.el-input--suffix .el-input__inner {
  width: 300px !important;
}
.base-info-index {
  .declare-type {
    border-bottom: 1px solid rgba(213, 217, 224, 1);
    padding-top: 16px;
  }
  .info-box {
    height: 65px;
    border-top: 1px solid rgba(213, 217, 224, 1);
    padding: 16px !important;
  }
  .ms-el-message {
    display: inline-block;
    min-width: 236px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    font-size: 12px;
    padding: 5px 10px 5px 9px;
    border-radius: 2px;
  }

  .ms-el-message--warning {
    background-color: #fff5e6;
    border-color: #ffa738;
    color: #ff9400;
  }
}
</style>
<style lang="less">
.zwx-basic-information-management-edit-zone .zwx-input {
  width: 300px !important;
}

.zwx-basic-information-management-edit-zone .el-popover__reference {
  width: 300px !important;
}

/* 行业分类选择 */
.zwx-occ-category-input {
  position: relative;
}

.zwx-occ-category-input .el-input-group__append {
  position: absolute;
  background-color: transparent;
  padding: 0;
  top: 0;
  bottom: 0;
  right: 16px;
  color: #6d87a3 !important;
  border: none;
}

.zwx-occ-category-input .el-input-group__append > span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  right: 0;
  height: 100%;
  cursor: pointer;
}

.unit-search-popover {
  min-width: 227px;
  background: #ffffff;
  border: 1px solid #d3dce6;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04), 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  padding: 0;
}

.unit-search-popover .drop-down-body {
  padding: 10px 0;
  border-bottom: 1px solid #eaeef1;
  height: 300px;
}

.unit-search-popover .drop-down-item {
  /*height: 24px;*/
  line-height: 24px;
  cursor: pointer;
  padding: 0 12px;
  font-size: 12px;
  color: rgba(102, 102, 102, 1);
}

.unit-search-popover .drop-down-item:hover {
  background: #f7f8fa;
}

.unit-search-popover .drop-down-item.item-more {
  color: rgba(80, 125, 252, 1);
}

.drop-down-body .drop-down-item:last-of-type {
  padding: 10px 0;
  border-bottom: none;
}

.drop-down-item p {
  display: flex;
  padding: 0;
  line-height: 20px;
  margin: 0 0 5px 0;
  font-size: 12px;
  justify-content: space-between;
}

.zwx-unit-input.is-disabled .el-input__inner {
  padding-right: 28px !important;
}
.year-declaration-dialog .el-dialog {
  width: 400px !important;
  min-width: unset !important;
}
.year-declaration-dialog .el-dialog__footer span {
  display: unset !important;
}

.zwx-unit-search-dialog .el-dialog__footer {
  border-top: unset !important;
}

.zwx-unit-search-dialog .zwx-radio > .el-radio__input + .el-radio__label {
  display: none;
}

.dynamic-table-class .zwx-table .zwx-checkbox {
  margin-right: unset !important;
}
</style>
