var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "major-product-index" },
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "majorProductIndexRef",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.msForm,
            "label-position": "right",
            rules: _vm.majorProductRules
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "edit-row",
              staticStyle: { "margin-bottom": "12px" }
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: { icon: "el-icon-plus" },
                  on: {
                    click: function($event) {
                      return _vm.addProduct()
                    }
                  }
                },
                [_vm._v("添加")]
              ),
              _c(
                "el-checkbox",
                {
                  staticStyle: { "margin-left": "14px" },
                  model: {
                    value: _vm.msForm.ifClassifiedUnit,
                    callback: function($$v) {
                      _vm.$set(_vm.msForm, "ifClassifiedUnit", $$v)
                    },
                    expression: "msForm.ifClassifiedUnit"
                  }
                },
                [_vm._v("军工等涉密企业")]
              ),
              _c(
                "div",
                {
                  staticClass: "ms-el-message ms-el-message--warning",
                  staticStyle: {
                    display: "inline-block",
                    "margin-left": "10px"
                  }
                },
                [
                  _c("i", { staticClass: "el-icon-warning" }),
                  _vm._v(
                    " 非生产型企业，如医院、饭店等，主要产品项名称及计量单位可填“无”，年产量填“0”。 "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              key: "majorProductListTable",
              staticClass: "zwx-table",
              staticStyle: { width: "100%" },
              attrs: {
                "row-key": "rid",
                data: _vm.msForm.majorProductList,
                stripe: "",
                border: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "productName",
                  label: "产品名称",
                  width: "350",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "majorProductList." +
                                scope.$index +
                                ".productName",
                              rules: _vm.majorProductRules.productName
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "zwx-input cc-input",
                              staticStyle: {
                                cursor: "pointer",
                                "min-width": "68px!important"
                              },
                              attrs: {
                                size: "small",
                                placeholder: "请输入",
                                clearable: "",
                                maxlength: "100"
                              },
                              model: {
                                value: scope.row.productName,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "productName", $$v)
                                },
                                expression: "scope.row.productName"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "dosage",
                  label: "年产量",
                  width: "180",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "majorProductList." + scope.$index + ".dosage",
                              rules: _vm.majorProductRules.dosage
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "zwx-input cc-input",
                              staticStyle: {
                                cursor: "pointer",
                                "min-width": "68px!important"
                              },
                              attrs: {
                                size: "small",
                                placeholder: "请输入",
                                clearable: "",
                                maxlength: "11"
                              },
                              on: {
                                input: function($event) {
                                  return _vm.$validate.nonnegativeDouble(
                                    _vm.$data["msForm"]["majorProductList"][
                                      scope.$index
                                    ],
                                    "dosage",
                                    8,
                                    2
                                  )
                                }
                              },
                              model: {
                                value: scope.row.dosage,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "dosage", $$v)
                                },
                                expression: "scope.row.dosage"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "unit",
                  label: "计量单位",
                  width: "180",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "majorProductList." + scope.$index + ".unit",
                              rules: _vm.majorProductRules.unit
                            }
                          },
                          [
                            _c("el-autocomplete", {
                              staticClass: "zwx-input",
                              attrs: {
                                "value-key": "codeName",
                                "fetch-suggestions": _vm.unitAutocompleteQuery,
                                maxlength: 10,
                                clearable: ""
                              },
                              model: {
                                value: scope.row.unit,
                                callback: function($$v) {
                                  _vm.$set(
                                    scope.row,
                                    "unit",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "scope.row.unit"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "left",
                  align: "left",
                  fixed: "right",
                  "min-width": "200"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.$zwxBase.verifyIsNotBlank(scope.row.productName) ||
                        _vm.$zwxBase.verifyIsNotBlank(scope.row.dosage) ||
                        _vm.$zwxBase.verifyIsNotBlank(scope.row.unit)
                          ? _c(
                              "el-popconfirm",
                              {
                                attrs: {
                                  title: "确认删除吗？",
                                  "confirm-button-type": "text"
                                },
                                on: {
                                  confirm: function($event) {
                                    return _vm.delProduct(scope)
                                  }
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass:
                                      "zwx-button zwx-button-text-26",
                                    attrs: {
                                      slot: "reference",
                                      type: "danger"
                                    },
                                    slot: "reference"
                                  },
                                  [_vm._v("删除")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$zwxBase.verifyIsBlank(scope.row.productName) &&
                        _vm.$zwxBase.verifyIsBlank(scope.row.dosage) &&
                        _vm.$zwxBase.verifyIsBlank(scope.row.unit)
                          ? _c(
                              "el-button",
                              {
                                staticClass: "zwx-button zwx-button-text-26",
                                attrs: { type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.delProduct(scope)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }