var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "health-supervision-detail-index" },
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "healthSupervisionDetailIndexRef",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.msForm,
            rules: _vm.rules,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "max-width": "1232px",
                padding: "10px 0 5px 20px !important"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "196px",
                    label: "本年度职业健康检查开展情况：",
                    prop: "projectNature"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "zwx-radio-group",
                      attrs: { disabled: _vm.radioDisabled },
                      on: { change: _vm.existsSupervisionChange },
                      model: {
                        value: _vm.msForm.existsSupervision,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "existsSupervision", $$v)
                        },
                        expression: "msForm.existsSupervision"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: true } },
                        [_vm._v("是")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: false } },
                        [_vm._v("否")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.isShow
            ? _c(
                "div",
                [
                  _c(
                    "base-headline",
                    {
                      staticClass: "headline no-bottom",
                      attrs: { title: "检查机构情况" }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "zwx-button zwx-button-icontext-28",
                          attrs: { icon: "el-icon-plus" },
                          on: { click: _vm.addList }
                        },
                        [_vm._v("添加")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          ref: "tableMsForm",
                          staticClass: "zwx-table",
                          staticStyle: { width: "100%" },
                          attrs: {
                            "row-key": "rid",
                            data: _vm.msForm.healthSupervisionRecordList,
                            border: "",
                            stripe: ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "unitName",
                              label: "检查机构名称",
                              width: "350",
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "healthSupervisionRecordList." +
                                              scope.$index +
                                              ".unitName",
                                            rules: _vm.rules.unitName
                                          }
                                        },
                                        [
                                          _c("detection-organ-select", {
                                            ref:
                                              "healthSupervisionRef_" +
                                              scope.$index,
                                            staticClass: "organ-select",
                                            staticStyle: {
                                              width: "315px!important"
                                            },
                                            attrs: {
                                              requestUrl:
                                                "/oh/repository/findTechnicalServiceAgencyList-1",
                                              codeType: "2011"
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.healthSupervisionOrganSelectChange(
                                                  $event,
                                                  scope.$index
                                                )
                                              }
                                            },
                                            model: {
                                              value: scope.row.unitName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "unitName",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.unitName"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3934464941
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "检查总结报告编号",
                              width: "220",
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "healthSupervisionRecordList." +
                                              scope.$index +
                                              ".reportNo",
                                            rules: _vm.rules.reportNo
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "zwx-input",
                                            attrs: {
                                              placeholder: "请输入",
                                              clearable: "",
                                              maxlength: "50"
                                            },
                                            model: {
                                              value: scope.row.reportNo,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "reportNo",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.reportNo"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3326975935
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "社会信用代码",
                              width: "230",
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "healthSupervisionRecordList." +
                                              scope.$index +
                                              ".creditCode",
                                            rules: _vm.rules.creditCode
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "zwx-input",
                                            attrs: {
                                              placeholder: "请输入",
                                              maxlength: "50"
                                            },
                                            model: {
                                              value: scope.row.creditCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "creditCode",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.creditCode"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              752459189
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "联系电话",
                              width: "200",
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "healthSupervisionRecordList." +
                                              scope.$index +
                                              ".contactInfo",
                                            rules: _vm.rules.contactInfo
                                          }
                                        },
                                        [
                                          _c("el-input", {
                                            staticClass: "zwx-input",
                                            attrs: {
                                              placeholder: "请输入",
                                              clearable: "",
                                              maxlength: "20"
                                            },
                                            model: {
                                              value: scope.row.contactInfo,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "contactInfo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.contactInfo"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              4094194617
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "操作",
                              "header-align": "left",
                              align: "left"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          staticClass:
                                            "zwx-button zwx-button-text-26",
                                          attrs: { type: "danger" },
                                          on: {
                                            click: function($event) {
                                              return _vm.deletion(scope.$index)
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1016729326
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("base-headline", {
                    staticClass: "headline no-top",
                    attrs: { title: "检查情况" }
                  }),
                  _c("div", { staticClass: "harz-list" }, [
                    _vm.msForm.dustHazardList.length > 0
                      ? _c("div", { staticClass: "harz" }, [
                          _c(
                            "div",
                            {
                              staticClass: "flex-row",
                              staticStyle: {
                                margin: "12px 16px",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("span", [_vm._v("粉尘因素")]),
                              _c(
                                "div",
                                {
                                  staticClass: "flex-row stages-css",
                                  staticStyle: { "align-items": "center" },
                                  on: {
                                    click: function($event) {
                                      return _vm.itemSelect(1)
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.msForm.existsDust,
                                          expression: "msForm.existsDust"
                                        }
                                      ],
                                      attrs: {
                                        src: require("../../../../assets/images/hazard_declaration/ic_select_1.svg"),
                                        alt: ""
                                      }
                                    }),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.msForm.existsDust,
                                          expression: "!msForm.existsDust"
                                        }
                                      ],
                                      attrs: {
                                        src: require("../../../../assets/images/hazard_declaration/ic_no_select.svg"),
                                        alt: ""
                                      }
                                    })
                                  ]),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "8px" } },
                                    [_vm._v("检查")]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { height: "36px" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "体检总人数：",
                                        "label-width": "96px",
                                        prop: "dustNumber",
                                        rules: _vm.msForm.existsDust
                                          ? _vm.rules.dustNumber
                                          : _vm.rules.noRequired
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "zwx-input",
                                        attrs: {
                                          placeholder: "请输入",
                                          clearable: "",
                                          maxlength: "50",
                                          disabled: !_vm.msForm.existsDust
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.$validate.nonnegativeInteger(
                                              _vm.msForm,
                                              "dustNumber"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.msForm.dustNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.msForm,
                                              "dustNumber",
                                              $$v
                                            )
                                          },
                                          expression: "msForm.dustNumber"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "div-table" },
                            [
                              _c("div", { staticClass: "div-table-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center",
                                    staticStyle: { "min-width": "250px" }
                                  },
                                  [_vm._v("检查危害因素")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center"
                                  },
                                  [_vm._v("体检人数")]
                                ),
                                _c("div", {
                                  staticClass: "div-table-head-border"
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center",
                                    staticStyle: { "min-width": "250px" }
                                  },
                                  [_vm._v("检查危害因素")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center"
                                  },
                                  [_vm._v("体检人数")]
                                ),
                                _c("div", {
                                  staticClass: "div-table-head-border"
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center",
                                    staticStyle: { "min-width": "250px" }
                                  },
                                  [_vm._v("检查危害因素")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center"
                                  },
                                  [_vm._v("体检人数")]
                                ),
                                _c("div", {
                                  staticClass:
                                    "div-table-head-name-col flew-center",
                                  staticStyle: { width: "calc(100% - 1508px)" }
                                })
                              ]),
                              _vm._l(_vm.msForm.dustHazardList, function(
                                childs,
                                i
                              ) {
                                return [
                                  _c(
                                    "div",
                                    { key: i, staticClass: "div-table-row" },
                                    [
                                      _vm._l(childs, function(child, j) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: "hazardsName" + i + j,
                                              staticClass:
                                                "div-table-td-name-col flew-center",
                                              staticStyle: {
                                                "justify-content": "flex-start",
                                                "min-width": "250px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(child.hazardsName) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              key: "peNumber" + i + j,
                                              staticClass:
                                                "div-table-td-num-col flew-center"
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom":
                                                      "0 !important",
                                                    "margin-right":
                                                      "0 !important"
                                                  },
                                                  attrs: {
                                                    id:
                                                      "dustHazardList." + i + j,
                                                    prop:
                                                      "dustHazardList." +
                                                      i +
                                                      "." +
                                                      j +
                                                      ".peNumber",
                                                    rules: _vm.msForm.existsDust
                                                      ? _vm.rules.peNumber
                                                      : _vm.rules.noRequired
                                                  }
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticClass: "zwx-input",
                                                    staticStyle: {
                                                      width: "100% !important"
                                                    },
                                                    attrs: {
                                                      maxlength: "8",
                                                      clearable: "",
                                                      disabled: !_vm.msForm
                                                        .existsDust
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.$validate.nonnegativeInteger(
                                                          child,
                                                          "peNumber"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: child.peNumber,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          child,
                                                          "peNumber",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "child.peNumber"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("div", {
                                            key: j,
                                            staticClass: "div-table-td-border",
                                            style:
                                              j == childs.length - 1
                                                ? {
                                                    borderBottom:
                                                      "1px solid #e1e5f4"
                                                  }
                                                : ""
                                          }),
                                          childs.length === 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-name-col flew-center",
                                                staticStyle: {
                                                  "min-width": "250px"
                                                }
                                              })
                                            : _vm._e(),
                                          childs.length === 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-num-col flew-center"
                                              })
                                            : _vm._e(),
                                          childs.length === 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-name-col flew-center",
                                                staticStyle: {
                                                  "min-width": "250px"
                                                }
                                              })
                                            : _vm._e(),
                                          childs.length === 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-num-col flew-center"
                                              })
                                            : _vm._e(),
                                          childs.length === 2 &&
                                          j === childs.length - 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-name-col flew-center",
                                                staticStyle: {
                                                  "min-width": "250px"
                                                }
                                              })
                                            : _vm._e(),
                                          childs.length === 2 &&
                                          j === childs.length - 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-num-col flew-center"
                                              })
                                            : _vm._e()
                                        ]
                                      }),
                                      _c("div", {
                                        staticClass:
                                          "div-table-td-name-col flew-center",
                                        staticStyle: {
                                          width: "calc(100% - 1508px)"
                                        }
                                      })
                                    ],
                                    2
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ])
                      : _vm._e(),
                    _vm.msForm.chemicalHazardList.length > 0
                      ? _c("div", { staticClass: "harz" }, [
                          _c(
                            "div",
                            {
                              staticClass: "flex-row",
                              staticStyle: {
                                margin: "12px 16px",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("span", [_vm._v("化学因素")]),
                              _c(
                                "div",
                                {
                                  staticClass: "flex-row stages-css",
                                  staticStyle: { "align-items": "center" },
                                  on: {
                                    click: function($event) {
                                      _vm.msForm.existsChemical = !_vm.msForm
                                        .existsChemical
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.msForm.existsChemical,
                                          expression: "msForm.existsChemical"
                                        }
                                      ],
                                      attrs: {
                                        src: require("../../../../assets/images/hazard_declaration/ic_select_1.svg"),
                                        alt: ""
                                      }
                                    }),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.msForm.existsChemical,
                                          expression: "!msForm.existsChemical"
                                        }
                                      ],
                                      attrs: {
                                        src: require("../../../../assets/images/hazard_declaration/ic_no_select.svg"),
                                        alt: ""
                                      }
                                    })
                                  ]),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "8px" } },
                                    [_vm._v("检查")]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { height: "36px" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "体检总人数：",
                                        "label-width": "96px",
                                        prop: "chemicalNumber",
                                        rules: _vm.msForm.existsChemical
                                          ? _vm.rules.chemicalNumber
                                          : _vm.rules.noRequired
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "zwx-input",
                                        attrs: {
                                          placeholder: "请输入",
                                          clearable: "",
                                          maxlength: "50",
                                          disabled: !_vm.msForm.existsChemical
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.$validate.nonnegativeInteger(
                                              _vm.msForm,
                                              "chemicalNumber"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.msForm.chemicalNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.msForm,
                                              "chemicalNumber",
                                              $$v
                                            )
                                          },
                                          expression: "msForm.chemicalNumber"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "div-table" },
                            [
                              _c("div", { staticClass: "div-table-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center",
                                    staticStyle: { "min-width": "250px" }
                                  },
                                  [_vm._v("检查危害因素")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center"
                                  },
                                  [_vm._v("体检人数")]
                                ),
                                _c("div", {
                                  staticClass: "div-table-head-border"
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center",
                                    staticStyle: { "min-width": "250px" }
                                  },
                                  [_vm._v("检查危害因素")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center"
                                  },
                                  [_vm._v("体检人数")]
                                ),
                                _c("div", {
                                  staticClass: "div-table-head-border"
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center",
                                    staticStyle: { "min-width": "250px" }
                                  },
                                  [_vm._v("检查危害因素")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center"
                                  },
                                  [_vm._v("体检人数")]
                                ),
                                _c("div", {
                                  staticClass:
                                    "div-table-head-name-col flew-center",
                                  staticStyle: { width: "calc(100% - 1508px)" }
                                })
                              ]),
                              _vm._l(_vm.msForm.chemicalHazardList, function(
                                childs,
                                i
                              ) {
                                return [
                                  _c(
                                    "div",
                                    { key: i, staticClass: "div-table-row" },
                                    [
                                      _vm._l(childs, function(child, j) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: "hazardsName" + i + j,
                                              staticClass:
                                                "div-table-td-name-col flew-center",
                                              staticStyle: {
                                                "justify-content": "flex-start",
                                                "min-width": "250px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(child.hazardsName) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              key: "peNumber" + i + j,
                                              staticClass:
                                                "div-table-td-num-col flew-center"
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom":
                                                      "0 !important",
                                                    "margin-right":
                                                      "0 !important"
                                                  },
                                                  attrs: {
                                                    id:
                                                      "chemicalHazardList." +
                                                      i +
                                                      j,
                                                    prop:
                                                      "chemicalHazardList." +
                                                      i +
                                                      "." +
                                                      j +
                                                      ".peNumber",
                                                    rules: _vm.msForm
                                                      .existsChemical
                                                      ? _vm.rules.peNumber
                                                      : _vm.rules.noRequired
                                                  }
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticClass: "zwx-input",
                                                    staticStyle: {
                                                      width: "100% !important"
                                                    },
                                                    attrs: {
                                                      placeholder: "",
                                                      maxlength: "8",
                                                      clearable: "",
                                                      disabled: !_vm.msForm
                                                        .existsChemical
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.$validate.nonnegativeInteger(
                                                          child,
                                                          "peNumber"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: child.peNumber,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          child,
                                                          "peNumber",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "child.peNumber"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("div", {
                                            key: j,
                                            staticClass: "div-table-td-border",
                                            style:
                                              j == childs.length - 1
                                                ? {
                                                    borderBottom:
                                                      "1px solid #e1e5f4"
                                                  }
                                                : ""
                                          }),
                                          childs.length === 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-name-col flew-center",
                                                staticStyle: {
                                                  "min-width": "250px"
                                                }
                                              })
                                            : _vm._e(),
                                          childs.length === 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-num-col flew-center"
                                              })
                                            : _vm._e(),
                                          childs.length === 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-name-col flew-center",
                                                staticStyle: {
                                                  "min-width": "250px"
                                                }
                                              })
                                            : _vm._e(),
                                          childs.length === 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-num-col flew-center"
                                              })
                                            : _vm._e(),
                                          childs.length === 2 &&
                                          j === childs.length - 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-name-col flew-center",
                                                staticStyle: {
                                                  "min-width": "250px"
                                                }
                                              })
                                            : _vm._e(),
                                          childs.length === 2 &&
                                          j === childs.length - 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-num-col flew-center"
                                              })
                                            : _vm._e()
                                        ]
                                      }),
                                      _c("div", {
                                        staticClass:
                                          "div-table-td-name-col flew-center",
                                        staticStyle: {
                                          width: "calc(100% - 1508px)"
                                        }
                                      })
                                    ],
                                    2
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ])
                      : _vm._e(),
                    _vm.msForm.physicalHazardList.length > 0
                      ? _c("div", { staticClass: "harz" }, [
                          _c(
                            "div",
                            {
                              staticClass: "flex-row",
                              staticStyle: {
                                margin: "12px 16px",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("span", [_vm._v("物理因素")]),
                              _c(
                                "div",
                                {
                                  staticClass: "flex-row stages-css",
                                  staticStyle: { "align-items": "center" },
                                  on: {
                                    click: function($event) {
                                      _vm.msForm.existsPhysical = !_vm.msForm
                                        .existsPhysical
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.msForm.existsPhysical,
                                          expression: "msForm.existsPhysical"
                                        }
                                      ],
                                      attrs: {
                                        src: require("../../../../assets/images/hazard_declaration/ic_select_1.svg"),
                                        alt: ""
                                      }
                                    }),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.msForm.existsPhysical,
                                          expression: "!msForm.existsPhysical"
                                        }
                                      ],
                                      attrs: {
                                        src: require("../../../../assets/images/hazard_declaration/ic_no_select.svg"),
                                        alt: ""
                                      }
                                    })
                                  ]),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "8px" } },
                                    [_vm._v("检查")]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { height: "36px" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "体检总人数：",
                                        "label-width": "96px",
                                        prop: "physicalNumber",
                                        rules: _vm.msForm.existsPhysical
                                          ? _vm.rules.physicalNumber
                                          : _vm.rules.noRequired
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "zwx-input",
                                        attrs: {
                                          placeholder: "请输入",
                                          clearable: "",
                                          maxlength: "50",
                                          disabled: !_vm.msForm.existsPhysical
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.$validate.nonnegativeInteger(
                                              _vm.msForm,
                                              "physicalNumber"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.msForm.physicalNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.msForm,
                                              "physicalNumber",
                                              $$v
                                            )
                                          },
                                          expression: "msForm.physicalNumber"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "div-table" },
                            [
                              _c("div", { staticClass: "div-table-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center",
                                    staticStyle: { "min-width": "250px" }
                                  },
                                  [_vm._v("检查危害因素")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center"
                                  },
                                  [_vm._v("体检人数")]
                                ),
                                _c("div", {
                                  staticClass: "div-table-head-border"
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center",
                                    staticStyle: { "min-width": "250px" }
                                  },
                                  [_vm._v("检查危害因素")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center"
                                  },
                                  [_vm._v("体检人数")]
                                ),
                                _c("div", {
                                  staticClass: "div-table-head-border"
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center",
                                    staticStyle: { "min-width": "250px" }
                                  },
                                  [_vm._v("检查危害因素")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center"
                                  },
                                  [_vm._v("体检人数")]
                                ),
                                _c("div", {
                                  staticClass:
                                    "div-table-head-name-col flew-center",
                                  staticStyle: { width: "calc(100% - 1508px)" }
                                })
                              ]),
                              _vm._l(_vm.msForm.physicalHazardList, function(
                                childs,
                                i
                              ) {
                                return [
                                  _c(
                                    "div",
                                    { key: i, staticClass: "div-table-row" },
                                    [
                                      _vm._l(childs, function(child, j) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: "hazardsName" + i + j,
                                              staticClass:
                                                "div-table-td-name-col flew-center",
                                              staticStyle: {
                                                "justify-content": "flex-start",
                                                "min-width": "250px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(child.hazardsName) +
                                                  " "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              key: "peNumber" + i + j,
                                              staticClass:
                                                "div-table-td-num-col flew-center"
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom":
                                                      "0 !important",
                                                    "margin-right":
                                                      "0 !important"
                                                  },
                                                  attrs: {
                                                    id:
                                                      "physicalHazardList." +
                                                      i +
                                                      j,
                                                    prop:
                                                      "physicalHazardList." +
                                                      i +
                                                      "." +
                                                      j +
                                                      ".peNumber",
                                                    rules: _vm.msForm
                                                      .existsPhysical
                                                      ? _vm.rules.peNumber
                                                      : _vm.rules.noRequired
                                                  }
                                                },
                                                [
                                                  _c("el-input", {
                                                    staticClass: "zwx-input",
                                                    staticStyle: {
                                                      width: "100% !important"
                                                    },
                                                    attrs: {
                                                      maxlength: "8",
                                                      clearable: "",
                                                      disabled: !_vm.msForm
                                                        .existsPhysical
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.$validate.nonnegativeInteger(
                                                          child,
                                                          "peNumber"
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value: child.peNumber,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          child,
                                                          "peNumber",
                                                          typeof $$v ===
                                                            "string"
                                                            ? $$v.trim()
                                                            : $$v
                                                        )
                                                      },
                                                      expression:
                                                        "child.peNumber"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("div", {
                                            key: j,
                                            staticClass: "div-table-td-border",
                                            style:
                                              j == childs.length - 1
                                                ? {
                                                    borderBottom:
                                                      "1px solid #e1e5f4"
                                                  }
                                                : ""
                                          }),
                                          childs.length === 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-name-col flew-center",
                                                staticStyle: {
                                                  "min-width": "250px"
                                                }
                                              })
                                            : _vm._e(),
                                          childs.length === 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-num-col flew-center"
                                              })
                                            : _vm._e(),
                                          childs.length === 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-name-col flew-center",
                                                staticStyle: {
                                                  "min-width": "250px"
                                                }
                                              })
                                            : _vm._e(),
                                          childs.length === 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-num-col flew-center"
                                              })
                                            : _vm._e(),
                                          childs.length === 2 &&
                                          j === childs.length - 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-name-col flew-center",
                                                staticStyle: {
                                                  "min-width": "250px"
                                                }
                                              })
                                            : _vm._e(),
                                          childs.length === 2 &&
                                          j === childs.length - 1
                                            ? _c("div", {
                                                staticClass:
                                                  "div-table-td-num-col flew-center"
                                              })
                                            : _vm._e()
                                        ]
                                      }),
                                      _c("div", {
                                        staticClass:
                                          "div-table-td-name-col flew-center",
                                        staticStyle: {
                                          width: "calc(100% - 1508px)"
                                        }
                                      })
                                    ],
                                    2
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ])
                      : _vm._e(),
                    _vm.msForm.radioactivityHazardList.length > 0
                      ? _c("div", { staticClass: "harz" }, [
                          _c(
                            "div",
                            {
                              staticClass: "flex-row",
                              staticStyle: {
                                margin: "12px 16px",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("span", [_vm._v("放射危害因素")]),
                              _c(
                                "div",
                                {
                                  staticClass: "flex-row stages-css",
                                  staticStyle: { "align-items": "center" },
                                  on: {
                                    click: function($event) {
                                      _vm.msForm.existsRadioactivity = !_vm
                                        .msForm.existsRadioactivity
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.msForm.existsRadioactivity,
                                          expression:
                                            "msForm.existsRadioactivity"
                                        }
                                      ],
                                      attrs: {
                                        src: require("../../../../assets/images/hazard_declaration/ic_select_1.svg"),
                                        alt: ""
                                      }
                                    }),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.msForm
                                            .existsRadioactivity,
                                          expression:
                                            "!msForm.existsRadioactivity"
                                        }
                                      ],
                                      attrs: {
                                        src: require("../../../../assets/images/hazard_declaration/ic_no_select.svg"),
                                        alt: ""
                                      }
                                    })
                                  ]),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "8px" } },
                                    [_vm._v("检查")]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { height: "36px" } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "体检总人数：",
                                        "label-width": "96px",
                                        prop: "radioactivityNumber",
                                        rules: _vm.msForm.existsRadioactivity
                                          ? _vm.rules.radioactivityNumber
                                          : _vm.rules.noRequired
                                      }
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "zwx-input",
                                        attrs: {
                                          placeholder: "请输入",
                                          clearable: "",
                                          maxlength: "50",
                                          disabled: !_vm.msForm
                                            .existsRadioactivity
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.$validate.nonnegativeInteger(
                                              _vm.msForm,
                                              "radioactivityNumber"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.msForm.radioactivityNumber,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.msForm,
                                              "radioactivityNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "msForm.radioactivityNumber"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "div-table" },
                            [
                              _c("div", { staticClass: "div-table-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center",
                                    staticStyle: { "min-width": "250px" }
                                  },
                                  [_vm._v("检查危害因素")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center"
                                  },
                                  [_vm._v("体检人数")]
                                ),
                                _c("div", {
                                  staticClass: "div-table-head-border"
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center",
                                    staticStyle: { "min-width": "250px" }
                                  },
                                  [_vm._v("检查危害因素")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center"
                                  },
                                  [_vm._v("体检人数")]
                                ),
                                _c("div", {
                                  staticClass: "div-table-head-border"
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center",
                                    staticStyle: { "min-width": "250px" }
                                  },
                                  [_vm._v("检查危害因素")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "div-table-head-name-col flew-center"
                                  },
                                  [_vm._v("体检人数")]
                                ),
                                _c("div", {
                                  staticClass:
                                    "div-table-head-name-col flew-center",
                                  staticStyle: { width: "calc(100% - 1508px)" }
                                })
                              ]),
                              _vm._l(
                                _vm.msForm.radioactivityHazardList,
                                function(childs, i) {
                                  return [
                                    _c(
                                      "div",
                                      { key: i, staticClass: "div-table-row" },
                                      [
                                        _vm._l(childs, function(child, j) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                key: "hazardsName" + i + j,
                                                staticClass:
                                                  "div-table-td-name-col flew-center",
                                                staticStyle: {
                                                  "justify-content":
                                                    "flex-start",
                                                  "min-width": "250px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(child.hazardsName) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                key: "peNumber" + i + j,
                                                staticClass:
                                                  "div-table-td-num-col flew-center"
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom":
                                                        "0 !important",
                                                      "margin-right":
                                                        "0 !important"
                                                    },
                                                    attrs: {
                                                      id:
                                                        "radioactivityHazardList." +
                                                        i +
                                                        j,
                                                      prop:
                                                        "radioactivityHazardList." +
                                                        i +
                                                        "." +
                                                        j +
                                                        ".peNumber",
                                                      rules: _vm.msForm
                                                        .existsRadioactivity
                                                        ? _vm.rules.peNumber
                                                        : _vm.rules.noRequired
                                                    }
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      staticClass: "zwx-input",
                                                      staticStyle: {
                                                        width: "100% !important"
                                                      },
                                                      attrs: {
                                                        maxlength: "8",
                                                        clearable: "",
                                                        disabled: !_vm.msForm
                                                          .existsRadioactivity
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.$validate.nonnegativeInteger(
                                                            child,
                                                            "peNumber"
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: child.peNumber,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            child,
                                                            "peNumber",
                                                            typeof $$v ===
                                                              "string"
                                                              ? $$v.trim()
                                                              : $$v
                                                          )
                                                        },
                                                        expression:
                                                          "child.peNumber"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c("div", {
                                              key: j,
                                              staticClass:
                                                "div-table-td-border",
                                              style:
                                                j == childs.length - 1
                                                  ? {
                                                      borderBottom:
                                                        "1px solid #e1e5f4"
                                                    }
                                                  : ""
                                            }),
                                            childs.length === 1
                                              ? _c("div", {
                                                  staticClass:
                                                    "div-table-td-name-col flew-center",
                                                  staticStyle: {
                                                    "min-width": "250px"
                                                  }
                                                })
                                              : _vm._e(),
                                            childs.length === 1
                                              ? _c("div", {
                                                  staticClass:
                                                    "div-table-td-num-col flew-center"
                                                })
                                              : _vm._e(),
                                            childs.length === 1
                                              ? _c("div", {
                                                  staticClass:
                                                    "div-table-td-name-col flew-center",
                                                  staticStyle: {
                                                    "min-width": "250px"
                                                  }
                                                })
                                              : _vm._e(),
                                            childs.length === 1
                                              ? _c("div", {
                                                  staticClass:
                                                    "div-table-td-num-col flew-center"
                                                })
                                              : _vm._e(),
                                            childs.length === 2 &&
                                            j === childs.length - 1
                                              ? _c("div", {
                                                  staticClass:
                                                    "div-table-td-name-col flew-center",
                                                  staticStyle: {
                                                    "min-width": "250px"
                                                  }
                                                })
                                              : _vm._e(),
                                            childs.length === 2 &&
                                            j === childs.length - 1
                                              ? _c("div", {
                                                  staticClass:
                                                    "div-table-td-num-col flew-center"
                                                })
                                              : _vm._e()
                                          ]
                                        }),
                                        _c("div", {
                                          staticClass:
                                            "div-table-td-name-col flew-center",
                                          staticStyle: {
                                            width: "calc(100% - 1508px)"
                                          }
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                }
                              )
                            ],
                            2
                          )
                        ])
                      : _vm._e()
                  ])
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }