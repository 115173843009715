<template>
  <el-dialog class="zwx-dialog" :title="title" :visible.sync="visible" @close="close()" :close-on-click-modal="false" :destroy-on-close="true">
    <div class="content">
      <el-form class="ms-form" key="msForm" ref="msForm" :model="msForm" :rules="rules" label-position="right" label-width="110px" @submit.native.prevent>
        <el-form-item label="应用场景：" prop="appScenario">
          <el-radio-group v-model="msForm.appScenario">
            <el-radio class="zwx-radio" :label="0">通用商户</el-radio>
            <el-radio class="zwx-radio" :label="1">个性商户</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="商户类型：" prop="businessType">
          <el-select class="zwx-select " popper-class="zwx-select-popper" v-model="msForm.businessType" :multiple="false" collapse-tags placeholder="请选择" clearable>
            <el-option label="微信" value="04"></el-option>
            <el-option label="支付宝" value="01"></el-option>
            <el-option label="银联商务" value="05"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商户编号：" prop="merchantNo">
          <el-input class="zwx-input" v-model.trim="msForm.merchantNo" style="width: 100% !important" placeholder="请输入" clearable maxlength="50" />
        </el-form-item>
        <el-form-item label="商户名称：" prop="payDescribe">
          <el-input class="zwx-input" v-model.trim="msForm.payDescribe" style="width: 100% !important" placeholder="请输入" clearable maxlength="50" />
        </el-form-item>
        <!--
        <el-form-item label="简要描述：" prop="payDescribe">
          <el-input
            class="zwx-textarea"
            type="textarea"
            v-model.trim="msForm.payDescribe"
            :autosize="{
              minRows: 5,
              maxRows: 8,
            }"
            maxlength="50"
            placeholder="请输入"
            clearable
            :style="{ width: '400px !important' }"
          />
        </el-form-item>
        <el-form-item label="商户JSON：" prop="businessJson">
          <el-input
            class="zwx-textarea"
            type="textarea"
            v-model.trim="msForm.businessJson"
            :autosize="{
              minRows: 5,
              maxRows: 8,
            }"
            maxlength="500"
            placeholder="请输入"
            clearable
            :style="{ width: '400px !important' }"
          />
        </el-form-item>
        -->
        <el-form-item label="API证书：" prop="annexList">
          <file-upload ref="picFileRef" accept=".pem" @showFileList="picFileShowFileList" @success="picFileUploadSuccess" :size="50 * 1024 * 1024" sizeInfo="50M">
            <el-button class="zwx-button zwx-button-text-26" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(msForm.annexList)">上传</el-button>
          </file-upload>
          <template v-if="$zwxBase.verifyIsNotBlank(msForm.annexList)">
            <div class="show-file" v-for="(item, idx) in msForm.annexList" :key="idx">
              <!-- <el-button class="zwx-button zwx-button-text-26" @click="openFilePreview1(msForm.annexList, idx)">查看</el-button> -->
              <span :style="{ marginRight: '8px' }">{{ item.fileName }}</span>
              <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletionPicFile(idx)">删除</el-button>
            </div>
          </template>
        </el-form-item>
        <el-form-item label="证书序列号：" prop="merchantSerialNumber">
          <el-input class="zwx-input" v-model.trim="msForm.merchantSerialNumber" style="width: 100% !important" placeholder="请输入" clearable maxlength="50" />
        </el-form-item>
        <el-form-item label="APIv3密钥：" prop="apiV3Key">
          <el-input class="zwx-input" v-model.trim="msForm.apiV3Key" style="width: 100% !important" placeholder="请输入" clearable maxlength="50" />
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="ms-dialog-footer-specical">
      <el-button class="zwx-button zwx-button-32" @click="close()">取消</el-button>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="commit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'MerchantCreateDialog',
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      users: this.$store.state.users,
      rules: {
        appScenario: [{ required: true, message: '请选择应用场景', trigger: ['change', 'blur'] }],
        businessType: [{ required: true, message: '请选择商户类型', trigger: ['change', 'blur'] }],
        merchantNo: [{ required: true, message: '请输入商户编号', trigger: ['change', 'blur'] }],
        payDescribe: [{ required: true, message: '请输入商户名称', trigger: ['change', 'blur'] }],
        // businessJson: [{ required: true, message: '请输入商户JSON', trigger: ['change', 'blur'] }],
        annexList: [{ required: true, message: '请上传API证书', trigger: ['change', 'blur'] }],
        merchantSerialNumber: [{ required: true, message: '请输入证书序列号', trigger: ['change', 'blur'] }],
        apiV3Key: [{ required: true, message: '请输入APIv3密钥', trigger: ['change', 'blur'] }],
      },
      visible: false,
      title: '',
      systemConfig: {},
      msForm: {
        appScenario: 0,
        businessType: '04',
        merchantNo: '',
        payDescribe: '',
        /**
         * 
        businessJson: '',
         */
        annexList: [],
        merchantSerialNumber: '',
        apiV3Key: '',
      },
    }
  },
  created() {},
  methods: {
    /**
     * 单个文件预览
     */
    openFilePreview(fileName, filePath) {
      let previewFileList = []
      let item = {
        fileName: fileName,
        filePath: filePath,
      }
      previewFileList.push(item)
      this.openFilePreview1(previewFileList, 0)
    },
    /**
     * 多个文件预览
     */
    openFilePreview1(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
    /**
     * 证书上传成功回调
     */
    picFileUploadSuccess(fileList) {
      // 文件集合
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.annexList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.msForm.annexList) {
          for (let j in fileList) {
            if (this.msForm.annexList[i].fileName === fileList[j].fileName) {
              this.msForm.annexList[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      /*this.msForm.trainingOptionList.forEach(item => {
        this.generateFilesUid(item.uuid)
      })*/
    },
    /**
     * 文件上传失败回调
     */
    picFileUploadSuccessError() {
      this.$system.notify('失败', '文件上传失败', 'error')
    },
    // 获取上传证书的UID
    generateFilesUidPic() {
      this.$emit('loading', true)
      let data = {
        folder: 'saas/' + this.systemConfig.domainPrefix + '/base',
      }
      this.$system.get(
        this.api + '/systematic/tools/getAnnexUploadUid-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$refs.picFileRef.submit(data.annexUploadUid)
            this.$emit('loading', false)
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        {}
      )
    },
    /**
     * 证书上传显示回调
     */
    picFileShowFileList(showList) {
      showList.forEach(item => {
        let flag = this.msForm.annexList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.msForm.annexList.push({
          fileName: item,
          fileIconShow: this.$zwxBase.dealfilePrefix(item),
          check: false,
        })
      })
      if (showList && showList.length > 0) {
        this.generateFilesUidPic()
      }
      this.$refs.msForm.clearValidate('annexList')
    },
    /**
     * 删除证书
     */
    deletionPicFile(index) {
      let fileName = this.msForm.annexList[index].fileName
      let filePath = this.msForm.annexList[index].filePath
      this.msForm.annexList.splice(index, 1)
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.picFileRef.deleteIndex(index)
      }
      let fileList = this.$refs.picFileRef.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.picFileRef.deleteIndex(j)
            fileList = this.$refs.picFileRef.showFileList || []
            continue
          }
        }
      }
    },
    commit() {
      this.$refs.msForm.validate(valid => {
        if (!valid) return

        let data = {
          rid: this.msForm.rid,
          uuid: this.msForm.uuid,
          appScenario: this.msForm.appScenario,
          businessType: this.msForm.businessType,
          merchantNo: this.$zwxSm.sm4JsEncrypt(this.msForm.merchantNo),
          payDescribe: this.msForm.payDescribe,
          /**
           * 
          businessJson: this.$zwxSm.sm4JsEncrypt(this.msForm.businessJson),
           */
          privateKeyPath: this.msForm.annexList.length > 0 ? this.msForm.annexList[0].filePath : '',
          merchantSerialNumber: this.$zwxSm.sm4JsEncrypt(this.msForm.merchantSerialNumber),
          apiV3Key: this.$zwxSm.sm4JsEncrypt(this.msForm.apiV3Key),
        }
        this.$system.postJson(this.api + '/training/platform/addOrModifyTrainingAgencyPayConfig-1', data, true, true, this.commitSuccess, this.error)
      })
    },
    commitSuccess(data) {
      if (data.type === '00') {
        this.$system.notify('成功', data.mess, 'success')
        this.close()
        this.$emit('confirm')
      } else if (data.type === '99') {
        this.$router.push({ name: 'MsError', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.$emit('loading', false)
      }
    },
    // 关闭弹框
    close() {
      this.visible = false
      this.msForm = {
        uuid: undefined,
        appScenario: 0,
        businessType: '04',
        merchantNo: '',
        payDescribe: '',
        /**
         * 
        businessJson: '',
         */
        annexList: [],
        merchantSerialNumber: '',
        apiV3Key: '',
      }
      this.$refs.msForm.resetFields()
    },
    // 打开弹框
    setShow(row) {
      this.title = row ? '商户信息编辑' : '商户信息添加'
      if (row) {
        row.annexList = []
        if (this.$zwxBase.verifyIsNotBlank(row.privateKeyPath)) {
          row.annexList.push({
            check: false,
            fileIconShow: 'ms-file-jpg-icon',
            fileName: row.privateKeyPath.split('/').pop(),
            filePath: row.privateKeyPath,
          })
        }
        this.msForm = {
          ...row,
          merchantNo: this.$zwxSm.sm4JsDecrypt(row.merchantNo),
          // businessJson: this.$zwxSm.sm4JsDecrypt(row.businessJson),
          merchantSerialNumber: this.$zwxSm.sm4JsDecrypt(row.merchantSerialNumber),
          apiV3Key: this.$zwxSm.sm4JsDecrypt(row.apiV3Key),
        }
      }
      this.visible = true
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.$emit('loading', false)
    },
  },
  mounted() {
    this.$SimpleCodeTools.getConfig(data => {
      this.systemConfig = data
    })
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  width: 580px !important;
  min-width: 580px !important;
  .ms-dialog-footer-specical {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
/deep/ .ms-form {
  .el-form-item {
    display: block !important;
  }
  .zwx-select {
    width: 100%;
    .el-input__inner {
      width: 100% !important;
    }
  }
}
.content {
  padding: 50px 48px 40px 30px;
  box-sizing: border-box;
}
</style>
