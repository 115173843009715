<template>
  <div class="hazard-distribution-index">
    <el-form class="zwx-form edit-form" key="msForm" ref="hazardDistributionIndexRef" :model="msForm" label-position="right" :rules="rules" @submit.native.prevent>
      <div class="choose-box edit-row ">
        <el-form-item label-width="170px" label="有无以下职业病危害因素：">
          <el-checkbox v-model="msForm.existsDust" border size="mini" @change="dataChange(1)" :disabled="2 == declareType && !firstAdd">粉尘因素</el-checkbox>
          <el-checkbox v-model="msForm.existsChemical" border size="mini" @change="dataChange(2)" :disabled="2 == declareType && !firstAdd">化学因素</el-checkbox>
          <el-checkbox v-model="msForm.existsPhysical" border size="mini" @change="dataChange(3)" :disabled="2 == declareType && !firstAdd">物理因素</el-checkbox>
          <el-checkbox v-model="msForm.existsRadioactivity" border size="mini" @change="dataChange(4)" :disabled="2 == declareType && !firstAdd">放射性因素</el-checkbox>
          <el-checkbox v-model="msForm.existsBiotic" border size="mini" @change="dataChange(5)" :disabled="2 == declareType && !firstAdd">生物因素</el-checkbox>
          <el-checkbox v-model="msForm.existsOther" border size="mini" @change="dataChange(6)" :disabled="2 == declareType && !firstAdd">其他因素</el-checkbox>
        </el-form-item>
      </div>
      <div class="harz-list">
        <!--粉尘-->
        <div v-if="existsDustFlag" class="harz">
          <!-- <base-headline title="粉尘因素" /> -->
          <div class="baseHeadline">粉尘因素</div>
          <div class="edit-row" style="padding-top:12px !important;">
            <el-form-item label-width="155px" label="粉尘因素接触总人数：" prop="dustContacts">
              <el-input style="width:120px !important" class="zwx-input" v-model="msForm.dustContacts" @input="$validate.nonnegativeInteger(msForm, 'dustContacts')" maxlength="8" placeholder="请输入" clearable />
            </el-form-item>
            <el-button class="zwx-button zwx-button-28" icon="el-icon-plus" @click="factorDialogShow('1', 11, '粉尘因素名称', '添加其他类粉尘', msForm.dustHazardList, 'dustHazardList')" :disabled="2 == declareType && !firstAdd">
              添加
            </el-button>
          </div>
          <div class="div-table">
            <div class="div-table-row">
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1220px)"></div>
            </div>
            <!--粉尘-->
            <template v-for="(childs, i) in msForm.dustHazardList">
              <div class="div-table-row">
                <template v-for="(child, j) in childs">
                  <div class="div-table-td-name-col flew-center" style="justify-content: flex-start">
                    {{ child.hazardsName }}
                    <img src="@/assets/images/hazard_declaration/ic_delete.png" style="width: 13px;margin: 0 3px;cursor: pointer" @click="deleteSelectedFactor(1, i, j)" v-if="0 == child.supervisionRequirement && (2 != declareType || (2 == declareType && firstAdd))" />
                  </div>
                  <div class="div-table-td-num-col flew-center">
                    <el-form-item :id="'dustHazardList' + i + j" :prop="'dustHazardList.' + i + '.' + j + '.contactNumber'" :rules="rules.contactNumber" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                      <el-input class="zwx-input" v-model.trim="child.contactNumber" style="width: 100% !important" @input="$validate.nonnegativeInteger(child, 'contactNumber')" maxlength="8" clearable />
                    </el-form-item>
                  </div>
                  <div :class="2 != j ? 'div-table-td-border' : ''" :style="i == msForm.dustHazardList.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''"></div>
                  <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 810px;"></div>
                  <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 400px;"></div>
                </template>
                <div class="div-table-td-name-col flew-center" style="width: calc(100% - 1220px)"></div>
              </div>
            </template>
          </div>
        </div>
        <!--化学因素-->
        <div v-if="existsChemicalFlag" class="harz">
          <!-- <base-headline title="化学因素" /> -->
          <div class="baseHeadline">化学因素</div>
          <div class="edit-row" style="padding-top:12px !important;">
            <el-form-item label-width="155px" label="化学因素接触总人数：" prop="chemicalContacts">
              <el-input style="width:120px !important" class="zwx-input" v-model="msForm.chemicalContacts" @input="$validate.nonnegativeInteger(msForm, 'chemicalContacts')" placeholder="请输入" clearable />
            </el-form-item>
            <el-button class="zwx-button zwx-button-28" icon="el-icon-plus" @click="factorDialogShow('2', 11, '化学因素名称', '添加其他类化学因素', msForm.chemicalHazardList, 'chemicalHazardList')" :disabled="2 == declareType && !firstAdd">
              添加
            </el-button>
          </div>
          <div class="div-table">
            <div class="div-table-row">
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1220px)"></div>
            </div>
            <template v-for="(childs, i) in msForm.chemicalHazardList">
              <div class="div-table-row">
                <template v-for="(child, j) in childs">
                  <div class="div-table-td-name-col flew-center" style="justify-content: flex-start">
                    {{ child.hazardsName }}
                    <img src="@/assets/images/hazard_declaration/ic_delete.png" style="width: 13px;margin: 0 3px;cursor: pointer" @click="deleteSelectedFactor(2, i, j)" v-if="0 == child.supervisionRequirement && (2 != declareType || (2 == declareType && firstAdd))" />
                  </div>
                  <div class="div-table-td-num-col flew-center">
                    <el-form-item :id="'chemicalHazardList' + i + j" :prop="'chemicalHazardList.' + i + '.' + j + '.contactNumber'" :rules="rules.contactNumber" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                      <el-input class="zwx-input" v-model.trim="child.contactNumber" style="width: 100% !important;" @input="$validate.nonnegativeInteger(child, 'contactNumber')" maxlength="8" clearable />
                    </el-form-item>
                  </div>
                  <div :class="2 != j ? 'div-table-td-border' : ''" :style="i == msForm.chemicalHazardList.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''"></div>
                  <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 810px;"></div>
                  <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 400px;"></div>
                </template>
                <div class="div-table-td-name-col flew-center" style="width: calc(100% - 1220px)"></div>
              </div>
            </template>
          </div>
        </div>
        <!--物理因素-->
        <div v-if="existsPhysicalFlag" class="harz">
          <!-- <base-headline title="物理因素" /> -->
          <div class="baseHeadline">物理因素</div>
          <div class="edit-row" style="padding-top:12px !important;">
            <el-form-item label-width="155px" label="物理因素接触总人数：" prop="physicalContacts">
              <el-input style="width:120px !important" class="zwx-input" v-model="msForm.physicalContacts" @input="$validate.nonnegativeInteger(msForm, 'physicalContacts')" placeholder="请输入" clearable />
            </el-form-item>
            <el-button class="zwx-button zwx-button-28" icon="el-icon-plus" @click="factorDialogShow('3', 11, '物理因素名称', '添加其他类物理因素', msForm.physicalHazardList, 'physicalHazardList')" :disabled="2 == declareType && !firstAdd">
              添加
            </el-button>
          </div>
          <div class="div-table">
            <div class="div-table-row">
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1220px)"></div>
            </div>
            <template v-for="(childs, i) in msForm.physicalHazardList">
              <div class="div-table-row">
                <template v-for="(child, j) in childs">
                  <div class="div-table-td-name-col flew-center" style="justify-content: flex-start">
                    {{ child.hazardsName }}
                    <img src="@/assets/images/hazard_declaration/ic_delete.png" style="width: 13px;margin: 0 3px;cursor: pointer" @click="deleteSelectedFactor(3, i, j)" v-if="0 == child.supervisionRequirement && (2 != declareType || (2 == declareType && firstAdd))" />
                  </div>
                  <div class="div-table-td-num-col flew-center">
                    <el-form-item :id="'physicalHazardList' + i + j" :prop="'physicalHazardList.' + i + '.' + j + '.contactNumber'" :rules="rules.contactNumber" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                      <el-input class="zwx-input" v-model.trim="child.contactNumber" style="width: 100% !important;" @input="$validate.nonnegativeInteger(child, 'contactNumber')" maxlength="8" clearable />
                    </el-form-item>
                  </div>
                  <div :class="2 != j ? 'div-table-td-border' : ''" :style="i == msForm.physicalHazardList.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''"></div>
                  <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 810px;"></div>
                  <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 400px;"></div>
                </template>
                <div class="div-table-td-name-col flew-center" style="width: calc(100% - 1220px)"></div>
              </div>
            </template>
          </div>
        </div>
        <!--放射危害因素-->
        <div v-if="existsRadioactivityFlag" class="harz">
          <!-- <base-headline title="放射危害因素" /> -->
          <div class="baseHeadline">放射危害因素</div>
          <div class="edit-row" style="padding-top:12px !important;">
            <el-form-item label-width="180px" label="放射危害因素接触总人数：" prop="radioactivityContacts">
              <el-input style="width:120px !important" class="zwx-input" v-model="msForm.radioactivityContacts" @input="$validate.nonnegativeInteger(msForm, 'radioactivityContacts')" placeholder="请输入" clearable />
            </el-form-item>
            <el-button class="zwx-button zwx-button-28" icon="el-icon-plus" @click="factorDialogShow('4', 11, '放射因素名称', '添加其他类放射因素', msForm.radioactivityHazardList, 'radioactivityHazardList')" :disabled="2 == declareType && !firstAdd">
              添加
            </el-button>
          </div>
          <div class="div-table">
            <div class="div-table-row">
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1220px)"></div>
            </div>
            <template v-for="(childs, i) in msForm.radioactivityHazardList">
              <div class="div-table-row">
                <template v-for="(child, j) in childs">
                  <div class="div-table-td-name-col flew-center" style="justify-content: flex-start">
                    {{ child.hazardsName }}
                    <img src="@/assets/images/hazard_declaration/ic_delete.png" style="width: 13px;margin: 0 3px;cursor: pointer" @click="deleteSelectedFactor(4, i, j)" v-if="0 == child.supervisionRequirement && (2 != declareType || (2 == declareType && firstAdd))" />
                  </div>
                  <div class="div-table-td-num-col flew-center">
                    <el-form-item :id="'radioactivityHazardList' + i + j" :prop="'radioactivityHazardList.' + i + '.' + j + '.contactNumber'" :rules="rules.contactNumber" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                      <el-input class="zwx-input" v-model.trim="child.contactNumber" style="width: 100% !important;" @input="$validate.nonnegativeInteger(child, 'contactNumber')" maxlength="8" clearable />
                    </el-form-item>
                  </div>
                  <div :class="2 != j ? 'div-table-td-border' : ''" :style="i == msForm.radioactivityHazardList.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''"></div>
                  <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 810px;"></div>
                  <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 400px;"></div>
                </template>
                <div class="div-table-td-name-col flew-center" style="width: calc(100% - 1220px)"></div>
              </div>
            </template>
          </div>
        </div>
        <!--生物因素-->
        <div v-if="existsBioticFlag" class="harz">
          <!-- <base-headline title="生物因素" /> -->
          <div class="baseHeadline">生物因素</div>
          <div class="edit-row" style="padding-top:12px !important;">
            <el-form-item label-width="155px" label="生物因素接触总人数：" prop="bioticContacts">
              <el-input style="width:120px !important" class="zwx-input" v-model="msForm.bioticContacts" @input="$validate.nonnegativeInteger(msForm, 'bioticContacts')" placeholder="请输入" clearable />
            </el-form-item>
            <el-button class="zwx-button zwx-button-28" icon="el-icon-plus" @click="factorDialogShow('5', 11, '生物因素名称', '添加其他类生物因素', msForm.bioticHazardList, 'bioticHazardList')" :disabled="2 == declareType && !firstAdd">
              添加
            </el-button>
          </div>
          <div class="div-table">
            <div class="div-table-row">
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1220px)"></div>
            </div>
            <template v-for="(childs, i) in msForm.bioticHazardList">
              <div class="div-table-row">
                <template v-for="(child, j) in childs">
                  <div class="div-table-td-name-col flew-center" style="justify-content: flex-start">
                    {{ child.hazardsName }}
                    <img src="@/assets/images/hazard_declaration/ic_delete.png" style="width: 13px;margin: 0 3px;cursor: pointer" @click="deleteSelectedFactor(5, i, j)" v-if="0 == child.supervisionRequirement && (2 != declareType || (2 == declareType && firstAdd))" />
                  </div>
                  <div class="div-table-td-num-col flew-center">
                    <el-form-item :id="'bioticHazardList' + i + j" :prop="'bioticHazardList.' + i + '.' + j + '.contactNumber'" :rules="rules.contactNumber" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                      <el-input class="zwx-input" v-model.trim="child.contactNumber" style="width: 100% !important;" @input="$validate.nonnegativeInteger(child, 'contactNumber')" maxlength="8" clearable />
                    </el-form-item>
                  </div>
                  <div :class="2 != j ? 'div-table-td-border' : ''" :style="i == msForm.bioticHazardList.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''"></div>
                  <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 810px;"></div>
                  <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 400px;"></div>
                </template>
                <div class="div-table-td-name-col flew-center" style="width: calc(100% - 1220px)"></div>
              </div>
            </template>
          </div>
        </div>
        <!--其他因素-->
        <div v-if="existsOtherFlag" class="harz">
          <!-- <base-headline title="其他因素" /> -->
          <base-headline title="其他因素" />
          <div class="edit-row" style="padding-top:12px !important;">
            <el-form-item label-width="155px" label="其他因素接触总人数：" prop="otherContacts">
              <el-input style="width:120px !important" class="zwx-input" v-model="msForm.otherContacts" @input="$validate.nonnegativeInteger(msForm, 'otherContacts')" placeholder="请输入" clearable />
            </el-form-item>
            <el-button class="zwx-button zwx-button-28" icon="el-icon-plus" @click="factorDialogShow('6', 11, '其他因素名称', '添加其他类其他因素', msForm.otherHazardList, 'otherHazardList')" :disabled="2 == declareType && !firstAdd">
              添加
            </el-button>
          </div>
          <div class="div-table">
            <div class="div-table-row">
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="width: 250px">接触危害因素</div>
              <div class="div-table-head-name-col flew-center">接触人数</div>
              <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1220px)"></div>
            </div>
            <template v-for="(childs, i) in msForm.otherHazardList">
              <div class="div-table-row">
                <template v-for="(child, j) in childs">
                  <div class="div-table-td-name-col flew-center" style="justify-content: flex-start">
                    {{ child.hazardsName }}
                    <img src="@/assets/images/hazard_declaration/ic_delete.png" style="width: 13px;margin: 0 3px;cursor: pointer" @click="deleteSelectedFactor(6, i, j)" v-if="0 == child.supervisionRequirement && (2 != declareType || (2 == declareType && firstAdd))" />
                  </div>
                  <div class="div-table-td-num-col flew-center">
                    <el-form-item :id="'otherHazardList' + i + j" :prop="'otherHazardList.' + i + '.' + j + '.contactNumber'" :rules="rules.contactNumber" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                      <el-input class="zwx-input" v-model.trim="child.contactNumber" style="width: 100% !important;" @input="$validate.nonnegativeInteger(child, 'contactNumber')" maxlength="8" clearable />
                    </el-form-item>
                  </div>
                  <div :class="2 != j ? 'div-table-td-border' : ''" :style="i == msForm.otherHazardList.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''"></div>
                  <div v-if="childs.length === 1 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="width: 810px;"></div>
                  <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-name-col flew-center " style="width: 400px;"></div>
                </template>
                <div class="div-table-td-name-col flew-center" style="width: calc(100% - 1220px)"></div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </el-form>
    <hazard-select-dialog ref="hazardSelectDialog" v-show="true" :selectedFactors="factorSelectDialog.factors" @submitFactor="confirmFactorDialog" :title="factorSelectDialog.title" :tableLabel="factorSelectDialog.dialogRoeName" :hazardsSort="factorSelectDialog.hazardsSort"></hazard-select-dialog>
  </div>
</template>

<script>
export default {
  name: 'HazardDistributionIndex',
  data() {
    return {
      api: this.$store.state.api,
      existsDustFlag: false, //粉尘是否显示
      existsChemicalFlag: false, // 化学物质是否显示
      existsPhysicalFlag: false, // 物理因素是否显示
      existsRadioactivityFlag: false, // 放射性因素是否显示
      existsBioticFlag: false, // 生物因素是否显示
      existsOtherFlag: false, // 其他危害因素是否显示
      msForm: {
        rid: '',
        mainId: '',
        existsDust: false, //粉尘有无
        dustContacts: '', //粉尘接触总人数
        existsChemical: false, // 化学物质_危害因素;1:有 0：无
        chemicalContacts: '', // 化学物质_接触总人数
        existsPhysical: false, // 物理因素;1:有 0：无
        physicalContacts: '', // 物理因素_接触总人数
        existsRadioactivity: false, // 放射性因素;1:有 0：无
        radioactivityContacts: '', // 放射性因素_接触总人数
        existsBiotic: false, // 生物因素;1:有 0：无
        bioticContacts: '', // 生物因素_接触总人数
        existsOther: false, //	其他因素_危害因素;1:有 0：无
        otherContacts: '', // 其他因素_接触总人数
        dustHazardList: [], // 粉尘危害因素接触集合
        chemicalHazardList: [], // 化学危害因素接触集合
        physicalHazardList: [], // 物理危害因素接触集合
        radioactivityHazardList: [], // 放射危害因素接触集合
        bioticHazardList: [], // 生物危害因素接触集合
        otherHazardList: [], // 其他危害因素接触集合
      },
      rules: {
        dustContacts: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalVictimsNum, trigger: ['blur'] },
        ],
        chemicalContacts: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalVictimsNum, trigger: ['blur'] },
        ],
        physicalContacts: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalVictimsNum, trigger: ['blur'] },
        ],
        radioactivityContacts: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalVictimsNum, trigger: ['blur'] },
        ],
        bioticContacts: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalVictimsNum, trigger: ['blur'] },
        ],
        otherContacts: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateTotalVictimsNum, trigger: ['blur'] },
        ],
        contactNumber: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateContactNumber, trigger: ['blur'] },
        ],
      },
      factorSelectDialog: {
        title: '',
        dialogRoeName: '',
        levelCodeNo: '',
        factors: [],
        currentPage: 1,
        pageSize: 18,
        total: 0,
        unShowedFactorForm: {
          hazardsName: '',
          selectedFactors: [],
        },
        factorList: [],
        jsonData: '',
        hazardsSort: '',
        listName: '',
      },
      totalVictimsNum: '', // 接害总人数（含外委
      declareType: '', //申报类型0：初次申报  1：变更申报  2：年度更新
      hazardStandardAllList: null, //必填小项
      firstAdd: false, //是否第一次申报
    }
  },
  created() {},
  mounted() {
    this.employerId = this.$route.params.employerId
    this.msForm.mainId = this.$route.params.rid
    this.getHazardDistributionByMainID()
  },
  activated() {},
  computed: {
    determineDisabled() {
      if (this.$zwxBase.verifyIsNotBlank(this.factorSelectDialog.unShowedFactorForm.selectedFactors)) {
        return false
      }
      return true
    },
  },
  methods: {
    /**
     *  选项框发生改变
     * */
    dataChange(val) {
      if (1 == val) {
        //取消选择
        if (!this.msForm.existsDust) {
          let flag = false
          let list = this.$zwxBase.arrayDeal(this.msForm.dustHazardList)
          list.forEach(item => {
            if (item.contactNumber && parseInt(item.contactNumber) > 0) {
              flag = true
            }
          })
          if (this.msForm.dustContacts && parseInt(this.msForm.dustContacts) > 0) {
            flag = true
          }
          if (flag) {
            // 填了数据才跳弹框
            this.$confirm('是否确认取消，取消后将清空该类危害因素填报内容?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' })
              .then(() => {
                this.msForm.existsDust = false
                this.msForm.dustContacts = ''
                this.msForm.dustHazardList = []
                this.existsDustFlag = false
              })
              .catch(() => {
                this.msForm.existsDust = true
                this.existsDustFlag = true
              })
          } else {
            this.msForm.existsDust = false
            this.msForm.dustContacts = ''
            this.msForm.dustHazardList = []
            this.existsDustFlag = false
          }
        } else {
          //选择
          this.existsDustFlag = true
          this.msForm.dustContacts = ''
          this.msForm.dustHazardList = []
          let hazardStandardAllList = JSON.parse(this.hazardStandardAllList)
          hazardStandardAllList.forEach(item => {
            if (1 == item.hazardsSort) {
              this.msForm.dustHazardList.push(item)
            }
          })
          this.msForm.dustHazardList = this.$zwxBase.multidimensionalArrayDeal(this.msForm.dustHazardList, 3)
        }
      }
      if (2 == val) {
        if (!this.msForm.existsChemical) {
          let flag = false
          let list = this.$zwxBase.arrayDeal(this.msForm.chemicalHazardList)
          list.forEach(item => {
            if (item.contactNumber && parseInt(item.contactNumber) > 0) {
              flag = true
            }
          })
          if (this.msForm.chemicalContacts && parseInt(this.msForm.chemicalContacts) > 0) {
            flag = true
          }
          if (flag) {
            this.$confirm('是否确认取消，取消后将清空该类危害因素填报内容?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                this.msForm.existsChemical = false
                this.msForm.chemicalContacts = ''
                this.msForm.chemicalHazardList = []
                this.existsChemicalFlag = false
              })
              .catch(() => {
                this.msForm.existsChemical = true
                this.existsChemicalFlag = true
              })
          } else {
            this.msForm.existsChemical = false
            this.msForm.chemicalContacts = ''
            this.msForm.chemicalHazardList = []
            this.existsChemicalFlag = false
          }
        } else {
          //选择
          this.existsChemicalFlag = true
          this.msForm.chemicalContacts = ''
          this.msForm.chemicalHazardList = []
          let hazardStandardAllList = JSON.parse(this.hazardStandardAllList)
          hazardStandardAllList.forEach(item => {
            if (2 == item.hazardsSort) {
              this.msForm.chemicalHazardList.push(item)
            }
          })
          this.msForm.chemicalHazardList = this.$zwxBase.multidimensionalArrayDeal(this.msForm.chemicalHazardList, 3)
        }
      }
      if (3 == val) {
        if (!this.msForm.existsPhysical) {
          let flag = false
          let list = this.$zwxBase.arrayDeal(this.msForm.physicalHazardList)
          list.forEach(item => {
            if (item.contactNumber && parseInt(item.contactNumber) > 0) {
              flag = true
            }
          })
          if (this.msForm.physicalContacts && parseInt(this.msForm.physicalContacts) > 0) {
            flag = true
          }
          if (flag) {
            this.$confirm('是否确认取消，取消后将清空该类危害因素填报内容?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                this.msForm.existsPhysical = false
                this.msForm.physicalContacts = ''
                this.msForm.physicalHazardList = []
                this.existsPhysicalFlag = false
              })
              .catch(() => {
                this.msForm.existsPhysical = true
                this.msForm.existsPhysicalFlag = true
              })
          } else {
            this.msForm.existsPhysical = false
            this.msForm.physicalContacts = ''
            this.msForm.physicalHazardList = []
            this.existsPhysicalFlag = false
          }
        } else {
          this.existsPhysicalFlag = true
          this.msForm.physicalContacts = ''
          this.msForm.physicalHazardList = []
          let hazardStandardAllList = JSON.parse(this.hazardStandardAllList)
          hazardStandardAllList.forEach(item => {
            if (3 == item.hazardsSort) {
              this.msForm.physicalHazardList.push(item)
            }
          })
          this.msForm.physicalHazardList = this.$zwxBase.multidimensionalArrayDeal(this.msForm.physicalHazardList, 3)
        }
      }
      if (4 == val) {
        if (!this.msForm.existsRadioactivity) {
          let flag = false
          let list = this.$zwxBase.arrayDeal(this.msForm.radioactivityHazardList)
          list.forEach(item => {
            if (item.contactNumber && parseInt(item.contactNumber) > 0) {
              flag = true
            }
          })
          if (this.msForm.radioactivityContacts && parseInt(this.msForm.radioactivityContacts) > 0) {
            flag = true
          }
          if (flag) {
            this.$confirm('是否确认取消，取消后将清空该类危害因素填报内容?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                this.msForm.existsRadioactivity = false
                this.msForm.radioactivityContacts = ''
                this.msForm.radioactivityHazardList = []
                this.existsRadioactivityFlag = false
              })
              .catch(() => {
                this.msForm.existsRadioactivity = true
                this.existsRadioactivityFlag = true
              })
          } else {
            this.msForm.existsRadioactivity = false
            this.msForm.radioactivityContacts = ''
            this.msForm.radioactivityHazardList = []
            this.existsRadioactivityFlag = false
          }
        } else {
          this.existsRadioactivityFlag = true
          this.msForm.radioactivityContacts = ''
          this.msForm.radioactivityHazardList = []
          let hazardStandardAllList = JSON.parse(this.hazardStandardAllList)
          hazardStandardAllList.forEach(item => {
            if (4 == item.hazardsSort) {
              this.msForm.radioactivityHazardList.push(item)
            }
          })
          this.msForm.radioactivityHazardList = this.$zwxBase.multidimensionalArrayDeal(this.msForm.radioactivityHazardList, 3)
        }
      }
      if (5 == val) {
        if (!this.msForm.existsBiotic) {
          let flag = false
          let list = this.$zwxBase.arrayDeal(this.msForm.bioticHazardList)
          list.forEach(item => {
            if (item.contactNumber && parseInt(item.contactNumber) > 0) {
              flag = true
            }
          })
          if (this.msForm.bioticContacts && parseInt(this.msForm.bioticContacts) > 0) {
            flag = true
          }
          if (flag) {
            this.$confirm('是否确认取消，取消后将清空该类危害因素填报内容?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                this.msForm.existsBiotic = false
                this.msForm.bioticContacts = ''
                this.msForm.bioticHazardList = []
                this.existsBioticFlag = false
              })
              .catch(() => {
                this.msForm.existsBiotic = true
                this.existsBioticFlag = true
              })
          } else {
            this.msForm.existsBiotic = false
            this.msForm.bioticContacts = ''
            this.msForm.bioticHazardList = []
            this.existsBioticFlag = false
          }
        } else {
          this.existsBioticFlag = true
          this.msForm.bioticContacts = ''
          this.msForm.bioticHazardList = []
          let hazardStandardAllList = JSON.parse(this.hazardStandardAllList)
          hazardStandardAllList.forEach(item => {
            if (5 == item.hazardsSort) {
              this.msForm.bioticHazardList.push(item)
            }
          })
          this.msForm.bioticHazardList = this.$zwxBase.multidimensionalArrayDeal(this.msForm.bioticHazardList, 3)
        }
      }
      if (6 == val) {
        if (!this.msForm.existsOther) {
          let flag = false
          let list = this.$zwxBase.arrayDeal(this.msForm.otherHazardList)
          list.forEach(item => {
            if (item.contactNumber && parseInt(item.contactNumber) > 0) {
              flag = true
            }
          })
          if (this.msForm.otherContacts && parseInt(this.msForm.otherContacts) > 0) {
            flag = true
          }
          if (flag) {
            this.$confirm('是否确认取消，取消后将清空该类危害因素填报内容?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' })
              .then(() => {
                this.msForm.existsOther = false
                this.msForm.otherContacts = ''
                this.msForm.otherHazardList = []
                this.existsOtherFlag = false
              })
              .catch(() => {
                this.msForm.existsOther = true
                this.existsOtherFlag = true
              })
          } else {
            this.msForm.existsOther = false
            this.msForm.otherContacts = ''
            this.msForm.otherHazardList = []
            this.existsOtherFlag = false
          }
        } else {
          this.existsOtherFlag = true
          this.msForm.otherContacts = ''
          this.msForm.otherHazardList = []
          let hazardStandardAllList = JSON.parse(this.hazardStandardAllList)
          hazardStandardAllList.forEach(item => {
            if (6 == item.hazardsSort) {
              this.msForm.otherHazardList.push(item)
            }
          })
          this.msForm.otherHazardList = this.$zwxBase.multidimensionalArrayDeal(this.msForm.otherHazardList, 3)
        }
      }
    },
    /**
     * 具体危害因素接触人数校验
     */
    validateTotalVictimsNum(rule, value, callback) {
      let listName = rule.field
      // 正整数的正则表达式
      let pattern = /^[1-9][0-9]*$/
      if (!pattern.test(value)) {
        callback('大于0')
      }
      if (parseInt(value) > parseInt(this.totalVictimsNum)) {
        callback(new Error('小于等于接害总人数（含外委)（' + parseInt(this.totalVictimsNum) + ')'))
      }
      if (listName === 'dustContacts') {
        let list = this.$zwxBase.arrayDeal(this.msForm.dustHazardList)
        list.forEach(item => {
          if (parseInt(item.contactNumber) > parseInt(value)) {
            callback(new Error('大于等于小类接害人数（' + parseInt(item.contactNumber) + '）'))
          }
        })
      }
      if (listName === 'chemicalContacts') {
        let list = this.$zwxBase.arrayDeal(this.msForm.chemicalHazardList)
        list.forEach(item => {
          if (parseInt(item.contactNumber) > parseInt(value)) {
            callback(new Error('大于等于小类接害人数（' + parseInt(item.contactNumber) + '）'))
          }
        })
      }
      if (listName === 'physicalContacts') {
        let list = this.$zwxBase.arrayDeal(this.msForm.physicalHazardList)
        list.forEach(item => {
          if (parseInt(item.contactNumber) > parseInt(value)) {
            callback(new Error('大于等于小类接害人数（' + parseInt(item.contactNumber) + '）'))
          }
        })
      }
      if (listName === 'radioactivityContacts') {
        let list = this.$zwxBase.arrayDeal(this.msForm.radioactivityHazardList)
        list.forEach(item => {
          if (parseInt(item.contactNumber) > parseInt(value)) {
            callback(new Error('大于等于小类接害人数（' + parseInt(item.contactNumber) + '）'))
          }
        })
      }
      if (listName === 'bioticContacts') {
        let list = this.$zwxBase.arrayDeal(this.msForm.bioticHazardList)
        list.forEach(item => {
          if (parseInt(item.contactNumber) > parseInt(value)) {
            callback(new Error('大于等于小类接害人数（' + parseInt(item.contactNumber) + '）'))
          }
        })
      }
      if (listName === 'otherContacts') {
        let list = this.$zwxBase.arrayDeal(this.msForm.otherHazardList)
        list.forEach(item => {
          if (parseInt(item.contactNumber) > parseInt(value)) {
            callback(new Error('大于等于小类接害人数（' + parseInt(item.contactNumber) + '）'))
          }
        })
      }
      callback()
    },
    /**
     * 具体危害因素接触人数校验
     */
    validateContactNumber(rule, value, callback) {
      let listName = rule.field.split('.')[0]
      let i = rule.field.split('.')[1]
      let j = rule.field.split('.')[2]
      if (listName === 'dustHazardList') {
        let factorObj = this.msForm.dustHazardList[i][j]
        // 非负数的正则表达式
        let pattern = /^\d+$/
        if (!pattern.test(value)) {
          factorObj.contactNumber = ''
          callback()
        }
        if (this.msForm.existsDust === true && this.$zwxBase.verifyIsNotBlank(factorObj.contactNumber) && this.$zwxBase.verifyIsNotBlank(this.msForm.dustContacts) && parseInt(factorObj.contactNumber) > parseInt(this.msForm.dustContacts)) {
          callback(new Error('小于等于粉尘因素接触总人数（' + parseInt(this.msForm.dustContacts) + '）'))
        }
      }
      if (listName === 'chemicalHazardList') {
        let factorObj = this.msForm.chemicalHazardList[i][j]
        // 非负数的正则表达式
        let pattern = /^\d+$/
        if (!pattern.test(value)) {
          factorObj.contactNumber = ''
          callback()
        }
        if (this.msForm.existsChemical === true && this.$zwxBase.verifyIsNotBlank(factorObj.contactNumber) && this.$zwxBase.verifyIsNotBlank(this.msForm.chemicalContacts) && parseInt(factorObj.contactNumber) > parseInt(this.msForm.chemicalContacts)) {
          callback(new Error('小于等于化学因素接触总人数（' + parseInt(this.msForm.chemicalContacts) + '）'))
        }
      }
      if (listName === 'physicalHazardList') {
        let factorObj = this.msForm.physicalHazardList[i][j]
        // 非负数的正则表达式
        let pattern = /^\d+$/
        if (!pattern.test(value)) {
          factorObj.contactNumber = ''
          callback()
        }
        if (this.msForm.existsPhysical === true && this.$zwxBase.verifyIsNotBlank(factorObj.contactNumber) && this.$zwxBase.verifyIsNotBlank(this.msForm.physicalContacts) && parseInt(factorObj.contactNumber) > parseInt(this.msForm.physicalContacts)) {
          callback(new Error('小于等于物理因素接触总人数（' + parseInt(this.msForm.physicalContacts) + '）'))
        }
      }
      if (listName === 'radioactivityHazardList') {
        let factorObj = this.msForm.radioactivityHazardList[i][j]
        // 非负数的正则表达式
        let pattern = /^\d+$/
        if (!pattern.test(value)) {
          factorObj.contactNumber = ''
          callback()
        }
        if (this.msForm.existsRadioactivity === true && this.$zwxBase.verifyIsNotBlank(factorObj.contactNumber) && this.$zwxBase.verifyIsNotBlank(this.msForm.radioactivityContacts) && parseInt(factorObj.contactNumber) > parseInt(this.msForm.radioactivityContacts)) {
          callback(new Error('小于等于放射危害因素接触总人数（' + parseInt(this.msForm.radioactivityContacts) + '）'))
        }
      }
      if (listName === 'bioticHazardList') {
        let factorObj = this.msForm.bioticHazardList[i][j]
        // 非负数的正则表达式
        let pattern = /^\d+$/
        if (!pattern.test(value)) {
          factorObj.contactNumber = ''
          callback()
        }
        if (this.msForm.existsBiotic === true && this.$zwxBase.verifyIsNotBlank(factorObj.contactNumber) && this.$zwxBase.verifyIsNotBlank(this.msForm.bioticContacts) && parseInt(factorObj.contactNumber) > parseInt(this.msForm.bioticContacts)) {
          callback(new Error('小于等于生物因素接触总人数（' + parseInt(this.msForm.bioticContacts) + '）'))
        }
      }
      if (listName === 'otherHazardList') {
        let factorObj = this.msForm.otherHazardList[i][j]
        // 非负数的正则表达式
        let pattern = /^\d+$/
        if (!pattern.test(value)) {
          factorObj.contactNumber = ''
          callback()
        }
        if (this.msForm.existsOther === true && this.$zwxBase.verifyIsNotBlank(factorObj.contactNumber) && this.$zwxBase.verifyIsNotBlank(this.msForm.otherContacts) && parseInt(factorObj.contactNumber) > parseInt(this.msForm.otherContacts)) {
          callback(new Error('小于等于其他因素接触总人数（' + parseInt(this.msForm.otherContacts) + '）'))
        }
      }
      callback()
    },
    /**
     * 查询接触危害因素
     */
    getHazardDistributionByMainID() {
      this.$parent.loading = true
      let fkByMainId = {
        rid: this.msForm.mainId,
      }
      let data = {
        fkByMainId: fkByMainId,
        employerId: this.employerId,
      }
      this.$system.postJson(
        this.api + '/oh/declaration/getHazardDistributionByMainID-1',
        data,
        true,
        true,
        data => {
          this.$parent.loading = false
          if (data.type === '00') {
            this.declareType = data.declareType
            this.msForm.dustHazardList = data.dustHazardList || []
            this.msForm.chemicalHazardList = data.chemicalHazardList || []
            this.msForm.physicalHazardList = data.physicalHazardList || []
            this.msForm.radioactivityHazardList = data.radioactivityHazardList || []
            this.msForm.bioticHazardList = data.bioticHazardList || []
            this.msForm.otherHazardList = data.otherHazardList || []
            this.hazardStandardAllList = JSON.stringify(data.hazardStandardAllList || [])
            this.firstAdd = data.firstAdd
            if (data.hazardDistribution) {
              this.totalVictimsNum = data.hazardDistribution.totalVictimsNum
              this.msForm.rid = data.hazardDistribution.rid ? data.hazardDistribution.rid : ''
              this.msForm.existsDust = data.hazardDistribution.existsDust ? data.hazardDistribution.existsDust : false
              this.msForm.dustContacts = data.hazardDistribution.dustContacts ? data.hazardDistribution.dustContacts : ''
              this.msForm.existsChemical = data.hazardDistribution.existsChemical ? data.hazardDistribution.existsChemical : false
              this.msForm.chemicalContacts = data.hazardDistribution.chemicalContacts ? data.hazardDistribution.chemicalContacts : ''
              this.msForm.existsPhysical = data.hazardDistribution.existsPhysical ? data.hazardDistribution.existsPhysical : false
              this.msForm.physicalContacts = data.hazardDistribution.physicalContacts ? data.hazardDistribution.physicalContacts : ''
              this.msForm.existsRadioactivity = data.hazardDistribution.existsRadioactivity ? data.hazardDistribution.existsRadioactivity : false
              this.msForm.radioactivityContacts = data.hazardDistribution.radioactivityContacts ? data.hazardDistribution.radioactivityContacts : ''
              this.msForm.existsBiotic = data.hazardDistribution.existsBiotic ? data.hazardDistribution.existsBiotic : false
              this.msForm.bioticContacts = data.hazardDistribution.bioticContacts ? data.hazardDistribution.bioticContacts : ''
              this.msForm.existsOther = data.hazardDistribution.existsOther ? data.hazardDistribution.existsOther : false
              this.msForm.otherContacts = data.hazardDistribution.otherContacts ? data.hazardDistribution.otherContacts : ''
              this.existsDustFlag = this.msForm.existsDust
              this.existsChemicalFlag = this.msForm.existsChemical
              this.existsPhysicalFlag = this.msForm.existsPhysical
              this.existsRadioactivityFlag = this.msForm.existsRadioactivity
              this.existsBioticFlag = this.msForm.existsBiotic
              this.existsOtherFlag = this.msForm.existsOther
            } else {
              this.msForm.existsDust = this.msForm.dustHazardList.length > 0
              this.msForm.existsChemical = this.msForm.chemicalHazardList.length > 0
              this.msForm.existsPhysical = this.msForm.physicalHazardList.length > 0
              this.msForm.existsRadioactivity = this.msForm.radioactivityHazardList.length > 0
              this.msForm.existsBiotic = this.msForm.bioticHazardList.length > 0
              this.msForm.existsOther = this.msForm.otherHazardList.length > 0
              this.existsDustFlag = this.msForm.existsDust
              this.existsChemicalFlag = this.msForm.existsChemical
              this.existsPhysicalFlag = this.msForm.existsPhysical
              this.existsRadioactivityFlag = this.msForm.existsRadioactivity
              this.existsBioticFlag = this.msForm.existsBiotic
              this.existsOtherFlag = this.msForm.existsOther
            }

            if (this.$zwxBase.verifyIsNotBlank(this.msForm.dustHazardList)) {
              this.msForm.dustHazardList = this.$zwxBase.multidimensionalArrayDeal(this.msForm.dustHazardList, 3)
            }
            if (this.$zwxBase.verifyIsNotBlank(this.msForm.chemicalHazardList)) {
              this.msForm.chemicalHazardList = this.$zwxBase.multidimensionalArrayDeal(this.msForm.chemicalHazardList, 3)
            }
            if (this.$zwxBase.verifyIsNotBlank(this.msForm.physicalHazardList)) {
              this.msForm.physicalHazardList = this.$zwxBase.multidimensionalArrayDeal(this.msForm.physicalHazardList, 3)
            }
            if (this.$zwxBase.verifyIsNotBlank(this.msForm.radioactivityHazardList)) {
              this.msForm.radioactivityHazardList = this.$zwxBase.multidimensionalArrayDeal(this.msForm.radioactivityHazardList, 3)
            }
            if (this.$zwxBase.verifyIsNotBlank(this.msForm.bioticHazardList)) {
              this.msForm.bioticHazardList = this.$zwxBase.multidimensionalArrayDeal(this.msForm.bioticHazardList, 3)
            }
            if (this.$zwxBase.verifyIsNotBlank(this.msForm.otherHazardList)) {
              this.msForm.otherHazardList = this.$zwxBase.multidimensionalArrayDeal(this.msForm.otherHazardList, 3)
            }
            this.$parent.jsonData = JSON.stringify(this.msForm)
            this.$parent.saveDisabled = false
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        data => {
          this.$parent.loading = false
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 自定义危害因素弹框
     * */
    factorDialogShow(hazardsSort, levelCodeNo, dialogRoeName, title, list, listName) {
      this.factorSelectDialog.dialogRoeName = dialogRoeName
      this.factorSelectDialog.levelCodeNo = levelCodeNo
      this.factorSelectDialog.hazardsSort = hazardsSort
      this.factorSelectDialog.listName = listName
      this.factorSelectDialog.title = title
      let newList = JSON.parse(JSON.stringify(list))
      newList = this.$zwxBase.arrayDeal(newList)
      let selectFactors = []
      newList.forEach(item => {
        let data = {
          codeNo: item.hazardsCode,
        }
        selectFactors.push(data)
      })
      this.factorSelectDialog.factors = selectFactors
      this.$nextTick(() => {
        this.$refs.hazardSelectDialog.factorDialogShow()
      })
    },
    /**
     * 危害因素查询 delete
     * */
    factorDialogSearchInit(currentPage) {
      let selectedFactorList = []
      if (this.$zwxBase.verifyIsNotBlank(this.factorSelectDialog.factors)) {
        selectedFactorList = this.$zwxBase.arrayDeal(this.factorSelectDialog.factors)
      }
      let data = {
        currentPage: currentPage,
        hazardsName: this.factorSelectDialog.unShowedFactorForm.hazardsName,
        hazardsSort: this.factorSelectDialog.hazardsSort,
        selectedFactorList: selectedFactorList,
      }
      this.$system.postJson(
        this.api + '/oh/repository/getHazardsFactorsByDeclareEditHazard-1',
        data,
        true,
        true,
        data => {
          if (data.type == '00') {
            this.factorSelectDialog.factorList = data.tableList ? data.tableList : []
            let selectedFactors = this.factorSelectDialog.unShowedFactorForm.selectedFactors
            if (this.$zwxBase.verifyIsNotBlank(selectedFactors)) {
              for (let i in selectedFactors) {
                for (let j in this.factorSelectDialog.factorList) {
                  if (this.$zwxBase.verifyIsNotBlank(this.factorSelectDialog.factorList[j].factor0) && selectedFactors[i].codeNo === this.factorSelectDialog.factorList[j].factor0.codeNo) {
                    this.factorSelectDialog.factorList[j].checked0 = true
                  }
                  if (this.$zwxBase.verifyIsNotBlank(this.factorSelectDialog.factorList[j].factor1) && selectedFactors[i].codeNo === this.factorSelectDialog.factorList[j].factor1.codeNo) {
                    this.factorSelectDialog.factorList[j].checked1 = true
                  }
                }
              }
            }
            this.factorSelectDialog.pageSize = data.pageSize ? data.pageSize : 18
            this.factorSelectDialog.total = data.total
            this.$refs.factorSelectDialogRef.show(true)
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 保存/更新职业病危害因素接触情况
     */
    saveOrUpdateHazardDistribution(opType) {
      if (0 == opType || 2 == opType) {
        //不校验
        this.saveHazardDistribution(opType)
      } else {
        // 至少填一项
        if (!this.msForm.existsDust && !this.msForm.existsChemical && !this.msForm.existsPhysical && !this.msForm.existsRadioactivity && !this.msForm.existsBiotic && !this.msForm.existsOther) {
          this.$notify.info({ title: '提示', message: '危害因素至少填一项' })
          return
        }
        // 校验
        this.$refs.hazardDistributionIndexRef.validate(valid => {
          if (valid) {
            if (!this.$parent.saveDisabled) {
              this.$parent.saveDisabled = true
              this.saveHazardDistribution(opType)
            }
          }
        })
      }
    },
    saveHazardDistribution(opType) {
      let flag1 = false
      let hazardDistributionDetailList = []
      if (this.msForm.dustHazardList.length > 0) {
        let list = this.$zwxBase.arrayDeal(this.msForm.dustHazardList)
        list.forEach(item => {
          if (parseInt(item.contactNumber) > 0) {
            flag1 = true
          }
          hazardDistributionDetailList.push(item)
        })
      }
      if (1 == opType && this.msForm.existsDust && !flag1) {
        this.$parent.saveDisabled = false
        this.$notify.info({ title: '提示', message: '粉尘因素明细不能全为0' })
        return false
      }
      let flag2 = false
      if (this.msForm.chemicalHazardList.length > 0) {
        let list2 = this.$zwxBase.arrayDeal(this.msForm.chemicalHazardList)
        list2.forEach(item => {
          if (parseInt(item.contactNumber) > 0) {
            flag2 = true
          }
          hazardDistributionDetailList.push(item)
        })
      }
      if (1 == opType && this.msForm.existsChemical && !flag2) {
        this.$parent.saveDisabled = false
        this.$notify.info({ title: '提示', message: '化学因素明细不能全为0' })
        return false
      }
      let flag3 = false
      if (this.msForm.physicalHazardList.length > 0) {
        let list3 = this.$zwxBase.arrayDeal(this.msForm.physicalHazardList)
        list3.forEach(item => {
          if (parseInt(item.contactNumber) > 0) {
            flag3 = true
          }
          hazardDistributionDetailList.push(item)
        })
      }
      if (1 == opType && this.msForm.existsPhysical && !flag3) {
        this.$parent.saveDisabled = false
        this.$notify.info({ title: '提示', message: '物理因素明细不能全为0' })
        return false
      }
      let flag4 = false
      if (this.msForm.radioactivityHazardList.length > 0) {
        let list4 = this.$zwxBase.arrayDeal(this.msForm.radioactivityHazardList)
        list4.forEach(item => {
          if (parseInt(item.contactNumber) > 0) {
            flag4 = true
          }
          hazardDistributionDetailList.push(item)
        })
      }
      if (1 == opType && this.msForm.existsRadioactivity && !flag4) {
        this.$parent.saveDisabled = false
        this.$notify.info({ title: '提示', message: '放射性因素明细不能全为0' })
        return false
      }
      let flag5 = false
      if (this.msForm.bioticHazardList.length > 0) {
        let list5 = this.$zwxBase.arrayDeal(this.msForm.bioticHazardList)
        list5.forEach(item => {
          if (parseInt(item.contactNumber) > 0) {
            flag5 = true
          }
          hazardDistributionDetailList.push(item)
        })
      }
      if (1 == opType && this.msForm.existsBiotic && !flag5) {
        this.$notify.info({ title: '提示', message: '生物因素明细不能全为0' })
        this.$parent.saveDisabled = false
        return false
      }
      let flag6 = false
      if (this.msForm.otherHazardList.length > 0) {
        let list6 = this.$zwxBase.arrayDeal(this.msForm.otherHazardList)
        list6.forEach(item => {
          if (parseInt(item.contactNumber) > 0) {
            flag6 = true
          }
          hazardDistributionDetailList.push(item)
        })
      }
      if (1 == opType && this.msForm.existsOther && !flag6) {
        this.$notify.info({ title: '提示', message: '其他因素明细不能全为0' })
        this.$parent.saveDisabled = false
        return false
      }
      // 校验危害因素大类数量
      // （1）危害因素大类只有1类时，危害因素接触情况填写的该类接触总人数应等于基本信息中接害总人数（含外委）
      // （2）危害因素大类存在多类时，危害因素接触情况各类接触总人数应等于大于基本信息中接害总人数（含外委）
      let existSum = 0
      existSum = this.msForm.existsDust ? existSum + 1 : existSum
      existSum = this.msForm.existsChemical ? existSum + 1 : existSum
      existSum = this.msForm.existsPhysical ? existSum + 1 : existSum
      existSum = this.msForm.existsRadioactivity ? existSum + 1 : existSum
      existSum = this.msForm.existsBiotic ? existSum + 1 : existSum
      existSum = this.msForm.existsOther ? existSum + 1 : existSum
      let numberPeopleAll = 0
      numberPeopleAll = parseInt(this.msForm.dustContacts) > 0 ? numberPeopleAll + parseInt(this.msForm.dustContacts) : numberPeopleAll
      numberPeopleAll = parseInt(this.msForm.chemicalContacts) > 0 ? numberPeopleAll + parseInt(this.msForm.chemicalContacts) : numberPeopleAll
      numberPeopleAll = parseInt(this.msForm.physicalContacts) > 0 ? numberPeopleAll + parseInt(this.msForm.physicalContacts) : numberPeopleAll
      numberPeopleAll = parseInt(this.msForm.radioactivityContacts) > 0 ? numberPeopleAll + parseInt(this.msForm.radioactivityContacts) : numberPeopleAll
      numberPeopleAll = parseInt(this.msForm.bioticContacts) > 0 ? numberPeopleAll + parseInt(this.msForm.bioticContacts) : numberPeopleAll
      numberPeopleAll = parseInt(this.msForm.otherContacts) > 0 ? numberPeopleAll + parseInt(this.msForm.otherContacts) : numberPeopleAll
      if (existSum == 1 && numberPeopleAll != this.totalVictimsNum) {
        this.$notify.info({ title: '提示', message: '单个大类危害因素接触触总人数应等于基本信息中接害总人数（含外委）' })
        this.$parent.saveDisabled = false
        return false
      }
      if (existSum > 1 && numberPeopleAll < this.totalVictimsNum) {
        this.$notify.info({ title: '提示', message: '多项大类危害因素接触触总人数之和应大于等于基本信息中接害总人数（含外委）' })
        this.$parent.saveDisabled = false
        return false
      }

      this.$parent.loading = true
      let fkByMainId = {
        rid: this.msForm.mainId,
      }
      let data = {
        hazardDistributionDetailList: hazardDistributionDetailList,
        rid: this.msForm.rid,
        fkByMainId: fkByMainId,
        totalVictimsNum: this.totalVictimsNum,
        existsDust: this.msForm.existsDust ? 1 : 0,
        dustContacts: this.msForm.dustContacts,
        existsChemical: this.msForm.existsChemical ? 1 : 0,
        chemicalContacts: this.msForm.chemicalContacts,
        existsPhysical: this.msForm.existsPhysical ? 1 : 0,
        physicalContacts: this.msForm.physicalContacts,
        existsRadioactivity: this.msForm.existsRadioactivity ? 1 : 0,
        radioactivityContacts: this.msForm.radioactivityContacts,
        existsBiotic: this.msForm.existsBiotic ? 1 : 0,
        bioticContacts: this.msForm.bioticContacts,
        existsOther: this.msForm.existsOther ? 1 : 0,
        otherContacts: this.msForm.otherContacts,
      }
      data.opType = opType
      this.$system.postJson(
        this.api + '/oh/declaration/saveOrUpdateHazardDistribution-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.msForm.rid = data.rid
            //点击下一步
            if (opType === 1) {
              this.$parent.step++
              this.$parent.informantStep = this.$parent.step
            } else {
              this.$parent.jsonData = JSON.stringify(this.msForm)
            }
            if (2 == opType) {
              // 暂存保存数据后，按钮解禁
              this.$parent.saveDisabled = false
            }
            if (0 == opType) {
              this.$parent.step--
            }
          } else if (data.type === '99') {
            this.$parent.saveDisabled = false
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$parent.saveDisabled = false
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$parent.loading = false
        },
        this.error
      )
    },
    /**
     * 删除自定义危害因素
     * */
    deleteSelectedFactor(type, i, j) {
      let index = i * 3 + j
      if (type === 1) {
        let list = this.$zwxBase.arrayDeal(this.msForm.dustHazardList)
        list.splice(index, 1)
        this.msForm.dustHazardList = this.$zwxBase.multidimensionalArrayDeal(list, 3)
      } else if (type === 2) {
        let list = this.$zwxBase.arrayDeal(this.msForm.chemicalHazardList)
        list.splice(index, 1)
        this.msForm.chemicalHazardList = this.$zwxBase.multidimensionalArrayDeal(list, 3)
      } else if (type === 3) {
        let list = this.$zwxBase.arrayDeal(this.msForm.physicalHazardList)
        list.splice(index, 1)
        this.msForm.physicalHazardList = this.$zwxBase.multidimensionalArrayDeal(list, 3)
      } else if (type === 4) {
        let list = this.$zwxBase.arrayDeal(this.msForm.radioactivityHazardList)
        list.splice(index, 1)
        this.msForm.radioactivityHazardList = this.$zwxBase.multidimensionalArrayDeal(list, 3)
      } else if (type === 5) {
        let list = this.$zwxBase.arrayDeal(this.msForm.bioticHazardList)
        list.splice(index, 1)
        this.msForm.bioticHazardList = this.$zwxBase.multidimensionalArrayDeal(list, 3)
      } else if (type === 6) {
        let list = this.$zwxBase.arrayDeal(this.msForm.otherHazardList)
        list.splice(index, 1)
        this.msForm.otherHazardList = this.$zwxBase.multidimensionalArrayDeal(list, 3)
      }
    },
    /**
     * 危害因素选择弹框提交
     * */
    confirmFactorDialog(list) {
      let selectFactors = JSON.parse(JSON.stringify(this.msForm[this.factorSelectDialog.listName]))
      selectFactors = this.$zwxBase.arrayDeal(selectFactors) //selectFactors.concat(this.factorSelectDialog.factors)
      list.forEach(item => {
        let data = {
          hazardsCode: item.codeNo,
          supervisionRequirement: false,
          hazardsName: item.hazardsName,
          hazardsSort: item.hazardsSort,
          contactNumber: '',
        }
        selectFactors.push(data)
      })
      selectFactors = this.$zwxBase.multidimensionalArrayDeal(selectFactors, 3)
      this.$set(this.msForm, this.factorSelectDialog.listName, selectFactors)
    },
  },
}
</script>

<style lang="less" scoped>
.baseHeadline {
  // margin-top: 12px;
  padding-left: 16px;
  font-size: 14px;
  color: rgb(106, 116, 154);
}
.harz {
  margin-top: 20px;
}
.harz-list {
  .harz:first-child {
    margin-top: 0px !important;
    /deep/ .zwx-head-title {
      border-top: none !important;
    }
  }
}

.zwx-input /deep/.el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
}
.hazard-distribution-index {
  .flew-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .choose-box {
    // 未选中样式
    /deep/ .el-checkbox__inner {
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background: rgba(255, 255, 255, 1);
      border: 1.14px solid rgba(65, 182, 120, 1);
    }
    /deep/ .el-checkbox {
      color: rgba(31, 45, 61, 1);
      font-size: 14px;
      background: rgba(244, 255, 244, 1);
      border: 1px solid rgba(199, 231, 195, 1);
      margin: 0 0 0 12px;
    }
    /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
      line-height: 22px !important;
      color: rgba(31, 45, 61, 1);
    }
    /deep/.el-checkbox.is-bordered.el-checkbox--mini {
      line-height: 22px !important;
    }
    /deep/.el-checkbox__input {
      line-height: 16px !important;
    }
    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: rgba(65, 182, 120, 1);
      border: 1.14px solid rgba(65, 182, 120, 1);
    }
    /deep/ .el-checkbox__input .el-checkbox__inner::after {
      // 对号
      border: 2px solid #fff;
      // 不覆盖下面的 会 导致对号变形
      box-sizing: content-box;
      content: '';
      border-left: 0;
      border-top: 0;
      height: 7px;
      left: 5px;
      position: absolute;
      top: 2px;
      width: 3px;
      transform: rotate(45deg) scaleY(1);
    }
  }
  .div-table {
    //border-bottom: 1px solid #d5d9e0;
  }

  .div-table-row {
    display: flex;
    width: 100%;
    // border-right: 1px solid #e1e5f4;
  }

  .div-table-head-name-col {
    width: 150px;
    height: 38px;
    border-right: 1px solid #e1e5f4;
    border-bottom: 1px solid #e1e5f4;
    border-top: 1px solid #e1e5f4;
    background-color: #f6f8fb;
  }
  .div-table-head-border {
    width: 10px;
    border-top: 1px solid #e1e5f4;
    border-right: 1px solid #e1e5f4;
    background-color: #f6f8fb;
  }
  .div-table-td-border {
    width: 10px;
    border-right: 1px solid #e1e5f4;
    background-color: #f6f8fb;
  }
  .div-table-td-name-col {
    width: 250px;
    height: 45px;
    border-right: 1px solid #e1e5f4;
    border-bottom: 1px solid #e1e5f4;
    padding: 8.5px 14px;
  }

  .div-table-td-num-col {
    width: 150px;
    height: 45px;
    border-right: 1px solid #e1e5f4;
    border-bottom: 1px solid #e1e5f4;
    padding: 5px 14px;
  }
}
</style>
