<template>
  <div class="HealthistachmentIndex">
    <div class="tags">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div>
      <span>确认完成后打印申请表：</span>
      <!-- <el-button class="zwx-button zwx-button-32" icon="el-icon-printer" @click="prints">
        打印
      </el-button> -->
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-printer" @click="prints">打印</el-button>
      <print-health ref="printHealth" :msDeclarationPrintViewForm="msDeclarationPrintViewForm"></print-health>
    </div>
    <div style="margin-top:19px;">
      盖章并扫描申报表
    </div>
    <div class="attachment">
      <div>
        <span>上传文件：</span>
        <file-upload ref="msPositive" :disabled="!($zwxBase.verifyIsBlank(msFormEdit.annexList) || msFormEdit.annexList.length < 5)" @showFileList="clickPositive" @success="clickPositiveSuccess" @error="clickError()" accept=".png,.jpeg,.jpg,.pdf,.JPG,.JPEG,.PNG,.PDF" :size="5 * 1024 * 1024" sizeInfo="5M">
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2" :disabled="!($zwxBase.verifyIsBlank(msFormEdit.annexList) || msFormEdit.annexList.length < 5)">
            上传
          </el-button>
        </file-upload>
        <span class="attachment-tip">支持pdf、jpg、png格式文件，不大于5M，最多上传5份附件</span>
      </div>
      <div style="color:red;">{{ warningtext }}</div>
      <div class="show-file" v-if="msFormEdit.annexList.length > 0">
        <div v-for="(item, index) in msFormEdit.annexList" :key="index" @mouseenter="mouseenter(index)" @mouseleave="mouseleave(index)">
          <span>
            <a style="color:#194DFF !important;margin-left:12px;cursor:pointer;" @click="openFilePreview(msFormEdit.annexList, index)">
              <i :class="item.fileIconShow" style="color:#316CF5!important"></i>
              {{ item.fileName }}
            </a>
            <span class="el-upload-list__item check" :style="item.check ? 'margin-left:18px;display:none;' : 'margin-left:18px;cursor:pointer'"><i class="el-icon-upload-success el-icon-circle-check"></i></span>
            <span class="check" style="line-height:1.8" :style="item.check ? 'margin-left:18px;cursor:pointer' : 'margin-left:18px;display:none;'"><i class="el-icon-close" @click="deletionPositive(index)"></i></span>
            <!-- <el-divider direction="vertical" v-if="msFormEdit.annexList.length > 1 && item !== msFormEdit.annexList[msFormEdit.annexList.length - 1]"></el-divider> -->
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PrintHealth from './components/PrintHealth.vue'
export default {
  name: 'HealthistachmentIndex',
  components: { PrintHealth },
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      msForm: {},
      msHazardDistributionForm: {},
      msDetectionForm: {},
      msSupervisionForm: {},
      msDeclarationPrintViewForm: {},
      warningtext: '',
      msFormEdit: {
        annexList: [],
      },
    }
  },
  mounted() {
    this.$parent.saveDisabled = false
    this.search()
  },
  methods: {
    prints() {
      let data = { rid: this.$route.params.rid }
      this.$parent.loading = true
      this.$system.get(
        this.api + '/oh/declaration/getDeclarationPrintInit-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            try {
              this.msForm = data.tdOccupationalHazardDeclarationExp
              let hazardDistributionDetail = this.msForm.tdHazardDistributionDetailExpList
              this.msHazardDistributionForm.dustList = []
              this.msHazardDistributionForm.chemicalList = []
              this.msHazardDistributionForm.physicalList = []
              this.msHazardDistributionForm.radioactivityList = []
              this.msHazardDistributionForm.bioticList = []
              this.msHazardDistributionForm.otherList = []
              this.msDetectionForm.bioticOtherList = []
              this.msSupervisionForm.bioticOtherList = []
              if (hazardDistributionDetail != null && hazardDistributionDetail.length > 0) {
                for (var k in hazardDistributionDetail) {
                  if (hazardDistributionDetail[k].hazardsSort === 1) {
                    this.msHazardDistributionForm.dustList.push(hazardDistributionDetail[k])
                  }
                  if (hazardDistributionDetail[k].hazardsSort === 2) {
                    this.msHazardDistributionForm.chemicalList.push(hazardDistributionDetail[k])
                  }
                  if (hazardDistributionDetail[k].hazardsSort === 3) {
                    this.msHazardDistributionForm.physicalList.push(hazardDistributionDetail[k])
                  }
                  if (hazardDistributionDetail[k].hazardsSort === 4) {
                    this.msHazardDistributionForm.radioactivityList.push(hazardDistributionDetail[k])
                  }
                  if (hazardDistributionDetail[k].hazardsSort === 5) {
                    this.msHazardDistributionForm.bioticList.push(hazardDistributionDetail[k])
                    if (this.msHazardDistributionForm.existsBiotic) {
                      var hazard = {}
                      Object.assign(hazard, hazardDistributionDetail[k])
                      hazard.index = this.msDetectionForm.bioticOtherList.length - 1
                      this.msDetectionForm.bioticOtherList.push(hazard)
                      hazard.index = this.msSupervisionForm.bioticOtherList.length - 1
                      this.msSupervisionForm.bioticOtherList.push(hazard)
                    }
                  }
                  if (hazardDistributionDetail[k].hazardsSort === 6) {
                    this.msHazardDistributionForm.otherList.push(hazardDistributionDetail[k])
                    if (this.msHazardDistributionForm.existsOther) {
                      var hazard = {}
                      Object.assign(hazard, hazardDistributionDetail[k])
                      hazard.index = this.msDetectionForm.bioticOtherList.length - 1
                      this.msDetectionForm.bioticOtherList.push(hazard)
                      hazard.index = this.msSupervisionForm.bioticOtherList.length - 1
                      this.msSupervisionForm.bioticOtherList.push(hazard)
                    }
                  }
                }
              }
              this.msDeclarationPrintForListPrint()
            } catch (error) {
              console.log(error, 'error')
            }
          } else if (data.type === '99') {
            // this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        this.error
      )
    },
    msDeclarationPrintForListPrint() {
      this.dealBioticOtherHazard()
      var printData = {}
      printData.declarationExp = {
        rid: this.msForm.rid,
        totalTraineeRate: this.msForm.totalTraineeRate,
        unitName: this.msForm.unitName,
        enterpriseScale: this.msForm.enterpriseScale,
        industryCateCode: this.msForm.industryCode,
        ifClassifiedUnit: this.msForm.ifClassifiedUnit,
        workAddr: this.msForm.workplaceAddr,
        regAddr: this.msForm.registerAddr,
        legalPerson: this.msForm.legalPerson,
        registerZone: this.msForm.registerZone,
        legalPersonPhone: this.msForm.legalPersonPhone,
        creditCode: this.msForm.creditCode,
        declareStatus: this.msForm.declareStatus,
        declareDate: this.msForm.declareDate ? this.$system.formatDate(this.msForm.declareDate, 'yyyy-MM-DD') : '/',
        declareYear: this.msForm.declareYear,
        declareType: this.msForm.declareType,
        economicName: this.msForm.economicName,
        industryCateName: this.msForm.industryName,
        reasonNo: this.msForm.reasonCode,
        reasonName: this.msForm.reasonCodeName,
        remark: this.msForm.otherReason,
        linkManager: this.msForm.linkManager,
        linkPhone: this.msForm.linkManagerContact,
        fillMan: this.msForm.informant,
        fillPhone: this.msForm.informantContact,
        empNum: this.msForm.totalStaffNum,
        registerNum: this.msForm.registerNum,
        outSourcingNum: this.msForm.outsourcingNum,
        externalNum: this.msForm.totalSubcontractingStaff,
        victimsNum: this.msForm.totalVictimsNum,
        occupationalDiseasesNum: this.msForm.cumulativePatients,
        existsLeaderTrain: this.msForm.principalTraining,
        existsManagersTrain: this.msForm.managerTraining,
        trainSum: this.msForm.totalTrainee,
        ifWarProduct: false,
        distributionExp: this.msForm.tdHazardDistributionExp || {},
        detectionExp: this.msForm.tdHazardDetectionExp || {},
        supervisionExp: this.msForm.tdHealthSupervisionExp || {},
      }
      printData.dustDetailList = this.msHazardDistributionForm.dustList || []
      printData.chemicalDetailList = this.msHazardDistributionForm.chemicalList || []
      printData.physicalDetailList = this.msHazardDistributionForm.physicalList || []
      printData.radioActivityDetailList = this.msHazardDistributionForm.radioactivityList || []
      printData.bioticDetailList = this.msHazardDistributionForm.bioticList || []
      printData.otherDetailList = this.msHazardDistributionForm.otherList || []
      printData.majorProductList = this.msForm.tdMajorProductExpList || []
      // printData.annexExpList = this.msAnnexForm.msAnnexList || []
      if (this.$zwxBase.verifyIsNotBlank(this.msForm.tdOhdApprovalRecordExp.approveDate)) {
        printData.approvalRecordExp = { approveDate: this.$system.formatDate(this.msForm.tdOhdApprovalRecordExp.approveDate, 'yyyy-MM-DD') }
      } else {
        printData.approvalRecordExp = {}
      }
      var detectOrgan = this.msForm.tdHazardDetectionRecordExpList
      var superOrgan = this.msForm.tdHealthSupervisionRecordExpList
      var delectionCode = ''
      var delectionOrganName = ''
      for (var i in detectOrgan) {
        delectionCode += '，' + detectOrgan[i].reportNo
        delectionOrganName += '，' + detectOrgan[i].unitName
      }
      printData.declarationExp.delectionCode = delectionCode.substring(1)
      printData.declarationExp.delectionOrganName = delectionOrganName.substring(1)
      var supervisionCode = ''
      var supervisionOrganName = ''
      for (var i in superOrgan) {
        supervisionCode += '，' + superOrgan[i].reportNo
        supervisionOrganName += '，' + superOrgan[i].unitName
      }
      printData.declarationExp.supervisionCode = supervisionCode.substring(1)
      printData.declarationExp.supervisionOrganName = supervisionOrganName.substring(1)
      this.msDeclarationDetailPrintInit(printData)
      this.$parent.loading = false
    },
    dealBioticOtherHazard() {
      var bioticOtherList = this.msDetectionForm.bioticOtherList
      var bioOtherMap = new Map()
      for (var i in bioticOtherList) {
        bioOtherMap.set(bioticOtherList[i].codeNo, bioticOtherList[i])
      }
      for (var i in this.msHazardDistributionForm.bioticList) {
        if (bioOtherMap.get(this.msHazardDistributionForm.bioticList[i].codeNo) !== undefined) {
          this.msHazardDistributionForm.bioticList[i].checkPoints = bioOtherMap.get(this.msHazardDistributionForm.bioticList[i].codeNo).checkPoints
          this.msHazardDistributionForm.bioticList[i].overproofPoints = bioOtherMap.get(this.msHazardDistributionForm.bioticList[i].codeNo).overproofPoints
          this.msHazardDistributionForm.bioticList[i].peNum = bioOtherMap.get(this.msHazardDistributionForm.bioticList[i].codeNo).peNum
        }
      }
      for (var i in this.msHazardDistributionForm.otherList) {
        if (bioOtherMap.get(this.msHazardDistributionForm.otherList[i].codeNo) !== undefined) {
          this.msHazardDistributionForm.otherList[i].checkPoints = bioOtherMap.get(this.msHazardDistributionForm.otherList[i].codeNo).checkPoints
          this.msHazardDistributionForm.otherList[i].overproofPoints = bioOtherMap.get(this.msHazardDistributionForm.otherList[i].codeNo).overproofPoints
          this.msHazardDistributionForm.otherList[i].peNum = bioOtherMap.get(this.msHazardDistributionForm.otherList[i].codeNo).peNum
        }
      }
    },
    msDeclarationDetailPrintInit(body) {
      this.msDeclarationPrintViewForm.declarationExp = body.declarationExp || {}
      this.msDeclarationPrintViewForm.distributionExp = body.declarationExp.distributionExp || {}
      this.msDeclarationPrintViewForm.detectionExp = body.declarationExp.detectionExp || {}
      this.msDeclarationPrintViewForm.supervisionExp = body.declarationExp.supervisionExp || {}
      this.msDeclarationPrintViewForm.dustDetailList = body.dustDetailList || []
      this.msDeclarationPrintViewForm.chemicalDetailList = body.chemicalDetailList || []
      this.msDeclarationPrintViewForm.physicalDetailList = body.physicalDetailList || []
      this.msDeclarationPrintViewForm.radioActivityDetailList = body.radioActivityDetailList || []
      this.msDeclarationPrintViewForm.bioticDetailList = body.bioticDetailList || []
      this.msDeclarationPrintViewForm.otherDetailList = body.otherDetailList || []
      this.msDeclarationPrintViewForm.majorProductList = body.majorProductList || []
      this.msDeclarationPrintViewForm.annexExpList = body.annexExpList || []
      this.msDeclarationPrintViewForm.approvalRecordExp = body.approvalRecordExp || {}
      this.$refs.printHealth.assignment(this.msDeclarationPrintViewForm)
      this.$nextTick(() => {
        this.$refs.printHealth.printApplyTable()
      })
    },
    search() {
      this.$parent.loading = true
      let data = { fkByMainId: { rid: this.$route.params.rid } }
      this.$system.postJson(
        this.api + '/oh/declaration/getHazardDeclarationAnnexByMainID-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$parent.loading = false
            this.msFormEdit.annexList = data.tdHazardDeclarationAnnexExpList.map(item => {
              item.fileIconShow = this.dealfilePrefix(item.fileName)
              item.check = false
              return item
            })
            this.$parent.jsonData = JSON.stringify(this.msFormEdit.annexList)
          } else if (data.type === '99') {
            this.$parent.saveDisabled = false
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$parent.saveDisabled = false
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$parent.loading = false
        },
        this.error
      )
    },
    // 提交数据
    saveOrUpdateHealthSupervision(opType) {
      if (opType == 0) {
        this.$parent.step--
      }
      if (this.msFormEdit.annexList.length == 0) {
        this.warningtext = '请上传附件'
        this.$parent.saveDisabled = false
        return
      }
      this.warningtext = ''
      this.$parent.loading = true
      let data = { hazardDeclarationAnnexExpList: this.msFormEdit.annexList, fkByMainId: { rid: this.$route.params.rid }, opType: opType == 2 ? 0 : opType }
      this.$system.postJson(
        this.api + '/oh/declaration/saveOrUpdateHazardDeclarationAnnex-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$parent.loading = false
            this.$system.notify('成功', data.mess, 'success')
            if (opType == 2) {
              this.$parent.saveDisabled = false
              this.search()
            }
            if (opType == 1) {
              this.$router.go(-1)
            }
          } else if (data.type === '99') {
            this.$parent.saveDisabled = false
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$parent.saveDisabled = false
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$parent.loading = false
        },
        this.error
      )
    },
    // 附件上传部分
    clickPositive(showList) {
      this.showList = showList
      showList.forEach(item => {
        let flag = this.msFormEdit.annexList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.msFormEdit.annexList.push({
          fileName: item,
          fileIconShow: this.dealfilePrefix(item),
          check: false,
          fileSuffix: item.substring(item.lastIndexOf('.')),
        })
      })
      this.generateFilesUid()
    },
    //文件的UID
    generateFilesUid() {
      this.$parent.loading = true
      let data = {
        //去除文件路径中模块
        folder: '/health_attachment',
      }
      this.$system.get(this.api + '/systematic/tools/getAnnexUploadUid-1', data, true, true, data => {
        if (data.type === '00') {
          this.$refs.msPositive.submit(data.annexUploadUid)
          this.$emit('loading', false)
        } else {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      })
    },
    clickPositiveSuccess(fileList) {
      this.warningtext = ''
      let fileExpList = this.msFormEdit.annexList
      if (this.$zwxBase.verifyIsNotBlank(fileExpList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in fileExpList) {
          for (let j in fileList) {
            if (fileExpList[i].fileName === fileList[j].fileName) {
              fileExpList[i].filePath = fileList[j].filePath
            }
          }
        }
      }
      this.msFormEdit.annexList = fileExpList
      this.$parent.loading = false
      this.$forceUpdate()
    },
    mouseenter(index) {
      this.msFormEdit.annexList[index].check = true
      this.$forceUpdate()
    },
    mouseleave(index) {
      this.msFormEdit.annexList[index].check = false
      this.$forceUpdate()
    },
    // 删除文件
    deletionPositive(index) {
      let fileName = this.msFormEdit.annexList[index].fileName
      this.msFormEdit.annexList.splice(index, 1)
      this.$refs.msPositive.deleteIndex(index)
      let fileList = this.$refs.msPositive.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.msPositive.deleteIndex(j)
            fileList = this.$refs.msPositive.showFileList || []
            continue
          }
        }
      }
    },
    clickError() {},
    /**
     * 文件类型图标
     */
    dealfilePrefix(file) {
      let prefix = file.substring(file.lastIndexOf('.'))
      let fileIconShow = ''
      if (prefix === '.pdf' || prefix === '.PDF') {
        fileIconShow = 'colorful chis-icon-colorful-pdf'
      } else if (prefix === '.doc' || prefix === '.docx' || prefix === '.DOC' || prefix === '.DOCX') {
        fileIconShow = 'colorful chis-icon-colorful-word'
      } else if (prefix === '.png' || prefix === '.jpeg' || prefix === '.jpg' || prefix === '.JPG' || prefix === '.JPEG' || prefix === '.PNG') {
        fileIconShow = 'colorful chis-icon-colorful-jpg'
      } else if (prefix === '.xlsx' || prefix === '.xls') {
        fileIconShow = 'colorful chis-icon-colorful-excel'
      }
      return fileIconShow
    },
    /**
     * 多个文件预览
     */
    openFilePreview(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
  },
}
</script>
<style lang="less" scoped>
.HealthistachmentIndex {
  padding: 20px 14px;
  padding-left: 30px;
  font-size: 14px;
  // font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
  font-weight: 400;
  color: #000000 !important;
  position: relative;
  .tags {
    position: absolute;
    top: 30px;
    left: 20px;
    height: 94px;
    border-left: 2px solid rgb(125, 178, 148);
    div {
      position: relative;
      left: -6px;
      height: 10px;
      width: 10px;
      background: rgb(192, 254, 199);
      border-radius: 50% 50%;
      border: 1px solid rgb(125, 178, 148);
      &:nth-child(2) {
        top: 32px;
      }
      &:nth-child(3) {
        top: 65px;
      }
    }
  }
  .attachment {
    padding-top: 20px;
    &-tip {
      padding-left: 20px;
      font-size: 12px;
      // font-family: HarmonyOS Sans SC, HarmonyOS Sans SC-Regular;
      font-weight: 400;
      text-align: justify;
      color: #595959;
    }
  }
  .show-file {
    margin-top: 10px;
    padding-left: 55px;
  }
}
</style>
