<template>
  <div class="health-supervision-detail-index">
    <el-form class="zwx-form edit-form" key="msForm" ref="healthSupervisionDetailIndexRef" :model="msForm" :rules="rules" label-position="right" @submit.native.prevent>
      <div style="max-width: 1232px; padding: 10px 0 5px 20px !important;">
        <el-form-item label-width="196px" label="本年度职业健康检查开展情况：" prop="projectNature">
          <el-radio-group class="zwx-radio-group" v-model="msForm.existsSupervision" @change="existsSupervisionChange" :disabled="radioDisabled">
            <el-radio class="zwx-radio" :label="true">是</el-radio>
            <el-radio class="zwx-radio" :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div v-if="isShow">
        <!-- 检测机构 -->
        <base-headline title="检查机构情况" class="headline no-bottom">
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addList">添加</el-button>
        </base-headline>
        <div>
          <!-- 列表展示 -->
          <el-table ref="tableMsForm" class="zwx-table" style="width: 100%;" row-key="rid" :data="msForm.healthSupervisionRecordList" border stripe>
            <el-table-column prop="unitName" label="检查机构名称" width="350" header-align="center" align="center">
              <template slot-scope="scope">
                <el-form-item :prop="'healthSupervisionRecordList.' + scope.$index + '.unitName'" :rules="rules.unitName">
                  <detection-organ-select class="organ-select" style="width: 315px!important;" :ref="'healthSupervisionRef_' + scope.$index" requestUrl="/oh/repository/findTechnicalServiceAgencyList-1" codeType="2011" @change="healthSupervisionOrganSelectChange($event, scope.$index)" v-model="scope.row.unitName" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="检查总结报告编号" width="220" header-align="center" align="center">
              <template slot-scope="scope">
                <el-form-item :prop="'healthSupervisionRecordList.' + scope.$index + '.reportNo'" :rules="rules.reportNo">
                  <el-input class="zwx-input" v-model="scope.row.reportNo" placeholder="请输入" clearable maxlength="50" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="社会信用代码" width="230" header-align="center" align="center">
              <template slot-scope="scope">
                <el-form-item :prop="'healthSupervisionRecordList.' + scope.$index + '.creditCode'" :rules="rules.creditCode">
                  <el-input class="zwx-input" v-model="scope.row.creditCode" placeholder="请输入" maxlength="50" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="联系电话" width="200" header-align="center" align="center">
              <template slot-scope="scope">
                <el-form-item :prop="'healthSupervisionRecordList.' + scope.$index + '.contactInfo'" :rules="rules.contactInfo">
                  <el-input class="zwx-input" v-model="scope.row.contactInfo" placeholder="请输入" clearable maxlength="20" />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作" header-align="left" align="left">
              <template slot-scope="scope">
                <!-- @click="deletion1(scope.$index)" -->
                <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deletion(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- 检查情况 -->
        <base-headline title="检查情况" class="headline no-top" />
        <div class="harz-list">
          <!--粉尘-->
          <div v-if="msForm.dustHazardList.length > 0" class="harz">
            <div class="flex-row" style="margin: 12px 16px;align-items:center">
              <span>粉尘因素</span>
              <div class="flex-row stages-css" style="align-items:center" @click="itemSelect(1)">
                <span>
                  <img v-show="msForm.existsDust" src="../../../../assets/images/hazard_declaration/ic_select_1.svg" alt="" />
                  <img v-show="!msForm.existsDust" src="../../../../assets/images/hazard_declaration/ic_no_select.svg" alt="" />
                </span>
                <span style="margin-left:8px">检查</span>
              </div>
              <div style="height:36px">
                <el-form-item label="体检总人数：" label-width="96px" prop="dustNumber" :rules="msForm.existsDust ? rules.dustNumber : rules.noRequired">
                  <el-input class="zwx-input" v-model="msForm.dustNumber" placeholder="请输入" @input="$validate.nonnegativeInteger(msForm, 'dustNumber')" clearable maxlength="50" :disabled="!msForm.existsDust" />
                </el-form-item>
              </div>
            </div>
            <div class="div-table">
              <div class="div-table-row">
                <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
                <div class="div-table-head-name-col flew-center">体检人数</div>
                <div class="div-table-head-border"></div>
                <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
                <div class="div-table-head-name-col flew-center">体检人数</div>
                <div class="div-table-head-border"></div>
                <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
                <div class="div-table-head-name-col flew-center">体检人数</div>
                <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1508px)"></div>
              </div>
              <template v-for="(childs, i) in msForm.dustHazardList">
                <div class="div-table-row" :key="i">
                  <template v-for="(child, j) in childs">
                    <div class="div-table-td-name-col flew-center" style="justify-content: flex-start;min-width: 250px" :key="'hazardsName' + i + j">
                      {{ child.hazardsName }}
                    </div>
                    <div class="div-table-td-num-col flew-center" :key="'peNumber' + i + j">
                      <el-form-item :id="'dustHazardList.' + i + j" :prop="'dustHazardList.' + i + '.' + j + '.peNumber'" :rules="msForm.existsDust ? rules.peNumber : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                        <el-input class="zwx-input" v-model.trim="child.peNumber" style="width: 100% !important" @input="$validate.nonnegativeInteger(child, 'peNumber')" maxlength="8" clearable :disabled="!msForm.existsDust" />
                      </el-form-item>
                    </div>
                    <div class="div-table-td-border" :style="j == childs.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''" :key="j"></div>
                    <div v-if="childs.length === 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                    <div v-if="childs.length === 1" class="div-table-td-num-col flew-center"></div>
                    <div v-if="childs.length === 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                    <div v-if="childs.length === 1" class="div-table-td-num-col flew-center"></div>
                    <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                    <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-num-col flew-center"></div>
                  </template>
                  <div class="div-table-td-name-col flew-center" style="width: calc(100% - 1508px)"></div>
                </div>
              </template>
            </div>
          </div>
          <!--化学-->
          <div v-if="msForm.chemicalHazardList.length > 0" class="harz">
            <div class="flex-row" style="margin: 12px 16px;align-items:center">
              <span>化学因素</span>
              <div class="flex-row stages-css" style="align-items:center" @click="msForm.existsChemical = !msForm.existsChemical">
                <span>
                  <img v-show="msForm.existsChemical" src="../../../../assets/images/hazard_declaration/ic_select_1.svg" alt="" />
                  <img v-show="!msForm.existsChemical" src="../../../../assets/images/hazard_declaration/ic_no_select.svg" alt="" />
                </span>
                <span style="margin-left:8px">检查</span>
              </div>
              <div style="height:36px">
                <el-form-item label="体检总人数：" label-width="96px" prop="chemicalNumber" :rules="msForm.existsChemical ? rules.chemicalNumber : rules.noRequired">
                  <el-input class="zwx-input" v-model="msForm.chemicalNumber" @input="$validate.nonnegativeInteger(msForm, 'chemicalNumber')" placeholder="请输入" clearable maxlength="50" :disabled="!msForm.existsChemical" />
                </el-form-item>
              </div>
            </div>
            <div class="div-table">
              <div class="div-table-row">
                <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
                <div class="div-table-head-name-col flew-center">体检人数</div>
                <div class="div-table-head-border"></div>
                <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
                <div class="div-table-head-name-col flew-center">体检人数</div>
                <div class="div-table-head-border"></div>
                <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
                <div class="div-table-head-name-col flew-center">体检人数</div>
                <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1508px)"></div>
              </div>
              <template v-for="(childs, i) in msForm.chemicalHazardList">
                <div class="div-table-row" :key="i">
                  <template v-for="(child, j) in childs">
                    <div class="div-table-td-name-col flew-center" style="justify-content: flex-start;min-width: 250px" :key="'hazardsName' + i + j">
                      {{ child.hazardsName }}
                    </div>
                    <div class="div-table-td-num-col flew-center" :key="'peNumber' + i + j">
                      <el-form-item :id="'chemicalHazardList.' + i + j" :prop="'chemicalHazardList.' + i + '.' + j + '.peNumber'" :rules="msForm.existsChemical ? rules.peNumber : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                        <el-input class="zwx-input" v-model.trim="child.peNumber" @input="$validate.nonnegativeInteger(child, 'peNumber')" style="width: 100% !important" placeholder="" maxlength="8" clearable :disabled="!msForm.existsChemical" />
                      </el-form-item>
                    </div>
                    <div class="div-table-td-border" :style="j == childs.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''" :key="j"></div>
                    <div v-if="childs.length === 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                    <div v-if="childs.length === 1" class="div-table-td-num-col flew-center"></div>
                    <div v-if="childs.length === 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                    <div v-if="childs.length === 1" class="div-table-td-num-col flew-center"></div>
                    <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                    <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-num-col flew-center"></div>
                  </template>
                  <div class="div-table-td-name-col flew-center" style="width: calc(100% - 1508px)"></div>
                </div>
              </template>
            </div>
          </div>
          <!--物理-->
          <div v-if="msForm.physicalHazardList.length > 0" class="harz">
            <div class="flex-row" style="margin: 12px 16px;align-items:center">
              <span>物理因素</span>
              <div class="flex-row stages-css" style="align-items:center" @click="msForm.existsPhysical = !msForm.existsPhysical">
                <span>
                  <img v-show="msForm.existsPhysical" src="../../../../assets/images/hazard_declaration/ic_select_1.svg" alt="" />
                  <img v-show="!msForm.existsPhysical" src="../../../../assets/images/hazard_declaration/ic_no_select.svg" alt="" />
                </span>
                <span style="margin-left:8px">检查</span>
              </div>
              <div style="height:36px">
                <el-form-item label="体检总人数：" label-width="96px" prop="physicalNumber" :rules="msForm.existsPhysical ? rules.physicalNumber : rules.noRequired">
                  <el-input class="zwx-input" v-model="msForm.physicalNumber" @input="$validate.nonnegativeInteger(msForm, 'physicalNumber')" placeholder="请输入" clearable maxlength="50" :disabled="!msForm.existsPhysical" />
                </el-form-item>
              </div>
            </div>
            <div class="div-table">
              <div class="div-table-row">
                <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
                <div class="div-table-head-name-col flew-center">体检人数</div>
                <div class="div-table-head-border"></div>
                <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
                <div class="div-table-head-name-col flew-center">体检人数</div>
                <div class="div-table-head-border"></div>
                <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
                <div class="div-table-head-name-col flew-center">体检人数</div>
                <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1508px)"></div>
              </div>
              <template v-for="(childs, i) in msForm.physicalHazardList">
                <div class="div-table-row" :key="i">
                  <template v-for="(child, j) in childs">
                    <div class="div-table-td-name-col flew-center" style="justify-content: flex-start;min-width: 250px" :key="'hazardsName' + i + j">
                      {{ child.hazardsName }}
                    </div>
                    <div class="div-table-td-num-col flew-center" :key="'peNumber' + i + j">
                      <el-form-item :id="'physicalHazardList.' + i + j" :prop="'physicalHazardList.' + i + '.' + j + '.peNumber'" :rules="msForm.existsPhysical ? rules.peNumber : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                        <el-input class="zwx-input" v-model.trim="child.peNumber" style="width: 100% !important" @input="$validate.nonnegativeInteger(child, 'peNumber')" maxlength="8" clearable :disabled="!msForm.existsPhysical" />
                      </el-form-item>
                    </div>
                    <div class="div-table-td-border" :style="j == childs.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''" :key="j"></div>
                    <div v-if="childs.length === 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                    <div v-if="childs.length === 1" class="div-table-td-num-col flew-center"></div>
                    <div v-if="childs.length === 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                    <div v-if="childs.length === 1" class="div-table-td-num-col flew-center"></div>
                    <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                    <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-num-col flew-center"></div>
                  </template>
                  <div class="div-table-td-name-col flew-center" style="width: calc(100% - 1508px)"></div>
                </div>
              </template>
            </div>
          </div>
          <!--放射危害-->
          <div v-if="msForm.radioactivityHazardList.length > 0" class="harz">
            <div class="flex-row" style="margin: 12px 16px;align-items:center">
              <span>放射危害因素</span>
              <div class="flex-row stages-css" style="align-items:center" @click="msForm.existsRadioactivity = !msForm.existsRadioactivity">
                <span>
                  <img v-show="msForm.existsRadioactivity" src="../../../../assets/images/hazard_declaration/ic_select_1.svg" alt="" />
                  <img v-show="!msForm.existsRadioactivity" src="../../../../assets/images/hazard_declaration/ic_no_select.svg" alt="" />
                </span>
                <span style="margin-left:8px">检查</span>
              </div>
              <div style="height:36px">
                <el-form-item label="体检总人数：" label-width="96px" prop="radioactivityNumber" :rules="msForm.existsRadioactivity ? rules.radioactivityNumber : rules.noRequired">
                  <el-input class="zwx-input" v-model="msForm.radioactivityNumber" placeholder="请输入" @input="$validate.nonnegativeInteger(msForm, 'radioactivityNumber')" clearable maxlength="50" :disabled="!msForm.existsRadioactivity" />
                </el-form-item>
              </div>
            </div>
            <div class="div-table">
              <div class="div-table-row">
                <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
                <div class="div-table-head-name-col flew-center">体检人数</div>
                <div class="div-table-head-border"></div>
                <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
                <div class="div-table-head-name-col flew-center">体检人数</div>
                <div class="div-table-head-border"></div>
                <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
                <div class="div-table-head-name-col flew-center">体检人数</div>
                <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1508px)"></div>
              </div>
              <template v-for="(childs, i) in msForm.radioactivityHazardList">
                <div class="div-table-row" :key="i">
                  <template v-for="(child, j) in childs">
                    <div class="div-table-td-name-col flew-center" style="justify-content: flex-start;min-width: 250px" :key="'hazardsName' + i + j">
                      {{ child.hazardsName }}
                    </div>
                    <div class="div-table-td-num-col flew-center" :key="'peNumber' + i + j">
                      <el-form-item :id="'radioactivityHazardList.' + i + j" :prop="'radioactivityHazardList.' + i + '.' + j + '.peNumber'" :rules="msForm.existsRadioactivity ? rules.peNumber : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                        <el-input class="zwx-input" v-model.trim="child.peNumber" style="width: 100% !important" @input="$validate.nonnegativeInteger(child, 'peNumber')" maxlength="8" clearable :disabled="!msForm.existsRadioactivity" />
                      </el-form-item>
                    </div>
                    <div class="div-table-td-border" :style="j == childs.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''" :key="j"></div>
                    <div v-if="childs.length === 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                    <div v-if="childs.length === 1" class="div-table-td-num-col flew-center"></div>
                    <div v-if="childs.length === 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                    <div v-if="childs.length === 1" class="div-table-td-num-col flew-center"></div>
                    <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                    <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-num-col flew-center"></div>
                  </template>
                  <div class="div-table-td-name-col flew-center" style="width: calc(100% - 1508px)"></div>
                </div>
              </template>
            </div>
          </div>
          <!--生物-->
          <!-- <div v-if="msForm.bioticHazardList.length > 0">
          <div class="flex-row" style="margin: 12px 16px;align-items:center">
            <span>生物因素</span>
            <div class="flex-row stages-css" style="align-items:center" @click="msForm.existsBiotic = !msForm.existsBiotic">
              <span>
                <img v-show="msForm.existsBiotic" src="../../../assets/images/hazard_declaration/ic_select_1.svg" alt="" />
                <img v-show="!msForm.existsBiotic" src="../../../assets/images/hazard_declaration/ic_no_select.svg" alt="" />
              </span>
              <span style="margin-left:8px">检查</span>
            </div>
            <div style="height:36px">
              <el-form-item label="体检总人数：" label-width="96px" prop="bioticNumber" :rules="msForm.existsBiotic ? rules.bioticNumber : rules.noRequired">
                <el-input class="zwx-input" v-model="msForm.bioticNumber" placeholder="请输入" clearable maxlength="50" :disabled="!msForm.existsBiotic" />
              </el-form-item>
            </div>
          </div>
          <div class="div-table">
            <div class="div-table-row">
              <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
              <div class="div-table-head-name-col flew-center">体检人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
              <div class="div-table-head-name-col flew-center">体检人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
              <div class="div-table-head-name-col flew-center">体检人数</div>
              <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1508px)"></div>
            </div>
            <template v-for="(childs, i) in msForm.bioticHazardList">
              <div class="div-table-row" :key="i">
                <template v-for="(child, j) in childs">
                  <div class="div-table-td-name-col flew-center" style="justify-content: flex-start;min-width: 250px" :key="'hazardsName' + i + j">
                    {{ child.hazardsName }}
                  </div>
                  <div class="div-table-td-num-col flew-center" :key="'peNumber' + i + j">
                    <el-form-item :id="'bioticHazardList.' + i + j" :prop="'bioticHazardList.' + i + '.' + j + '.peNumber'" :rules="msForm.existsBiotic ? rules.peNumber : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                      <el-input class="zwx-input" v-model.trim="child.peNumber" style="width: 100% !important" placeholder="" maxlength="8" clearable :disabled="!msForm.existsBiotic" />
                    </el-form-item>
                  </div>
                  <div class="div-table-td-border" :style="j == childs.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''" :key="j"></div>
                  <div v-if="childs.length === 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                  <div v-if="childs.length === 1" class="div-table-td-num-col flew-center"></div>
                  <div v-if="childs.length === 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                  <div v-if="childs.length === 1" class="div-table-td-num-col flew-center"></div>
                  <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                  <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-num-col flew-center"></div>
                </template>
                <div class="div-table-td-name-col flew-center" style="width: calc(100% - 1508px)"></div>
              </div>
            </template>
          </div>
        </div> -->
          <!--其他-->
          <!-- <div v-if="msForm.otherHazardList.length > 0">
          <div class="flex-row" style="margin: 12px 16px;align-items:center">
            <span>其他因素</span>
            <div class="flex-row stages-css" style="align-items:center" @click="msForm.existsOther = !msForm.existsOther">
              <span>
                <img v-show="msForm.existsOther" src="../../../assets/images/hazard_declaration/ic_select_1.svg" alt="" />
                <img v-show="!msForm.existsOther" src="../../../assets/images/hazard_declaration/ic_no_select.svg" alt="" />
              </span>
              <span style="margin-left:8px">检查</span>
            </div>
            <div style="height:36px">
              <el-form-item label="体检总人数：" label-width="96px" prop="otherNumber" :rules="msForm.existsOther ? rules.otherNumber : rules.noRequired">
                <el-input class="zwx-input" v-model="msForm.otherNumber" placeholder="请输入" clearable maxlength="50" :disabled="!msForm.existsOther" />
              </el-form-item>
            </div>
          </div>
          <div class="div-table">
            <div class="div-table-row">
              <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
              <div class="div-table-head-name-col flew-center">体检人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
              <div class="div-table-head-name-col flew-center">体检人数</div>
              <div class="div-table-head-border"></div>
              <div class="div-table-head-name-col flew-center" style="min-width: 250px">检查危害因素</div>
              <div class="div-table-head-name-col flew-center">体检人数</div>
              <div class="div-table-head-name-col flew-center" style="width: calc(100% - 1508px)"></div>
            </div>
            <template v-for="(childs, i) in msForm.otherHazardList">
              <div class="div-table-row" :key="i">
                <template v-for="(child, j) in childs">
                  <div class="div-table-td-name-col flew-center" style="justify-content: flex-start;min-width: 250px" :key="'hazardsName' + i + j">
                    {{ child.hazardsName }}
                  </div>
                  <div class="div-table-td-num-col flew-center" :key="'peNumber' + i + j">
                    <el-form-item :id="'otherHazardList.' + i + j" :prop="'otherHazardList.' + i + '.' + j + '.peNumber'" :rules="msForm.existsOther ? rules.peNumber : rules.noRequired" style="margin-bottom: 0 !important;margin-right: 0 !important;">
                      <el-input class="zwx-input" v-model.trim="child.peNumber" style="width: 100% !important" placeholder="" maxlength="8" clearable :disabled="!msForm.existsOther" />
                    </el-form-item>
                  </div>
                  <div class="div-table-td-border" :style="j == childs.length - 1 ? { borderBottom: '1px solid #e1e5f4' } : ''" :key="j"></div>
                  <div v-if="childs.length === 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                  <div v-if="childs.length === 1" class="div-table-td-num-col flew-center"></div>
                  <div v-if="childs.length === 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                  <div v-if="childs.length === 1" class="div-table-td-num-col flew-center"></div>
                  <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-name-col flew-center" style="min-width: 250px;"></div>
                  <div v-if="childs.length === 2 && j === childs.length - 1" class="div-table-td-num-col flew-center"></div>
                </template>
                <div class="div-table-td-name-col flew-center" style="width: calc(100% - 1508px)"></div>
              </div>
            </template>
          </div>
        </div> -->
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'HealthSupervisionDetailIndex',
  data() {
    return {
      api: this.$store.state.api,
      view: true,
      employerUuid: '',
      isShow: false,
      // 出现校验时为true   控制离下方的margin的样式存在
      validatePeNumberShow: false,
      hazardDistribution: {},
      msForm: {
        rid: '',
        mainId: '',
        existsSupervision: true,
        existsDust: true,
        existsChemical: true,
        existsPhysical: true,
        existsRadioactivity: true,
        existsBiotic: true,
        existsOther: true,
        dustNumber: '',
        chemicalNumber: '',
        physicalNumber: '',
        radioactivityNumber: '',
        bioticNumber: '',
        otherNumber: '',
        healthSupervisionRecordList: [],
        dustHazardList: [], //粉尘因素
        chemicalHazardList: [], //化学因素
        physicalHazardList: [], //物理因素
        radioactivityHazardList: [], //放射因素
        bioticHazardList: [], //生物因素
        otherHazardList: [], //其他因素
      },
      tdHazardDistribution: {
        dustContacts: '', //粉尘_接触总人数
        chemicalContacts: '', //化学物质_接触总人数
        physicalContacts: '', //物理因素_接触总人数
        radioactivityContacts: '', //放射性因素_接触总人数
        bioticContacts: '', //生物因素_接触总人数
        otherContacts: '', //其他因素_接触总人数
      },
      organizationList: [], // 检查机构列表
      rules: {
        unitName: [
          { required: true, message: '请搜索选择有社会信用代码的机构', trigger: ['change', 'blur'] },
          { validator: this.validatePass, trigger: ['change', 'change', 'blur'] },
        ],
        reportNo: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateReportNo, trigger: ['change', 'blur'] },
        ],
        creditCode: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateCreditCode, trigger: ['blur', 'change'] },
        ],
        contactInfo: [{ required: true, validator: this.$validate.mbPeLdline, message: '请输入正确的联系电话', trigger: ['change', 'blur'] }],
        dustNumber: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateDustNumber, trigger: ['change', 'blur'] },
        ],
        chemicalNumber: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateChemicalNumber, trigger: ['change', 'blur'] },
        ],
        physicalNumber: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validatePhysicalNumber, trigger: ['change', 'blur'] },
        ],
        radioactivityNumber: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateRadioactivityNumber, trigger: ['change', 'blur'] },
        ],
        bioticNumber: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateBioticNumber, trigger: ['change', 'blur'] },
        ],
        otherNumber: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validateOtherNumber, trigger: ['change', 'blur'] },
        ],
        peNumber: [
          { required: true, message: '请输入', trigger: ['change', 'blur'] },
          { validator: this.validatePeNumber, trigger: ['change', 'blur'] },
        ],
        noRequired: [{ required: false, message: '请输入', trigger: ['change', 'blur'] }],
      },
      radioDisabled: false, //没有危害因素选项框禁用
    }
  },
  created() {},
  mounted() {
    this.employerUuid = this.$route.params.employerUuid
    this.msForm.mainId = this.$route.params.rid
    this.getHealthSupervisionByMainID(true)
  },
  watch: {
    'msForm.existsDust': {
      handler() {
        if (!this.msForm.existsDust) {
          this.msForm.dustNumber = ''
          this.msForm.dustHazardList.forEach(child => {
            child.forEach(item => {
              this.$set(item, 'peNumber', '')
            })
          })
        }
      },
      deep: true,
    },
    'msForm.existsChemical': {
      handler() {
        if (!this.msForm.existsChemical) {
          this.msForm.chemicalNumber = ''
          this.msForm.chemicalHazardList.forEach(child => {
            child.forEach(item => {
              this.$set(item, 'peNumber', '')
            })
          })
        }
      },
      deep: true,
    },
    'msForm.existsPhysical': {
      handler() {
        if (!this.msForm.existsPhysical) {
          this.msForm.physicalNumber = ''
          this.msForm.physicalHazardList.forEach(child => {
            child.forEach(item => {
              this.$set(item, 'peNumber', '')
            })
          })
        }
      },
      deep: true,
    },
    'msForm.existsRadioactivity': {
      handler() {
        if (!this.msForm.existsRadioactivity) {
          this.msForm.radioactivityNumber = ''
          this.msForm.radioactivityHazardList.forEach(child => {
            child.forEach(item => {
              this.$set(item, 'peNumber', '')
            })
          })
        }
      },
      deep: true,
    },
    'msForm.existsBiotic': {
      handler() {
        if (!this.msForm.existsBiotic) {
          this.msForm.bioticNumber = ''
          this.msForm.bioticHazardList.forEach(child => {
            child.forEach(item => {
              this.$set(item, 'peNumber', '')
            })
          })
        }
      },
      deep: true,
    },
    'msForm.existsOther': {
      handler() {
        if (!this.msForm.existsOther) {
          this.msForm.otherNumber = ''
          this.msForm.otherHazardList.forEach(child => {
            child.forEach(item => {
              this.$set(item, 'peNumber', '')
            })
          })
        }
      },
      deep: true,
    },
    // msForm: {
    //   handler() {
    //     this.$parent.jsonData = JSON.stringify(this.msForm)
    //   },
    //   deep: true,
    // },
  },
  methods: {
    // 清空数据
    clearForm() {
      let [item] = this.msForm.healthSupervisionRecordList
      item.unitName = null
      item.reportNo = null
      item.creditCode = null
      item.contactInfo = null
      this.msForm.healthSupervisionRecordList = [item]
      if (this.msForm.dustHazardList.length > 0) {
        this.msForm.existsDust = false
        this.msForm.dustHazardList.map(item => {
          item.peNumber = null
        })
      }
      this.$forceUpdate()
    },
    /**
     * 获取下拉体检机构
     */
    getPeServiceContractList() {
      let data = {
        // 用人单位id
        employerUuid: this.employerUuid,
      }
      this.$system.get(
        this.api + '/oh/declaration/getPeServiceContractList-1',
        data,
        true,
        true,
        data => {
          //this.$parent.loading = false
          if (data.type == '00') {
            this.organizationList = data.tdPeServiceContractList
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          //this.$parent.loading = false
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    // 体检机构下拉框选择
    changeSelect(value, row) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        let list = this.organizationList.filter(e => value === e.organName)
        this.$set(row, 'creditCode', list[0].organCreditCode)
        this.$set(row, 'unitName', list[0].organName)
      } else {
        row.unitName = ''
        row.creditCode = ''
      }
    },
    existsSupervisionChange(val) {
      if (this.isShow) {
        this.$confirm('修改后将清空下方填写内容，是否确定?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' })
          .then(() => {
            this.isShow = false
            if (this.msForm.healthSupervisionList) {
              this.msForm.healthSupervisionList.forEach(item => {
                item.ifDetection = false
              })
            }
            // this.clearForm()
          })
          .catch(e => {
            this.isShow = true
            this.msForm.existsSupervision = true
          })
      } else {
        // this.isShow = true
        this.getHealthSupervisionByMainID(false)
      }
    },
    // 检测报告编号不可重复
    validateReportNo(rule, value, callback) {
      let index = rule.field.split('.')[1]
      for (let i = 0; i < this.msForm.healthSupervisionRecordList.length; i++) {
        if (index != i && value == this.msForm.healthSupervisionRecordList[i].reportNo) {
          callback(new Error('检测报告编号不可重复'))
        }
      }
      callback()
    },
    /**
     * 明细因素体检人数校验
     */
    validatePeNumber(rule, value, callback) {
      let listName = rule.field.split('.')[0]
      if (listName === 'dustHazardList') {
        if (this.$zwxBase.verifyIsNotBlank(this.msForm.dustNumber) && this.$zwxBase.verifyIsNotBlank(value) && parseInt(value) > parseInt(this.msForm.dustNumber)) {
          return callback(new Error('体检人数应小于或等于体检总人数（' + parseInt(this.msForm.dustNumber) + '）'))
        }
      }
      if (listName === 'chemicalHazardList') {
        if (this.$zwxBase.verifyIsNotBlank(this.msForm.chemicalNumber) && this.$zwxBase.verifyIsNotBlank(value) && parseInt(value) > parseInt(this.msForm.chemicalNumber)) {
          return callback(new Error('体检人数应小于或等于体检总人数（' + parseInt(this.msForm.chemicalNumber) + '）'))
        }
      }
      if (listName === 'physicalHazardList') {
        if (this.$zwxBase.verifyIsNotBlank(this.msForm.physicalNumber) && this.$zwxBase.verifyIsNotBlank(value) && parseInt(value) > parseInt(this.msForm.physicalNumber)) {
          return callback(new Error('体检人数应小于或等于体检总人数（' + parseInt(this.msForm.physicalNumber) + '）'))
        }
      }
      if (listName === 'radioactivityHazardList') {
        if (this.$zwxBase.verifyIsNotBlank(this.msForm.radioactivityNumber) && this.$zwxBase.verifyIsNotBlank(value) && parseInt(value) > parseInt(this.msForm.radioactivityNumber)) {
          return callback(new Error('体检人数应小于或等于体检总人数（' + parseInt(this.msForm.radioactivityNumber) + '）'))
        }
      }
      if (listName === 'bioticHazardList') {
        if (this.$zwxBase.verifyIsNotBlank(this.msForm.bioticNumber) && this.$zwxBase.verifyIsNotBlank(value) && parseInt(value) > parseInt(this.msForm.bioticNumber)) {
          return callback(new Error('体检人数应小于或等于体检总人数（' + parseInt(this.msForm.bioticNumber) + '）'))
        }
      }
      if (listName === 'otherHazardList') {
        if (this.$zwxBase.verifyIsNotBlank(this.msForm.otherNumber) && this.$zwxBase.verifyIsNotBlank(value) && parseInt(value) > parseInt(this.msForm.otherNumber)) {
          return callback(new Error('体检人数应小于或等于体检总人数（' + parseInt(this.msForm.otherNumber) + '）'))
        }
      }
      callback()
    },
    /**
     * 粉尘体检总人数校验
     */
    validateDustNumber(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (value > 0) {
          let total = 0
          this.msForm.dustHazardList.forEach(child => {
            if (this.$zwxBase.verifyIsNotBlank(child)) {
              child.forEach(item => {
                if (this.$zwxBase.verifyIsNotBlank(item.peNumber)) {
                  total = total + parseInt(item.peNumber)
                }
              })
            }
          })
          // if (total > 0 && value > total) {
          //   callback(new Error('体检总人数应小于等于体检人数之和'))
          // }
          if (value > this.tdHazardDistribution.dustContacts) {
            callback(new Error('体检总人数应小于等于接触总人数（' + this.tdHazardDistribution.dustContacts + '）'))
          }
        }
        callback()
      }
    },
    /**
     * 化学体检总人数校验
     */
    validateChemicalNumber(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (value > 0) {
          let total = 0
          this.msForm.chemicalHazardList.forEach(child => {
            if (this.$zwxBase.verifyIsNotBlank(child)) {
              child.forEach(item => {
                if (this.$zwxBase.verifyIsNotBlank(item.peNumber)) {
                  total = total + parseInt(item.peNumber)
                }
              })
            }
          })
          // if (total > 0 && value > total) {
          //   callback(new Error('体检总人数应小于等于体检人数之和'))
          // }
          if (value > this.tdHazardDistribution.chemicalContacts) {
            callback(new Error('体检总人数应小于等于接触总人数（' + this.tdHazardDistribution.chemicalContacts + '）'))
          }
        }
        callback()
      }
    },
    /**
     * 物理体检总人数校验
     */
    validatePhysicalNumber(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (value > 0) {
          let total = 0
          this.msForm.physicalHazardList.forEach(child => {
            if (this.$zwxBase.verifyIsNotBlank(child)) {
              child.forEach(item => {
                if (this.$zwxBase.verifyIsNotBlank(item.peNumber)) {
                  total = total + parseInt(item.peNumber)
                }
              })
            }
          })
          // if (total > 0 && value > total) {
          //   callback(new Error('体检总人数应小于等于体检人数之和'))
          // }
          if (value > this.tdHazardDistribution.physicalContacts) {
            callback(new Error('体检总人数应小于等于接触总人数（' + this.tdHazardDistribution.physicalContacts + '）'))
          }
        }
        callback()
      }
    },
    /**
     * 放射体检总人数校验
     */
    validateRadioactivityNumber(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (value > 0) {
          let total = 0
          this.msForm.radioactivityHazardList.forEach(child => {
            if (this.$zwxBase.verifyIsNotBlank(child)) {
              child.forEach(item => {
                if (this.$zwxBase.verifyIsNotBlank(item.peNumber)) {
                  total = total + parseInt(item.peNumber)
                }
              })
            }
          })
          // if (total > 0 && value > total) {
          //   callback(new Error('体检总人数应小于等于体检人数之和'))
          // }
          if (value > this.tdHazardDistribution.radioactivityContacts) {
            callback(new Error('体检总人数应小于等于接触总人数（' + this.tdHazardDistribution.radioactivityContacts + '）'))
          }
        }
        callback()
      }
    },
    /**
     * 生物体检总人数校验
     */
    validateBioticNumber(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (value > 0) {
          let total = 0
          this.msForm.bioticHazardList.forEach(child => {
            if (this.$zwxBase.verifyIsNotBlank(child)) {
              child.forEach(item => {
                if (this.$zwxBase.verifyIsNotBlank(item.peNumber)) {
                  total = total + parseInt(item.peNumber)
                }
              })
            }
          })
          // if (total > 0 && value > total) {
          //   callback(new Error('体检总人数应小于等于体检人数之和'))
          // }
          if (value > this.tdHazardDistribution.bioticContacts) {
            callback(new Error('体检总人数应小于等于接触总人数（' + this.tdHazardDistribution.bioticContacts + '）'))
          }
        }
        callback()
      }
    },
    /**
     * 其他体检总人数校验
     */
    validateOtherNumber(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        if (value > 0) {
          let total = 0
          this.msForm.otherHazardList.forEach(child => {
            if (this.$zwxBase.verifyIsNotBlank(child)) {
              child.forEach(item => {
                if (this.$zwxBase.verifyIsNotBlank(item.peNumber)) {
                  total = total + parseInt(item.peNumber)
                }
              })
            }
          })
          // if (total > 0 && value > total) {
          //   callback(new Error('体检总人数应小于等于体检人数之和'))
          // }
          if (value > this.tdHazardDistribution.otherContacts) {
            callback(new Error('体检总人数应小于等于接触总人数（' + this.tdHazardDistribution.otherContacts + '）'))
          }
        }
        callback()
      }
    },
    // 查询健康监护情况
    getHealthSupervisionByMainID(flag) {
      this.$parent.loading = true
      let fkByMainId = {
        rid: this.msForm.mainId,
      }
      let data = {
        employerUuid: this.employerUuid,
        fkByMainId: fkByMainId,
      }
      if (!flag) {
        data.ifClickYes = true
      }
      this.$system.postJson(
        this.api + '/oh/declaration/getHealthSupervisionByMainID-1',
        data,
        true,
        true,
        data => {
          this.$parent.loading = false
          if (data.type === '00') {
            if (flag) {
              this.msForm.existsSupervision = data.healthSupervision.existsSupervision == false ? false : true
              this.isShow = data.healthSupervision.existsSupervision == false ? false : true
            } else {
              this.msForm.existsSupervision = true
              this.isShow = true
            }
            this.msForm.rid = data.healthSupervision.rid
            this.msForm.existsDust = data.healthSupervision.existsDust
            this.msForm.existsChemical = data.healthSupervision.existsChemical
            this.msForm.existsPhysical = data.healthSupervision.existsPhysical
            this.msForm.existsRadioactivity = data.healthSupervision.existsRadioactivity
            this.msForm.existsBiotic = data.healthSupervision.existsBiotic
            this.msForm.existsOther = data.healthSupervision.existsOther
            this.msForm.dustNumber = data.healthSupervision.dustNumber
            this.msForm.chemicalNumber = data.healthSupervision.chemicalNumber
            this.msForm.physicalNumber = data.healthSupervision.physicalNumber
            this.msForm.radioactivityNumber = data.healthSupervision.radioactivityNumber
            this.msForm.bioticNumber = data.healthSupervision.bioticNumber
            this.msForm.otherNumber = data.healthSupervision.otherNumber
            this.msForm.healthSupervisionRecordList = data.tdHealthSupervisionRecordExpList || []
            this.hazardDistribution = data.tdHazardDistributionExp
            if (this.$zwxBase.verifyIsNotBlank(data.dustHazardList)) {
              this.msForm.dustHazardList = this.$zwxBase.multidimensionalArrayDeal(data.dustHazardList, 3)
            }
            if (this.$zwxBase.verifyIsNotBlank(data.chemicalHazardList)) {
              this.msForm.chemicalHazardList = this.$zwxBase.multidimensionalArrayDeal(data.chemicalHazardList, 3)
            }
            if (this.$zwxBase.verifyIsNotBlank(data.physicalHazardList)) {
              this.msForm.physicalHazardList = this.$zwxBase.multidimensionalArrayDeal(data.physicalHazardList, 3)
            }
            if (this.$zwxBase.verifyIsNotBlank(data.radioactivityHazardList)) {
              this.msForm.radioactivityHazardList = this.$zwxBase.multidimensionalArrayDeal(data.radioactivityHazardList, 3)
            }
            if (this.$zwxBase.verifyIsNotBlank(data.bioticHazardList)) {
              this.msForm.bioticHazardList = this.$zwxBase.multidimensionalArrayDeal(data.bioticHazardList, 3)
            }
            if (this.$zwxBase.verifyIsNotBlank(data.otherHazardList)) {
              this.msForm.otherHazardList = this.$zwxBase.multidimensionalArrayDeal(data.otherHazardList, 3)
            }
            if (this.$zwxBase.verifyIsNotBlank(data.tdHazardDistributionExp)) {
              this.tdHazardDistribution = data.tdHazardDistributionExp
            }
            if (flag) {
              this.$nextTick(() => {
                this.$parent.jsonData = JSON.stringify(this.msForm)
              })
            }
            this.$parent.saveDisabled = false
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$parent.loading = false
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    // 是否选中
    itemSelect(index) {
      if (index == 1) {
        this.msForm.existsDust = !this.msForm.existsDust
      }
    },
    // 检测机构列表添加
    addList() {
      this.msForm.healthSupervisionRecordList.push({
        unitName: '',
        reportNo: '',
        creditCode: '',
        contactInfo: '',
      })
    },
    healthSupervisionOrganSelectChange(value, index) {
      this.$set(this.msForm.healthSupervisionRecordList[index], 'unitName', value.unitName)
      this.$set(this.msForm.healthSupervisionRecordList[index], 'creditCode', value.creditCode)
      this.$set(this.msForm.healthSupervisionRecordList[index], 'contactInfo', value.linkMobile)
    },
    // 检测机构列表删除
    deletion(index) {
      if (this.msForm.healthSupervisionRecordList.length < 2) {
        this.$system.notify('提示', '至少填写一条记录', 'info')
      } else if (this.msForm.healthSupervisionRecordList[index].unitName != '' || this.msForm.healthSupervisionRecordList[index].reportNo != '' || this.msForm.healthSupervisionRecordList[index].creditCode != '' || this.msForm.healthSupervisionRecordList[index].contactInfo != '') {
        this.$system.msgbox('', '提示', '是否确定删除？', '确定', '取消', () => {
          this.msForm.healthSupervisionRecordList.splice(index, 1)
        })
      } else {
        this.msForm.healthSupervisionRecordList.splice(index, 1)
      }
    },
    /**
     * 保存/更新
     */
    saveOrUpdateHealthSupervision(opType) {
      if (0 == opType || 2 == opType) {
        //不校验
        this.saveHealthSupervision(opType)
      } else {
        if (!this.isShow) {
          this.$parent.saveDisabled = true
          this.saveHealthSupervision(opType)
        } else {
          // 校验
          if (this.msForm.healthSupervisionRecordList.length < 1) {
            this.$system.notify('提示', '至少填写一条检测机构记录', 'info')
            return
          }
          if (this.msForm.existsSupervision && !this.msForm.existsDust && !this.msForm.existsChemical && !this.msForm.existsPhysical && !this.msForm.existsRadioactivity) {
            if (!this.hazardDistribution.existsBiotic && !this.hazardDistribution.existsOther) {
              this.$system.notify('提示', '至少填写一条危害因素检查情况', 'info')
              return
            }
          }
          this.$refs.healthSupervisionDetailIndexRef.validate(valid => {
            if (valid) {
              this.$parent.saveDisabled = true
              this.saveHealthSupervision(opType)
            }
          })
        }
      }
    },
    saveHealthSupervision(opType) {
      let healthSupervisionDetailExpList = []
      if (!this.isShow) {
        this.msForm.tdHazardDetectionRecordExpList = []
      } else {
        if (this.msForm.dustHazardList.length > 0) {
          let flag = false
          let list = this.$zwxBase.arrayDeal(this.msForm.dustHazardList)
          list.forEach(item => {
            if (parseInt(item.peNumber) > 0) {
              flag = true
            }
            healthSupervisionDetailExpList.push(item)
          })
          if (this.msForm.existsDust && 1 == opType && !flag) {
            this.$parent.saveDisabled = false
            this.$notify.info({ title: '提示', message: '粉尘因素下体检人数不能全部为0' })
            return
          }
        }
        if (this.msForm.chemicalHazardList.length > 0) {
          let flag = false
          let list = this.$zwxBase.arrayDeal(this.msForm.chemicalHazardList)
          list.forEach(item => {
            if (parseInt(item.peNumber) > 0) {
              flag = true
            }
            healthSupervisionDetailExpList.push(item)
          })
          if (this.msForm.existsChemical && 1 == opType && !flag) {
            this.$parent.saveDisabled = false
            this.$notify.info({ title: '提示', message: '化学因素下体检人数不能全部为0' })
            return
          }
        }
        if (this.msForm.physicalHazardList.length > 0) {
          let flag = false
          let list = this.$zwxBase.arrayDeal(this.msForm.physicalHazardList)
          list.forEach(item => {
            if (parseInt(item.peNumber) > 0) {
              flag = true
            }
            healthSupervisionDetailExpList.push(item)
          })
          if (this.msForm.existsPhysical && 1 == opType && !flag) {
            this.$parent.saveDisabled = false
            this.$notify.info({ title: '提示', message: '物理因素下体检人数不能全部为0' })
            return
          }
        }
        if (this.msForm.radioactivityHazardList.length > 0) {
          let flag = false
          let list = this.$zwxBase.arrayDeal(this.msForm.radioactivityHazardList)
          list.forEach(item => {
            if (parseInt(item.peNumber) > 0) {
              flag = true
            }
            healthSupervisionDetailExpList.push(item)
          })
          if (this.msForm.existsRadioactivity && 1 == opType && !flag) {
            this.$parent.saveDisabled = false
            this.$notify.info({ title: '提示', message: '放射因素下体检人数不能全部为0' })
            return
          }
        }
      }
      this.$parent.loading = true
      let fkByMainId = {
        rid: this.msForm.mainId,
      }
      let data = {
        rid: this.msForm.rid,
        fkByMainId: fkByMainId,
        existsSupervision: this.msForm.existsSupervision,
        existsDust: this.msForm.existsDust,
        existsChemical: this.msForm.existsChemical,
        existsPhysical: this.msForm.existsPhysical,
        existsRadioactivity: this.msForm.existsRadioactivity,
        existsBiotic: undefined, //this.msForm.existsBiotic,
        existsOther: undefined, //this.msForm.existsOther,
        dustNumber: this.msForm.dustNumber,
        chemicalNumber: this.msForm.chemicalNumber,
        physicalNumber: this.msForm.physicalNumber,
        radioactivityNumber: this.msForm.radioactivityNumber,
        bioticNumber: this.msForm.bioticNumber,
        otherNumber: this.msForm.otherNumber,
        healthSupervisionRecordList: this.msForm.healthSupervisionRecordList,
        healthSupervisionDetailExpList: healthSupervisionDetailExpList,
      }
      let data2 = {
        rid: this.msForm.rid,
        fkByMainId: fkByMainId,
        existsSupervision: this.msForm.existsSupervision,
        opType,
      }
      data.opType = opType
      this.$system.postJson(
        this.api + '/oh/declaration/saveOrUpdateHealthSupervision-1',
        this.isShow ? data : data2,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            //点击下一步
            if (opType === 1) {
              // this.$router.push({ name: 'HazardDeclaration', params: { fresh: true } })
              this.$parent.step++
            } else {
              this.$parent.jsonData = JSON.stringify(this.msForm)
            }
            if (2 == opType) {
              // 暂存保存数据后，按钮解禁
              this.$parent.saveDisabled = false
            }
            if (0 == opType) {
              this.$parent.step--
            }
          } else if (data.type === '99') {
            this.$parent.saveDisabled = false
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$parent.saveDisabled = false
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$parent.loading = false
        },
        this.error
      )
    },
    /**
     * 社会信用代码校验
     */
    validateCreditCode(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        this.$validate.creditCode(rule, value, callback)
      }
      callback()
    },
  },
}
</script>

<style lang="less" scoped>
.harz {
  margin-top: 20px !important;
  border-top: 1px solid #d5d9e0;
}
.harz-list {
  .harz:first-child {
    margin-top: 0px !important;
    border-top: none !important;
  }
}

.table-css {
  /deep/.zwx-table .zwx-input .el-input__inner {
    width: 120px !important;
  }
}

.flex-row {
  display: flex;
  // align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flew-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.zwx-table .el-input__icon {
  line-height: 32px !important;
}

.health-supervision-detail-index {
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  .stages-css {
    color: rgba(31, 45, 61, 1);
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    background: rgba(244, 255, 244, 1);
    padding: 8px 10px;
    border: 1px solid rgba(199, 231, 195, 1);
    border-radius: 4px;
    margin-left: 12px;
    margin-right: 20px;
    cursor: pointer;
  }
  .div-table {
  }

  .div-table-row {
    display: flex;
    width: 100%;
  }

  .div-table-head-name-col {
    min-width: 246px;
    height: 38px;
    border-right: 1px solid #e1e5f4;
    border-bottom: 1px solid #e1e5f4;
    border-top: 1px solid #e1e5f4;
    background-color: #f6f8fb;
  }
  .div-table-head-border {
    min-width: 10px;
    border-top: 1px solid #e1e5f4;
    border-right: 1px solid #e1e5f4;
    background-color: #f6f8fb;
  }
  .div-table-td-border {
    min-width: 10px;
    height: 45px;
    border-right: 1px solid #e1e5f4;
    background-color: #f6f8fb;
  }
  .div-table-td-name-col {
    min-width: 250px;
    height: 45px;
    border-right: 1px solid #e1e5f4;
    border-bottom: 1px solid #e1e5f4;
    padding: 8.5px 14px;
  }
  .div-table-td-num-col {
    min-width: 246px;
    height: 45px;
    border-right: 1px solid #e1e5f4;
    border-bottom: 1px solid #e1e5f4;
    padding: 5px 14px;
  }
}
</style>
