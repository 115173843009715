var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "base-info-index" },
    [
      _c(
        "el-form",
        {
          key: "msForm",
          ref: "baseInfoIndexRef",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.msForm,
            "label-position": "right",
            rules: _vm.rules
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "edit-row declare-type" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "90px",
                    label: "申报类型：",
                    prop: "declareType"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "zwx-radio-group",
                      on: { change: _vm.declareTypeChange },
                      model: {
                        value: _vm.msForm.declareType,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "declareType", $$v)
                        },
                        expression: "msForm.declareType"
                      }
                    },
                    [
                      _vm.ifShow
                        ? _c(
                            "el-radio",
                            { staticClass: "zwx-radio", attrs: { label: 0 } },
                            [_vm._v("初次申报")]
                          )
                        : _vm._e(),
                      !_vm.ifShow
                        ? _c(
                            "el-radio",
                            { staticClass: "zwx-radio", attrs: { label: 1 } },
                            [_vm._v("变更申报")]
                          )
                        : _vm._e(),
                      !_vm.ifShow && _vm.ifCanDeclareType2
                        ? _c(
                            "el-radio",
                            { staticClass: "zwx-radio", attrs: { label: 2 } },
                            [_vm._v("年度申报")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.ifShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        "label-width": "90px",
                        label: "变更原因：",
                        prop: "reasonCode"
                      }
                    },
                    [
                      _c("cascader-single", {
                        ref: "cascader2",
                        staticClass: "codeTypeCodeCss",
                        attrs: {
                          simpleCodeList: _vm.reasonCodeList,
                          codeTypeCode: "2003",
                          disabled: _vm.reasonCodeDisabled
                        },
                        model: {
                          value: _vm.msForm.reasonCode,
                          callback: function($$v) {
                            _vm.$set(_vm.msForm, "reasonCode", $$v)
                          },
                          expression: "msForm.reasonCode"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "90px",
                    label: "申报年份：",
                    prop: "declareYear"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "zwx-select",
                      attrs: {
                        "popper-class": "zwx-select-popper",
                        clearable: true,
                        disabled: _vm.yearDisable
                      },
                      model: {
                        value: _vm.msForm.declareYear,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "declareYear", $$v)
                        },
                        expression: "msForm.declareYear"
                      }
                    },
                    _vm._l(_vm.declareYearList, function(item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              "20030099" == _vm.msForm.reasonCode
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "90px",
                            label: "其他原因：",
                            prop: "otherReason"
                          }
                        },
                        [
                          _c("el-input", {
                            staticClass: "zwx-textarea",
                            staticStyle: { width: "810px !important" },
                            attrs: {
                              placeholder: "请输入其他原因说明",
                              rows: 2,
                              type: "textarea",
                              clearable: "",
                              maxlength: "2000",
                              rules: {
                                required: true,
                                message: "请输入其他原因说明",
                                trigger: "blur"
                              }
                            },
                            model: {
                              value: _vm.msForm.otherReason,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.msForm,
                                  "otherReason",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "msForm.otherReason"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row", staticStyle: { "margin-top": "16px" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "单位名称：",
                    prop: "unitName"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "300px !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      disabled:
                        _vm.$zwxBase.verifyIsNotBlank(_vm.msForm.unitName) &&
                        _vm.disabledFlag
                    },
                    model: {
                      value: _vm.msForm.unitName,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "unitName", $$v)
                      },
                      expression: "msForm.unitName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "170px",
                    label: "社会信用代码：",
                    prop: "creditCode"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "300px !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      disabled: ""
                    },
                    model: {
                      value: _vm.msForm.creditCode,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "creditCode", $$v)
                      },
                      expression: "msForm.creditCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "单位注册地区：",
                    prop: "registerZone"
                  }
                },
                [
                  _c("zwx-select-area", {
                    ref: "registerZone",
                    staticClass: "zwx-basic-information-management-edit-zone",
                    attrs: {
                      zoneCode12From: _vm.zoneCode12From,
                      zoneTypeMax: "0",
                      zoneTypeMin: "4",
                      showFullName: true,
                      disabled:
                        _vm.$zwxBase.verifyIsNotBlank(
                          _vm.msForm.registerZone
                        ) && _vm.disabledFlag
                    },
                    on: { change: _vm.nativePlaceChange1 }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "170px",
                    label: "单位注册地址：",
                    prop: "registerAddr"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "300px !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "400",
                      disabled:
                        _vm.$zwxBase.verifyIsNotBlank(
                          _vm.msForm.registerAddr
                        ) && _vm.disabledFlag
                    },
                    model: {
                      value: _vm.msForm.registerAddr,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "registerAddr", $$v)
                      },
                      expression: "msForm.registerAddr"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "作业场所地区：",
                    prop: "bizZone"
                  }
                },
                [
                  _c("zwx-select-area", {
                    ref: "bizZone",
                    staticClass: "zwx-basic-information-management-edit-zone",
                    attrs: {
                      zoneCode12From: _vm.zoneCode12From,
                      zoneTypeMax: "0",
                      zoneTypeMin: "4",
                      showFullName: true,
                      disabled:
                        _vm.$zwxBase.verifyIsNotBlank(_vm.msForm.bizZone) &&
                        _vm.disabledFlag
                    },
                    on: { change: _vm.nativePlaceChange }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "170px",
                    label: "作业场所地址：",
                    prop: "workplaceAddr"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "300px !important" },
                    attrs: {
                      placeholder: "请输入",
                      clearable: "",
                      maxlength: "400",
                      disabled:
                        _vm.$zwxBase.verifyIsNotBlank(
                          _vm.msForm.workplaceAddr
                        ) && _vm.disabledFlag
                    },
                    model: {
                      value: _vm.msForm.workplaceAddr,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "workplaceAddr", $$v)
                      },
                      expression: "msForm.workplaceAddr"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "企业规模：",
                    prop: "enterpriseScale"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "zwx-radio-group",
                      staticStyle: { "min-width": "300px !important" },
                      attrs: {
                        disabled:
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.msForm.enterpriseScale
                          ) && _vm.disabledFlag
                      },
                      model: {
                        value: _vm.msForm.enterpriseScale,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "enterpriseScale", $$v)
                        },
                        expression: "msForm.enterpriseScale"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 1 } },
                        [_vm._v("大")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 2 } },
                        [_vm._v("中")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 3 } },
                        [_vm._v("小")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: 4 } },
                        [_vm._v("微")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "170px",
                    label: "经济类型：",
                    prop: "economicCode"
                  }
                },
                [
                  _c("cascader-single", {
                    ref: "cascader",
                    staticClass: "zwx-basic-information-management-edit-zone",
                    attrs: {
                      codeTypeCode: "1015",
                      expandTrigger: "hover",
                      simpleCodeList: _vm.economicSimpleCodeList,
                      disabled:
                        _vm.$zwxBase.verifyIsNotBlank(
                          _vm.msForm.economicCode
                        ) && _vm.disabledFlag
                    },
                    on: { change: _vm.economicCodeChange },
                    model: {
                      value: _vm.msForm.economicCode,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "economicCode", $$v)
                      },
                      expression: "msForm.economicCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "行业分类：",
                    prop: "industryName"
                  }
                },
                [
                  _c(
                    "div",
                    { on: { click: _vm.msDeclareInfoCategoryDialog } },
                    [
                      _c(
                        "el-input",
                        {
                          staticClass: "zwx-input zwx-occ-category-input",
                          staticStyle: { width: "300px !important" },
                          attrs: {
                            readonly: "readonly",
                            placeholder: "",
                            "read-only": true,
                            disabled:
                              _vm.$zwxBase.verifyIsNotBlank(
                                _vm.msForm.industryName
                              ) && _vm.disabledFlag
                          },
                          model: {
                            value: _vm.msForm.industryName,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.msForm,
                                "industryName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "msForm.industryName"
                          }
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _c("span", [
                              _c("i", { staticClass: "el-icon-search" })
                            ])
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "170px",
                    label: "填报人：",
                    prop: "informant"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "120px !important" },
                    attrs: { placeholder: "姓名", clearable: "" },
                    model: {
                      value: _vm.msForm.informant,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "informant", $$v)
                      },
                      expression: "msForm.informant"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "informantContact" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "160px !important" },
                    attrs: { placeholder: "联系方式", clearable: "" },
                    model: {
                      value: _vm.msForm.informantContact,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "informantContact", $$v)
                      },
                      expression: "msForm.informantContact"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "110px",
                    label: "法定代表人：",
                    prop: "legalPerson"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "120px !important" },
                    attrs: {
                      placeholder: "姓名",
                      clearable: "",
                      disabled:
                        _vm.$zwxBase.verifyIsNotBlank(_vm.msForm.legalPerson) &&
                        _vm.disabledFlag
                    },
                    model: {
                      value: _vm.msForm.legalPerson,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "legalPerson", $$v)
                      },
                      expression: "msForm.legalPerson"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "legalPersonPhone" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "160px !important" },
                    attrs: {
                      placeholder: "联系方式",
                      clearable: "",
                      disabled: _vm.ifDisabled && _vm.disabledFlag
                    },
                    model: {
                      value: _vm.msForm.legalPersonPhone,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "legalPersonPhone", $$v)
                      },
                      expression: "msForm.legalPersonPhone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "170px",
                    label: "职业卫生管理联系人：",
                    prop: "linkManager"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "120px !important" },
                    attrs: { placeholder: "姓名", clearable: "" },
                    model: {
                      value: _vm.msForm.linkManager,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "linkManager", $$v)
                      },
                      expression: "msForm.linkManager"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "", prop: "linkManagerContact" } },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "160px !important" },
                    attrs: { placeholder: "联系方式", clearable: "" },
                    model: {
                      value: _vm.msForm.linkManagerContact,
                      callback: function($$v) {
                        _vm.$set(_vm.msForm, "linkManagerContact", $$v)
                      },
                      expression: "msForm.linkManagerContact"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row info-box" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "160px",
                    label: "本单位在册职工总数：",
                    prop: "totalStaffNum"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "80px !important" },
                    attrs: { placeholder: "请输入", clearable: "" },
                    on: {
                      input: function($event) {
                        return _vm.$validate.positiveInteger(
                          _vm.$data["msForm"],
                          "totalStaffNum"
                        )
                      }
                    },
                    model: {
                      value: _vm.msForm.totalStaffNum,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "totalStaffNum",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.totalStaffNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "120px",
                    label: "外委人员总数：",
                    prop: "totalSubcontractingStaff"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "80px !important" },
                    attrs: { placeholder: "请输入", clearable: "" },
                    on: {
                      input: function($event) {
                        return _vm.$validate.nonnegativeInteger(
                          _vm.$data["msForm"],
                          "totalSubcontractingStaff"
                        )
                      }
                    },
                    model: {
                      value: _vm.msForm.totalSubcontractingStaff,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "totalSubcontractingStaff",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.totalSubcontractingStaff"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "140px",
                    label: "在册接害人数：",
                    prop: "workerNum"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "80px !important" },
                    attrs: { placeholder: "请输入", clearable: "" },
                    on: {
                      input: function($event) {
                        return _vm.$validate.nonnegativeInteger(
                          _vm.$data["msForm"],
                          "workerNum"
                        )
                      }
                    },
                    model: {
                      value: _vm.msForm.workerNum,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "workerNum",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.workerNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "150px",
                    label: "外委接害人数：",
                    prop: "outsourcingNum"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "80px !important" },
                    attrs: { placeholder: "请输入", clearable: "" },
                    on: {
                      input: function($event) {
                        return _vm.$validate.nonnegativeInteger(
                          _vm.$data["msForm"],
                          "outsourcingNum"
                        )
                      }
                    },
                    model: {
                      value: _vm.msForm.outsourcingNum,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "outsourcingNum",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.outsourcingNum"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "140px",
                    label: "职业病累计人数：",
                    prop: "cumulativePatients"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "80px !important" },
                    attrs: { placeholder: "请输入", clearable: "" },
                    on: {
                      input: function($event) {
                        return _vm.$validate.nonnegativeInteger(
                          _vm.$data["msForm"],
                          "cumulativePatients"
                        )
                      }
                    },
                    model: {
                      value: _vm.msForm.cumulativePatients,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "cumulativePatients",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.cumulativePatients"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "150px",
                    label: "接害总人数(含外委)："
                  }
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.totalVictimsNum))]),
                  _c(
                    "span",
                    {
                      staticStyle: { "padding-left": "10px", color: "orange" }
                    },
                    [
                      _vm._v(
                        " 提示：该人数为在册接害人数与外委接害人数之和，自动统计无需填写 "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row info-box" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "130px",
                    label: "主要负责人培训：",
                    prop: "principalTraining"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "zwx-radio-group",
                      staticStyle: { "min-width": "130px !important" },
                      model: {
                        value: _vm.msForm.principalTraining,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "principalTraining", $$v)
                        },
                        expression: "msForm.principalTraining"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: true } },
                        [_vm._v("已培训")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: false } },
                        [_vm._v("未培训")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "170px",
                    label: "职业卫生管理人员培训：",
                    prop: "managerTraining"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticClass: "zwx-radio-group",
                      staticStyle: { "min-width": "130px !important" },
                      model: {
                        value: _vm.msForm.managerTraining,
                        callback: function($$v) {
                          _vm.$set(_vm.msForm, "managerTraining", $$v)
                        },
                        expression: "msForm.managerTraining"
                      }
                    },
                    [
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: true } },
                        [_vm._v("已培训")]
                      ),
                      _c(
                        "el-radio",
                        { staticClass: "zwx-radio", attrs: { label: false } },
                        [_vm._v("未培训")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "260px",
                    label: "接触职业病危害因素年度培训总人数：",
                    prop: "totalTrainee"
                  }
                },
                [
                  _c("el-input", {
                    staticClass: "zwx-input",
                    staticStyle: { width: "80px !important" },
                    attrs: { placeholder: "请输入", clearable: "" },
                    on: {
                      input: function($event) {
                        return _vm.$validate.nonnegativeInteger(
                          _vm.$data["msForm"],
                          "totalTrainee"
                        )
                      }
                    },
                    model: {
                      value: _vm.msForm.totalTrainee,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.msForm,
                          "totalTrainee",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "msForm.totalTrainee"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "edit-row", staticStyle: { display: "flex" } },
            [
              _vm.msForm.principalTraining
                ? _c(
                    "div",
                    { staticStyle: { width: "270px" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "160px",
                            label: _vm.msForm.principalTraining
                              ? "主要负责人培训证明："
                              : "",
                            prop: "principalTrainingPath",
                            rules: [
                              {
                                required:
                                  _vm.msForm.declareType == "2" &&
                                  _vm.msForm.principalTraining,
                                validator: (rule, value, callback) => {
                                  _vm.validateTrainingPath(
                                    rule,
                                    value,
                                    callback
                                  )
                                },
                                trigger: ["change", "blur"]
                              }
                            ]
                          }
                        },
                        [
                          _c(
                            "file-upload",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.msForm.principalTrainingPathList
                                      .length == 0,
                                  expression:
                                    "msForm.principalTrainingPathList.length == 0"
                                }
                              ],
                              ref: "principalTrainingPathList",
                              attrs: {
                                accept:
                                  ".png,.PNG,.jpg,.JPG,.JPEG,.jpeg,.pdf,.PDF",
                                limit: 1,
                                size: 50 * 1024 * 1024,
                                sizeInfo: "50M"
                              },
                              on: {
                                showFileList: _vm.showPrincipalTrainingPathList,
                                success: _vm.principalTrainingPathSuccess,
                                error: _vm.fileUploadError
                              }
                            },
                            [
                              _vm.msForm.principalTraining
                                ? _c(
                                    "el-button",
                                    {
                                      staticClass:
                                        "zwx-button zwx-button-icontext-28",
                                      attrs: { icon: "el-icon-upload2" }
                                    },
                                    [_vm._v(" 上传 ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _vm.$zwxBase.verifyIsNotBlank(
                            _vm.msForm.principalTrainingPathList
                          ) && _vm.msForm.principalTraining
                            ? _c(
                                "div",
                                { staticStyle: { display: "inline-block" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "margin-right": "15px",
                                        color: "#0045F9",
                                        cursor: "pointer"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openTrainingFilePreview(
                                            _vm.msForm
                                              .principalTrainingPathList,
                                            "主要负责人培训证明"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        cursor: "pointer"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.delAnnexFile(false)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.msForm.managerTraining
                ? _c(
                    "div",
                    [
                      _c("attachmen-upload", {
                        attrs: {
                          folder: "/employer/basic",
                          label: "职业卫生管理人员培训证明：",
                          labelwidth: "200px",
                          limit: 5,
                          fileProp: "managerTrainingPath"
                        },
                        model: {
                          value: _vm.fileList,
                          callback: function($$v) {
                            _vm.fileList = $$v
                          },
                          expression: "fileList"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ]
      ),
      _c("industry-select", {
        ref: "msDeclareComponentCategory",
        attrs: { ifValidateIfLeaf: true, interfaceName: "/oh/repository" },
        on: { change: _vm.msDeclareComponentCategoryChange }
      }),
      _c(
        "base-dialog",
        {
          ref: "yearDeclarationDialog",
          staticClass: "year-declaration-dialog",
          attrs: {
            title: "知情同意书",
            ifShowClose: false,
            ifDetermine: false,
            ifCancel: false
          }
        },
        [
          _c("div", { staticClass: "edit-row" }, [
            _vm._v(
              " 职业病危害项目申报“年度更新”，仅适用于年度检验检测、职业健康检查工作已依法开展并取得正式报告后方可申报。 "
            )
          ]),
          _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
            _c(
              "div",
              { staticStyle: { "text-align": "center" } },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "zwx-button zwx-button-32",
                    attrs: { disabled: _vm.timeForm.informedConsentDisabled },
                    on: {
                      click: function($event) {
                        return _vm.closeYearDeclarationDialog()
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.timeForm.informedConsentButtonTitle))]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }