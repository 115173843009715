<template>
  <div class="harzard-declaration-edit-page">
    <div class="edit-step">
      <template v-for="(item, index) in tabs">
        <div class="edit-step-item is-complete" @click="toStep(index + 1)">
          <div class="edit-step-item-icon " :class="step === index + 1 ? 'is-complete-icon' : step >= index + 1 ? 'is-complete-icon-check' : ''">{{ index + 1 }}</div>
          <span :style="step >= index + 1 ? 'cursor:pointer' : ''" @click="toStep(index + 1)">{{ item }}</span>
          <div class="edit-step-item-separator" :class="step > index + 1 ? 'is-complete-separator' : ''" v-if="index < 5"></div>
        </div>
      </template>
    </div>
    <div style="height:52px"></div>
    <div class="basic-info-main-body">
      <!-- <h1 id="node1" @click="selectComponent(1)">
        基本信息
      </h1> -->
      <div id="node1">
        <base-headline title="基本信息" class="no-top" />
      </div>
      <template>
        <base-info-index ref="baseInfoIndexRef"></base-info-index>
      </template>
      <!-- <h1 id="node2" @click="selectComponent(2)">主要产品</h1> -->
      <div id="node2">
        <base-headline title="主要产品" />
      </div>
      <template>
        <major-product-index ref="majorProductIndexRef"></major-product-index>
      </template>
      <!-- <h1 id="node3" @click="selectComponent(3)">危害因素接触情况</h1> -->
      <div id="node3" style="margin-top:12px;">
        <base-headline title="危害因素接触情况" />
      </div>
      <template>
        <hazard-distribution-index ref="hazardDistributionIndexRef"></hazard-distribution-index>
      </template>
      <!-- <h1 id="node4" @click="selectComponent(4)">危害因素检测情况</h1> -->
      <div id="node4" style="margin-top:12px;">
        <base-headline title="危害因素检测情况" />
      </div>
      <template>
        <hazard-detection-index ref="hazardDetectionIndexRef"></hazard-detection-index>
      </template>
      <!-- <h1 id="node5" @click="selectComponent(5)">职业健康检查开展情况</h1> -->
      <div id="node5">
        <base-headline title="职业健康检查开展情况" />
      </div>
      <template>
        <health-supervision-detail-index ref="healthSupervisionDetailIndexRef"></health-supervision-detail-index>
      </template>
      <!-- <h1 id="node6" @click="selectComponent(6)">附件上传</h1> -->
      <div id="node6">
        <base-headline title="附件上传" />
      </div>
      <template>
        <health-attachment-index ref="healthAttachmentIndexRef"></health-attachment-index>
      </template>
    </div>
    <div class="basic-info-main-foot">
      <fixed-footer>
        <el-button class="zwx-button zwx-button-32" @click="save(0, 'back')" v-if="step > 1" :disabled="saveDisabled">
          上一步
        </el-button>
        <el-button class="zwx-button zwx-button-32" type="primary" @click="save(1, '')" v-if="step < 6" :disabled="saveDisabled">
          下一步
        </el-button>
        <el-button class="zwx-button zwx-button-32" type="primary" @click="submit(1, '提交')" v-if="step === 6" :disabled="saveDisabled">
          提交
        </el-button>
        <el-button class="zwx-button zwx-button-32" @click="save(2, '')" :disabled="saveDisabled">
          暂存
        </el-button>
      </fixed-footer>
    </div>
  </div>
</template>

<script>
import BaseInfoIndex from './BaseInfoIndex.vue' //基本信息
import MajorProductIndex from './MajorProductIndex.vue' //主要产品
import HazardDistributionIndex from './HazardDistributionIndex.vue' //危害因素接触情况
import HazardDetectionIndex from './HazardDetectionIndex.vue' //危害因素检测情况
import HealthSupervisionDetailIndex from './HealthSupervisionDetailIndex.vue' //职业健康检查开展情况
import HealthAttachmentIndex from './HealthAttachmentIndex.vue' //附件上传
export default {
  name: 'HazardDeclarationEdit',
  components: {
    BaseInfoIndex,
    MajorProductIndex,
    HazardDistributionIndex,
    HazardDetectionIndex,
    HealthSupervisionDetailIndex,
    HealthAttachmentIndex,
  },
  data() {
    return {
      api: this.$store.state.api,
      tabs: ['基本信息', '主要产品', '危害因素接触情况', '危害因素检测情况', '职业健康检查开展情况', '附件上传'],
      step: 1, //当前页 默认为0
      saveDisabled: true,
      jsonData: '',
      clickName: '',
      firstAdd: false, //第一次申报
      loading: false,
      type: this.$route.params.type,
    }
  },
  created() {},
  mounted() {
    this.$route.params.fresh = true
    if (this.type == 2) {
      this.$emit('breadcrumb', '编辑', true)
    } else {
      this.$emit('breadcrumb', '添加', true)
    }
  },
  activated() {},
  watch: {
    loading: {
      handler(v) {
        this.$emit('loading', v)
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs
    },
  },
  beforeRouteLeave(to, from, next) {
    to.params.fresh = true
    // 路由返回跳转确认
    let json = ''
    if (this.step === 1) {
      json = JSON.stringify(this.$refs.baseInfoIndexRef.msForm)
    } else if (this.step === 2) {
      json = JSON.stringify(this.$refs.majorProductIndexRef.msForm)
    } else if (this.step === 3) {
      json = JSON.stringify(this.$refs.hazardDistributionIndexRef.msForm)
    } else if (this.step === 4) {
      json = JSON.stringify(this.$refs.hazardDetectionIndexRef.msForm)
    } else if (this.step === 5) {
      json = JSON.stringify(this.$refs.healthSupervisionDetailIndexRef.msForm)
    } else {
      json = JSON.stringify(this.$refs.healthAttachmentIndexRef.msFormEdit.annexList)
    }
    if (this.clickName === '提交') {
      this.clickName = ''
      next()
    } else {
      if (this.jsonData !== json) {
        this.$confirm('数据还未保存，确定取消操作吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            next()
          })
          .catch(() => {})
      } else {
        next()
      }
    }
  },
  mounted() {
    this.detail()
    let pageNode = document.querySelector('.el-scrollbar__wrap')
    pageNode.addEventListener('scroll', e => {
      let { scrollTop } = e.target
      for (let i = 1; i <= 6; i++) {
        let currentTop = document.getElementById('node' + i).offsetTop
        if (scrollTop >= currentTop - 52) {
          this.step = i
        }
      }
    })
  },
  methods: {
    detail() {
      this.$emit('loading', true)
      // let fkByMainId = {
      //   rid: this.msForm.mainId,
      // }
      // let data = {
      //   fkByMainId: fkByMainId,
      // }
      this.$system.get(
        this.api + '/declaration/loadHazardDeclarationBasic-1',
        {},
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            console.log(data, 'data')
            this.$refs.baseInfoIndexRef.loadHazardDeclarationBasicSuccess(data)
          } else if (data.type === '99') {
            // this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        data => {
          this.$parent.loading = false
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    selectComponent(type) {
      let node = document.getElementById('node' + type)
      let pageNode = document.querySelector('.el-scrollbar__wrap')
      pageNode.scrollTo(0, parseInt(node.offsetTop) - 52)
    },
    /**
     * 保存
     */
    save(opType, type) {
      //opType:0上一步 ,1提交;2:暂存；提交才做必填校验
      // 点击上一步、暂存直接把按钮禁用
      if (1 != opType) {
        this.saveDisabled = true
      }
      switch (this.step) {
        case 1:
          //基本信息
          this.$refs.baseInfoIndexRef.saveOrUpdateHazardDeclarationBasic(opType)
          break
        case 2:
          //主要产品
          this.$refs.majorProductIndexRef.saveOrUpdateMajorProduct(opType)
          break
        case 3:
          //危害因素接触情况
          this.$refs.hazardDistributionIndexRef.saveOrUpdateHazardDistribution(opType)
          break
        case 4:
          //危害因素检测情况
          this.$refs.hazardDetectionIndexRef.saveOrUpdateHazardDetection(opType)
          break
        case 5:
          // 职业健康检查开展情况
          this.$refs.healthSupervisionDetailIndexRef.saveOrUpdateHealthSupervision(opType)
          break
        case 6:
          // 附件上传
          this.$refs.healthAttachmentIndexRef.saveOrUpdateHealthSupervision(opType)
          break
        default:
      }
    },
    /**
     * 提交
     */
    submit(opType, clickName) {
      this.clickName = clickName
      this.$refs.healthAttachmentIndexRef.saveOrUpdateHealthSupervision(opType)
    },
    /**
     *
     */
    toStep(step) {
      this.selectComponent(step)
      // this.saveDisabled = true
      console.log(step, 'this.step')
      this.step = step
    },
  },
}
</script>

<style lang="less" scoped>
.harzard-declaration-edit-page {
  .edit-step {
    padding-left: 24px;
    height: 52px;
    width: 100%;
    z-index: 1111;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(213, 217, 224, 1);
    position: fixed;
    background: #fff;
    .edit-step-item {
      display: flex;
      line-height: 28px;
      color: rgba(153, 173, 197, 1);
      .edit-step-item-icon {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: rgba(153, 173, 197, 1);
        border: 1px solid rgba(153, 173, 197, 1);
        border-radius: 50%;
        margin-right: 7px;
        font-family: 'Harmony Bold';
      }
      .edit-step-item-separator {
        width: 70px;
        height: 1px;
        background: #99adc5;
        margin: auto 10px;
      }
      .is-complete-icon {
        background: rgba(28, 160, 106, 1);
        color: rgba(255, 255, 255, 1);
        cursor: pointer;
      }
      .is-complete-icon-check {
        background: rgba(255, 255, 255, 1);
        border-color: rgba(28, 160, 106, 1);
        color: rgba(28, 160, 106, 1);
        cursor: pointer;
      }
      .is-complete-separator {
        background: rgba(28, 160, 106, 1);
      }
    }
    .is-complete {
      color: rgba(28, 160, 106, 1);
    }
  }
}
</style>
