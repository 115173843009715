<template>
  <el-dialog class="zwx-dialog" title="商户信息详情" :visible.sync="visible" @close="close()" :close-on-click-modal="false" :destroy-on-close="true">
    <div class="content">
      <el-form class="ms-form" key="msForm" ref="msForm" :model="msForm" label-position="right" label-width="110px" @submit.native.prevent>
        <el-form-item label="应用场景：" prop="appScenario">
          <span>{{ { 0: '通用商户', 1: '个性商户' }[msForm.appScenario] || '-' }}</span>
        </el-form-item>
        <el-form-item label="商户类型：" prop="businessType">
          <span>{{ { '04': '微信', '01': '支付宝', '05': '银联商务' }[msForm.businessType] || '-' }}</span>
        </el-form-item>
        <el-form-item label="商户编号：" prop="merchantNo">
          <span>{{ msForm.merchantNo || '-' }}</span>
        </el-form-item>
        <el-form-item label="商户名称：" prop="payDescribe">
          <span>{{ msForm.payDescribe || '-' }}</span>
        </el-form-item>
        <el-form-item label="API证书：" prop="annexList">
          <template v-if="$zwxBase.verifyIsNotBlank(msForm.annexList)">
            <div class="show-file" v-for="(item, idx) in msForm.annexList" :key="idx">
              <!-- <el-button class="zwx-button zwx-button-text-26" @click="openFilePreview1(msForm.annexList, idx)">查看</el-button> -->
              <span :style="{ marginRight: '8px' }">{{ item.fileName }}</span>
            </div>
          </template>
          <span v-else>-</span>
        </el-form-item>
        <el-form-item label="证书序列号：" prop="merchantSerialNumber">
          <span>{{ msForm.merchantSerialNumber || '-' }}</span>
        </el-form-item>
        <el-form-item label="APIv3密钥：" prop="apiV3Key">
          <span>{{ msForm.apiV3Key || '-' }}</span>
        </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'MerchantDetailDialog',
  data() {
    return {
      api: this.$store.state.api,
      file: this.$store.state.file,
      users: this.$store.state.users,
      rules: {
        appScenario: [{ required: true, message: '请选择应用场景', trigger: ['change', 'blur'] }],
        businessType: [{ required: true, message: '请选择商户类型', trigger: ['change', 'blur'] }],
        merchantNo: [{ required: true, message: '请输入商户编号', trigger: ['change', 'blur'] }],
        payDescribe: [{ required: true, message: '请输入商户名称', trigger: ['change', 'blur'] }],
        // businessJson: [{ required: true, message: '请输入商户JSON', trigger: ['change', 'blur'] }],
        annexList: [{ required: true, message: '请上传API证书', trigger: ['change', 'blur'] }],
        merchantSerialNumber: [{ required: true, message: '请输入证书序列号', trigger: ['change', 'blur'] }],
        apiV3Key: [{ required: true, message: '请输入APIv3密钥', trigger: ['change', 'blur'] }],
      },
      visible: false,
      msForm: {
        appScenario: 0,
        businessType: '04',
        merchantNo: '',
        payDescribe: '',
        /**
         * 
        businessJson: '',
         */
        annexList: [],
        merchantSerialNumber: '',
        apiV3Key: '',
      },
    }
  },
  created() {},
  methods: {
    commit() {
      this.$refs.msForm.validate(valid => {
        if (!valid) return

        let data = {
          rid: this.msForm.rid,
          uuid: this.msForm.uuid,
          appScenario: this.msForm.appScenario,
          businessType: this.msForm.businessType,
          merchantNo: this.$zwxSm.sm4JsEncrypt(this.msForm.merchantNo),
          payDescribe: this.msForm.payDescribe,
          /**
           * 
          businessJson: this.$zwxSm.sm4JsEncrypt(this.msForm.businessJson),
           */
          privateKeyPath: this.msForm.annexList.length > 0 ? this.msForm.annexList[0].filePath : '',
          merchantSerialNumber: this.$zwxSm.sm4JsEncrypt(this.msForm.merchantSerialNumber),
          apiV3Key: this.$zwxSm.sm4JsEncrypt(this.msForm.apiV3Key),
        }
        this.$system.postJson(this.api + '/training/platform/addOrModifyTrainingAgencyPayConfig-1', data, true, true, this.commitSuccess, this.error)
      })
    },
    commitSuccess(data) {
      if (data.type === '00') {
        this.$system.notify('成功', data.mess, 'success')
        this.close()
        this.$emit('confirm')
      } else if (data.type === '99') {
        this.$router.push({ name: 'MsError', params: { mess: data.mess } })
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.$emit('loading', false)
      }
    },
    // 关闭弹框
    close() {
      this.visible = false
      this.msForm = {
        uuid: undefined,
        appScenario: 0,
        businessType: '04',
        merchantNo: '',
        payDescribe: '',
        /**
         * 
        businessJson: '',
         */
        annexList: [],
        merchantSerialNumber: '',
        apiV3Key: '',
      }
      this.$refs.msForm.resetFields()
    },
    // 打开弹框
    setShow(row) {
      if (row) {
        row.annexList = []
        if (this.$zwxBase.verifyIsNotBlank(row.privateKeyPath)) {
          row.annexList.push({
            check: false,
            fileIconShow: 'ms-file-jpg-icon',
            fileName: row.privateKeyPath.split('/').pop(),
            filePath: row.privateKeyPath,
          })
        }
        this.msForm = {
          ...row,
          merchantNo: this.$zwxSm.sm4JsDecrypt(row.merchantNo),
          // businessJson: this.$zwxSm.sm4JsDecrypt(row.businessJson),
          merchantSerialNumber: this.$zwxSm.sm4JsDecrypt(row.merchantSerialNumber),
          apiV3Key: this.$zwxSm.sm4JsDecrypt(row.apiV3Key),
        }
      }
      this.visible = true
    },
    error() {
      this.$system.notify('错误', '网络连接失败', 'error')
      this.$emit('loading', false)
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  width: 580px !important;
  min-width: 580px !important;
  .ms-dialog-footer-specical {
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    box-sizing: border-box;
  }
}
/deep/ .ms-form {
  .el-form-item {
    display: block !important;
  }
  .zwx-select {
    .el-input__inner {
      width: 280px !important;
    }
  }
}
.content {
  padding: 50px 60px 40px;
  box-sizing: border-box;
}
</style>
