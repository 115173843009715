var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "zwx-dialog",
      attrs: {
        title: "商户信息详情",
        visible: _vm.visible,
        "close-on-click-modal": false,
        "destroy-on-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              key: "msForm",
              ref: "msForm",
              staticClass: "ms-form",
              attrs: {
                model: _vm.msForm,
                "label-position": "right",
                "label-width": "110px"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "应用场景：", prop: "appScenario" } },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        { 0: "通用商户", 1: "个性商户" }[
                          _vm.msForm.appScenario
                        ] || "-"
                      )
                    )
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商户类型：", prop: "businessType" } },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        { "04": "微信", "01": "支付宝", "05": "银联商务" }[
                          _vm.msForm.businessType
                        ] || "-"
                      )
                    )
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商户编号：", prop: "merchantNo" } },
                [_c("span", [_vm._v(_vm._s(_vm.msForm.merchantNo || "-"))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商户名称：", prop: "payDescribe" } },
                [_c("span", [_vm._v(_vm._s(_vm.msForm.payDescribe || "-"))])]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "API证书：", prop: "annexList" } },
                [
                  _vm.$zwxBase.verifyIsNotBlank(_vm.msForm.annexList)
                    ? _vm._l(_vm.msForm.annexList, function(item, idx) {
                        return _c(
                          "div",
                          { key: idx, staticClass: "show-file" },
                          [
                            _c("span", { style: { marginRight: "8px" } }, [
                              _vm._v(_vm._s(item.fileName))
                            ])
                          ]
                        )
                      })
                    : _c("span", [_vm._v("-")])
                ],
                2
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "证书序列号：", prop: "merchantSerialNumber" }
                },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.msForm.merchantSerialNumber || "-"))
                  ])
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "APIv3密钥：", prop: "apiV3Key" } },
                [_c("span", [_vm._v(_vm._s(_vm.msForm.apiV3Key || "-"))])]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }