var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "HealthistachmentIndex" }, [
    _vm._m(0),
    _c(
      "div",
      [
        _c("span", [_vm._v("确认完成后打印申请表：")]),
        _c(
          "el-button",
          {
            staticClass: "zwx-button zwx-button-icontext-28",
            attrs: { icon: "el-icon-printer" },
            on: { click: _vm.prints }
          },
          [_vm._v("打印")]
        ),
        _c("print-health", {
          ref: "printHealth",
          attrs: { msDeclarationPrintViewForm: _vm.msDeclarationPrintViewForm }
        })
      ],
      1
    ),
    _c("div", { staticStyle: { "margin-top": "19px" } }, [
      _vm._v(" 盖章并扫描申报表 ")
    ]),
    _c("div", { staticClass: "attachment" }, [
      _c(
        "div",
        [
          _c("span", [_vm._v("上传文件：")]),
          _c(
            "file-upload",
            {
              ref: "msPositive",
              attrs: {
                disabled: !(
                  _vm.$zwxBase.verifyIsBlank(_vm.msFormEdit.annexList) ||
                  _vm.msFormEdit.annexList.length < 5
                ),
                accept: ".png,.jpeg,.jpg,.pdf,.JPG,.JPEG,.PNG,.PDF",
                size: 5 * 1024 * 1024,
                sizeInfo: "5M"
              },
              on: {
                showFileList: _vm.clickPositive,
                success: _vm.clickPositiveSuccess,
                error: function($event) {
                  return _vm.clickError()
                }
              }
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: {
                    icon: "el-icon-upload2",
                    disabled: !(
                      _vm.$zwxBase.verifyIsBlank(_vm.msFormEdit.annexList) ||
                      _vm.msFormEdit.annexList.length < 5
                    )
                  }
                },
                [_vm._v(" 上传 ")]
              )
            ],
            1
          ),
          _c("span", { staticClass: "attachment-tip" }, [
            _vm._v("支持pdf、jpg、png格式文件，不大于5M，最多上传5份附件")
          ])
        ],
        1
      ),
      _c("div", { staticStyle: { color: "red" } }, [
        _vm._v(_vm._s(_vm.warningtext))
      ]),
      _vm.msFormEdit.annexList.length > 0
        ? _c(
            "div",
            { staticClass: "show-file" },
            _vm._l(_vm.msFormEdit.annexList, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  on: {
                    mouseenter: function($event) {
                      return _vm.mouseenter(index)
                    },
                    mouseleave: function($event) {
                      return _vm.mouseleave(index)
                    }
                  }
                },
                [
                  _c("span", [
                    _c(
                      "a",
                      {
                        staticStyle: {
                          color: "#194DFF !important",
                          "margin-left": "12px",
                          cursor: "pointer"
                        },
                        on: {
                          click: function($event) {
                            return _vm.openFilePreview(
                              _vm.msFormEdit.annexList,
                              index
                            )
                          }
                        }
                      },
                      [
                        _c("i", {
                          class: item.fileIconShow,
                          staticStyle: { color: "#316CF5!important" }
                        }),
                        _vm._v(" " + _vm._s(item.fileName) + " ")
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "el-upload-list__item check",
                        style: item.check
                          ? "margin-left:18px;display:none;"
                          : "margin-left:18px;cursor:pointer"
                      },
                      [
                        _c("i", {
                          staticClass:
                            "el-icon-upload-success el-icon-circle-check"
                        })
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "check",
                        staticStyle: { "line-height": "1.8" },
                        style: item.check
                          ? "margin-left:18px;cursor:pointer"
                          : "margin-left:18px;display:none;"
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-close",
                          on: {
                            click: function($event) {
                              return _vm.deletionPositive(index)
                            }
                          }
                        })
                      ]
                    )
                  ])
                ]
              )
            }),
            0
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tags" }, [_c("div"), _c("div"), _c("div")])
  }
]
render._withStripped = true

export { render, staticRenderFns }